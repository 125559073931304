import React, { Fragment, useState, useEffect, useRef } from "react";
import { copyright } from "../utils/copyright";
// import { CiSearch } from "react-icons/ci";
// import { FaRegTrashAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
// import { IoMdArrowBack } from "react-icons/io";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { MdPlaylistPlay } from "react-icons/md";
// import { likedVideoData } from "../data";
import { useDispatch, useSelector } from "react-redux";
import { deletePlaylist, getPlaylist } from "../redux/apislice/playListSlice";
import PlaylistHeader from "../components/playlist/PlaylistHeader";
import Skeleton from "react-loading-skeleton";
import FilterHeader from "../components/common/FilterHeader";
import Cookies from "js-cookie";

const PlayList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [visibleMenuId, setVisibleMenuId] = useState(null);
    const [sortedResults, setSortedResults] = useState([]);
    const menuRefs = useRef({});
    const playlist = useSelector((state) => state.playlist);
    const { playList, loading } = playlist
    const userId = Cookies.get("userId");
    console.log(playList, "playList")


    const handlePlaylistClick = (playlistId) => {
        navigate(`/playlist/${playlistId}`);
    };

    const toggleMenu = (id) => {
        setVisibleMenuId(visibleMenuId === id ? null : id);
    };

    const handleClickOutside = (event) => {
        if (
            !Object.values(menuRefs.current).some((ref) => ref && ref.contains(event.target))
        ) {
            setVisibleMenuId(null);
        }
    };



    function timeAgo(timestamp) {
        const now = new Date(); // Current date and time
        const past = new Date(timestamp); // The timestamp you want to convert

        const diffInSeconds = Math.floor((now - past) / 1000); // Time difference in seconds

        // Define time intervals
        const intervals = {
            year: 31536000, // 365 days * 24 hours * 60 minutes * 60 seconds
            month: 2592000,  // 30 days * 24 hours * 60 minutes * 60 seconds
            week: 604800,    // 7 days * 24 hours * 60 minutes * 60 seconds
            day: 86400,      // 24 hours * 60 minutes * 60 seconds
            hour: 3600,      // 60 minutes * 60 seconds
            minute: 60,      // 60 seconds
            second: 1        // 1 second
        };

        // Calculate the largest time interval and its corresponding value
        for (const [key, value] of Object.entries(intervals)) {
            const timeDifference = Math.floor(diffInSeconds / value);
            if (timeDifference >= 1) {
                return `${timeDifference} ${key}${timeDifference > 1 ? 's' : ''} ago`;
            }
        }

        return 'just now'; // Default if the difference is less than 1 second
    }

    const handleDeletePlaylist = (playlistId) => {
        dispatch(deletePlaylist(playlistId)).then(() => {
            dispatch(getPlaylist())
        }
        )
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    useEffect(() => {
        dispatch(getPlaylist(userId))
    }, [dispatch, userId])


    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center">
                {/* <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / Play List / V1 / 15 Aug, 2024
                </p> */}
                <p className="text-black text-[9px] py-[2px]">{copyright}</p>
            </div>
            <PlaylistHeader />
            <div className="mt-2 px-2">
                <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2 text-white">
                        <p className="text-[14px] font-medium">Total Playlists</p>
                        <p className="text-[14px] font-bold"> {playList?.results?.length}</p>
                    </div>
                    <p
                        onClick={() => navigate("/createplaylist")}
                        className="text-[10px] text-light-blue-600 hover:text-light-blue-500 cursor-pointer"
                    >
                        Create Playlist
                    </p>
                </div>
                <FilterHeader data={playList?.results} setSortedResults={setSortedResults} />

                {sortedResults?.map((data) => (
                    <div className="grid grid-cols-12 my-4 gap-2" key={data.id}>
                        <div className="col-span-5 relative" onClick={() => handlePlaylistClick(data.id)}>
                            <img
                                alt="liked video"
                                className="w-full h-[4.35rem] object-cover rounded cursor-pointer"
                                src={data.videos[0]?.video?.thumbnail ? data.videos[0]?.video?.thumbnail : "https://th.bing.com/th/id/OIP.MVbv2zRerfpzIkpqMlMRJAHaF7?w=211&h=180&c=7&r=0&o=5&pid=1.7"}
                            />
                            <div className="absolute bottom-1 right-2 flex items-center gap-1 bg-[#292929] px-2 py-[1px] rounded-[4px]">
                                <MdPlaylistPlay className="text-white text-[12px]" />
                                <p className="text-white text-[10px]">{data?.videos?.length}</p>
                            </div>
                        </div>
                        <div className="col-span-7">
                            <div className="flex justify-between">
                                <div className="flex flex-col" onClick={() => handlePlaylistClick(data.id)}>
                                    <p className="text-[#808080] text-[10px] cursor-pointer">{data.title}</p>
                                    <div className="flex items-center gap-1">
                                        <p className="text-[#808080] text-[10px]">{data.status}</p>
                                        <p className="text-[#808080] text-[10px]">•</p>
                                        <p className="text-[#808080] text-[10px]">Playlist</p>
                                    </div>
                                    <p className="text-[#808080] text-[10px] cursor-pointer">{timeAgo(data.updated_at)}</p>
                                </div>
                                <div className="relative" ref={(el) => (menuRefs.current[data.id] = el)}>
                                    <HiOutlineDotsVertical
                                        onClick={() => toggleMenu(data.id)}
                                        className="text-[#808080] text-[14px] cursor-pointer mr-[-5px] z-10"
                                    />
                                    {visibleMenuId === data.id && (
                                        <div className="absolute top-0 right-0 bg-white px-2 rounded">
                                            <p className="text-[12px] hover:text-[#f83d3d] cursor-pointer" onClick={() => handleDeletePlaylist(data.id)}>delete</p>
                                            <p className="text-[12px] hover:text-[#f83d3d] cursor-pointer" onClick={() => navigate(`/edit/playlist/${data.id}`)}>edit</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}

                {loading && ([1, 2, 3, 4, 5, 6].map(() => (
                    <div className="grid grid-cols-12 gap-2" >
                        <div className="col-span-5">
                            <Skeleton baseColor="#202020" highlightColor="#444" height={68} width={125} />
                        </div>
                        <div className="col-span-7">
                            <div className="flex flex-col items-start justify-start">
                                <div className='grid grid-cols-12 gap-1 justify-between items-start'>
                                    <p className='col-span-8'>
                                        <Skeleton baseColor="#202020" highlightColor="#444" height={10} width={130} />
                                    </p>
                                    <p className='col-span-2'></p>
                                    <p className='col-span-2 flex justify-end items-center'>
                                        <Skeleton baseColor="#202020" highlightColor="#444" height={10} width={10} />
                                    </p>
                                </div>
                                <p className="text-[#808080] text-[10px]">
                                    <Skeleton baseColor="#202020" highlightColor="#444" height={10} width={120} /></p>
                                <p className="text-[#808080] text-[10px]">
                                    <Skeleton baseColor="#202020" highlightColor="#444" height={10} width={100} /></p>
                            </div>
                        </div>
                    </div>
                )))
                }
                {playList?.results?.length === 0 && (<p className="text-[#4d4d4d] px-1 text-[14px] text-center">No Playlist Found</p>)}
            </div>
        </Fragment>
    );
};

export default PlayList;
