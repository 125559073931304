import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import { useDispatch, useSelector } from "react-redux";
import { copyright } from "../../utils/copyright";
import { IoMdArrowBack } from "react-icons/io";
import { contactUs } from "../../redux/apislice/contactSlice";

const ContactPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        full_name: "",
        email: "",
        contact_number: "",
        message: ""
    });
    const [successMessage, setSuccessMessage] = useState("");

    const { loading, error } = useSelector((state) => state.contact);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(contactUs(formData))
            .unwrap()
            .then(() => {
                setSuccessMessage("Your message has been successfully submitted.");
                setFormData({
                    full_name: "",
                    email: "",
                    contact_number: "",
                    message: ""
                });
            })
            .catch(() => {
                setSuccessMessage("");
            });
    };

    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center">
                {/* <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / Contact Us / V1 / 28 Aug, 2024
                </p> */}
                <p className="text-black text-[9px] py-[2px]">{copyright}</p>
            </div>

            <div className="sticky top-0 grid grid-cols-12 items-center px-3 py-[6px] z-10 bg-[#1e1e1e]">
                <div className="col-span-10 flex items-center justify-start gap-3">
                    <IoMdArrowBack
                        onClick={() => navigate(-1)}
                        className="text-white h-5 w-5 cursor-pointer ml-[-3px]"
                    />
                    <p className="text-white text-[16px] font-medium">Contact Us</p>
                </div>
            </div>

            <form className="m-2" onSubmit={handleSubmit}>
                <div className="mb-2">
                    <label className="text-white text-[14px] font-normal">Full Name</label>
                    <input
                        type="text"
                        name="full_name"
                        value={formData.full_name}
                        onChange={handleChange}
                        placeholder="Enter your full name"
                        className="w-full mt-[2px] px-2 py-2 text-white placeholder:text-[14px] text-[14px] rounded border-none bg-[#474747] focus:outline-none"
                    />
                </div>
                <div className="mb-2">
                    <label className="text-white text-[14px] font-normal">Email</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Enter your email"
                        className="w-full mt-[2px] px-2 py-2 text-white placeholder:text-[14px] text-[14px] rounded border-none bg-[#474747] focus:outline-none"
                    />
                </div>
                <div className="mb-2">
                    <label className="text-white text-[14px] font-normal">Contact Number (optional)</label>
                    <input
                        type="text"
                        name="contact_number"
                        value={formData.contact_number}
                        onChange={handleChange}
                        placeholder="Enter your contact number"
                        className="w-full mt-[2px] px-2 py-2 text-white placeholder:text-[14px] text-[14px] rounded border-none bg-[#474747] focus:outline-none"
                    />
                </div>
                <div className="mb-2">
                    <label className="text-white text-[14px] font-normal">Message</label>
                    <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        rows={7}
                        placeholder="Enter your message"
                        className="w-full mt-[2px] px-2 py-2 resize-none text-white placeholder:text-[14px] text-[14px] rounded border-none bg-[#474747] focus:outline-none"
                    />
                </div>
                <button type="submit" className="bg-[#ffffff] rounded-full py-1 w-full" disabled={loading}>
                    {loading ?
                        <BeatLoader
                            color={"#000"}
                            loading={true}
                            size={15}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                        :
                        "Submit"
                    }
                </button>
                {error &&
                    <p className="text-red-500 mt-2 text-center">
                        Submission failed. Please try again.
                    </p>
                }
                {successMessage &&
                    <p className="text-green-500 mt-2 text-center">
                        {successMessage}
                    </p>
                }
            </form>
        </Fragment>
    );
};

export default ContactPage;