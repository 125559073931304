import React, { Fragment, useState, useRef, useEffect } from "react";
import axios from "axios";
import { copyright } from "../../utils/copyright";
import BeatLoader from "react-spinners/BeatLoader";
import { Progress } from "@material-tailwind/react";
import { CiSearch } from "react-icons/ci";
import { IoMdArrowBack } from "react-icons/io";
import { MdOutlineStarPurple500 } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { uploadMusic } from "../../redux/apislice/music/musicSlice";

const UploadMusic = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loading } = useSelector((state) => state.musicList);

    const [formData, setFormData] = useState({
        title: "",
        singer: "",
        category: "",
    });
    const [success, setSuccess] = useState(false);
    const [countdown, setCountdown] = useState(3);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [cancelTokenSource, setCancelTokenSource] = useState(null);
    const [isCanceled, setIsCanceled] = useState(false);

    const musicFileRef = useRef(null);
    const imageFileRef = useRef(null);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const musicFile = musicFileRef.current?.files[0];
        const imageFile = imageFileRef.current?.files[0];

        if (!musicFile || !imageFile) {
            console.error("Music file or image file is missing");
            return;
        }

        const data = new FormData();
        data.append("title", formData.title);
        data.append("singer", formData.singer);
        data.append("category", formData.category);
        data.append("file", musicFile);
        data.append("image", imageFile);

        // Create a cancel token
        const source = axios.CancelToken.source();
        setCancelTokenSource(source);

        try {
            await dispatch(uploadMusic({
                musicData: data,
                progressCallback: setUploadProgress,
                cancelToken: source.token
            })).unwrap();
            setFormData({
                title: "",
                singer: "",
                category: "",
            });
            musicFileRef.current.value = null;
            imageFileRef.current.value = null;
            setSuccess(true);
        } catch (error) {
            console.error("Upload failed:", error);
        }
    };

    const handleCancel = () => {
        if (cancelTokenSource) {
            cancelTokenSource.cancel("Upload canceled by user");
            setUploadProgress(0);
            setSuccess(false); // Ensure success is not set to true
            setIsCanceled(true); // Set isCanceled to true
        }
    };

    useEffect(() => {
        if (success && !isCanceled) {
            const timer = setInterval(() => {
                setCountdown((prev) => {
                    if (prev === 1) {
                        clearInterval(timer);
                        navigate("/music");
                    }
                    return prev - 1;
                });
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [success, isCanceled, navigate]);

    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center">
                {/* <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / Upload Music / V1 / 17 OCT, 2024
                </p> */}
                <p className="text-black text-[9px] py-[2px]">{copyright}</p>
            </div>

            <div className="sticky top-0 grid grid-cols-12 items-center px-2 py-[6px] z-10 bg-[#1e1e1e]">
                <div className="col-span-6 flex items-center justify-start gap-3">
                    <IoMdArrowBack
                        onClick={() => navigate(-1)}
                        className="text-white h-5 w-5 cursor-pointer ml-[-3px]"
                    />
                    <p className="text-white text-[16px] font-medium">
                        Upload Music
                    </p>
                </div>
                <div className="col-span-6 flex items-center justify-end gap-3 ">
                    <CiSearch
                        onClick={() => navigate("/search")}
                        className="text-white h-5 w-5 cursor-pointer mr-[-2px]"
                    />
                </div>
            </div>

            <form className="px-2 mt-2" onSubmit={handleSubmit}>
                <div className="mb-2">
                    <div className="flex mb-[3px]">
                        <p className="text-white text-[14px]">
                            Music Title
                        </p>
                        <MdOutlineStarPurple500
                            className="text-[8px] text-[#f52929]"
                        />
                    </div>

                    <input
                        required
                        type="text"
                        placeholder="Add music title here ..."
                        name="title"
                        value={formData.title}
                        autoComplete="off"
                        onChange={handleChange}
                        color="white"
                        className="text-[14px] w-full bg-[#000000] px-3 py-[8px] md:py-[8px] text-white border border-[#D9D9D9] rounded-md focus:outline-none focus:ring-2 focus:ring-[#ffffff] focus:border-transparent placeholder:text-white"
                    />
                </div>
                <div className="mb-2">
                    <div className="flex mb-[3px]">
                        <p className="text-white text-[14px]">
                            Singer Name
                        </p>
                        <MdOutlineStarPurple500
                            className="text-[8px] text-[#f52929]"
                        />
                    </div>
                    <input
                        required
                        type="text"
                        placeholder="Add singer name here ..."
                        name="singer"
                        value={formData.singer}
                        onChange={handleChange}
                        color="white"
                        className="text-[14px] w-full bg-[#000000] px-3 py-[8px] md:py-[8px] text-white border border-[#D9D9D9] rounded-md focus:outline-none focus:ring-2 focus:ring-[#ffffff] focus:border-transparent placeholder:text-white"
                    />
                </div>

                <div className="flex mb-[3px]">
                    <p className="text-white text-[14px]">
                        Music Category
                    </p>
                    <MdOutlineStarPurple500
                        className="text-[8px] text-[#f52929]"
                    />
                </div>
                <div className="relative w-full mb-2">
                    <select
                        required
                        name="category"
                        value={formData.category}
                        onChange={handleChange}
                        className="w-full bg-[#000000] px-3 py-[8px] md:py-[8px] text-white text-[14px] border border-[#D9D9D9] rounded-md focus:outline-none focus:ring-2 focus:ring-[#ffffff] appearance-none focus:border-transparent"
                    >
                        <option value="">Select Category</option>
                        <option value="ElectronicDanceMusic">Electronic Dance Music</option>
                        <option value="RockMusic">Rock Music</option>
                        <option value="Jazz">Jazz</option>
                        <option value="Dubstep">Dubstep</option>
                        <option value="RhythmandBlues">Rhythm and Blues</option>
                        <option value="Techno">Techno</option>
                        <option value="CountryMusic">Country Music</option>
                        <option value="Electro">Electro</option>
                        <option value="IndieRock">Indie Rock</option>
                        <option value="PopMusic">Pop Music</option>
                    </select>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.2" stroke="currentColor" className="h-5 w-5 ml-1 absolute top-2.5 right-2.5 text-slate-700 text-white">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                    </svg>
                </div>
                <div className="mb-2">
                    <div className="flex mb-[3px]">
                        <p className="text-white text-[14px]">
                            Select Music File
                        </p>
                        <MdOutlineStarPurple500
                            className="text-[8px] text-[#f52929]"
                        />
                    </div>
                    <input
                        required
                        type="file"
                        ref={musicFileRef}
                        color="white"
                        accept="audio/mp3"
                        className="text-[14px] w-full bg-[#000000] px-3 py-[8px] md:py-[8px] text-white border border-[#D9D9D9] rounded-md focus:outline-none focus:ring-2 focus:ring-[#ffffff] focus:border-transparent"
                    />
                </div>
                <div className="mb-2">
                    <div className="flex mb-[3px]">
                        <p className="text-white text-[14px]">
                            Select Poster
                        </p>
                        <MdOutlineStarPurple500
                            className="text-[8px] text-[#f52929]"
                        />
                    </div>
                    <input
                        required
                        type="file"
                        ref={imageFileRef}
                        color="white"
                        accept="image/*"
                        className="text-[14px] w-full bg-[#000000] px-3 py-[8px] md:py-[8px] text-white border border-[#D9D9D9] rounded-md focus:outline-none focus:ring-2 focus:ring-[#ffffff] focus:border-transparent"
                    />
                </div>
                <button
                    type="submit"
                    className="w-full bg-[#1e1e1e] text-white py-[6px] rounded-md focus:outline-none"
                >
                    {loading ? (
                        <BeatLoader
                            color={"#fff"}
                            loading={true}
                            size={10}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    ) : (
                        "Upload Music"
                    )}
                </button>

                {loading && (
                    <div className="text-center my-2">
                        <Progress value={uploadProgress} size="md" label=" " color="blue" />
                    </div>
                )}

                {loading && (
                    <button
                        type="button"
                        onClick={handleCancel}
                        className="w-full bg-red-600 text-white py-[6px] rounded-md focus:outline-none"
                    >
                        Cancel Upload
                    </button>
                )}
            </form>

            {success && !isCanceled && (
                <div className="text-center text-[10px] md:text-[14px] mt-4 text-white">
                    <p>Upload successful! Redirecting in {countdown} seconds...</p>
                </div>
            )}
        </Fragment>
    );
};

export default UploadMusic;