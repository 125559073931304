import React, { Fragment, useEffect, useState, useRef } from "react";
import { copyright } from "../utils/copyright";
import { useSwipeable } from "react-swipeable";
import { useSpring, animated } from "@react-spring/web";
import Controls from "../components/clips/Controls";
import Subscribe from "../components/clips/Subscribe";
import Comment from "../components/clips/Comment";
import VideoDetails from "../components/clips/VideoDetails";
import Download from "../components/clips/Download";
import Share from "../components/clips/Share";
import Report from "../components/clips/Report";
import { Line } from "rc-progress";
import { useDispatch, useSelector } from "react-redux";
import { getClipsDetails, randomClips, setPlayedClips } from "../redux/apislice/clips/clipsSlice";
import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate, useParams } from "react-router-dom";

const ClipsMainPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const { clipsId } = params;
    const { loading, clipsData, error, videoData, videoDescription, progress, clipsDetails, playedClips } = useSelector(state => state.clips);
    const filteredVideo = clipsDetails.filter((data) => data.id === clipsId);
    const { video, id } = filteredVideo[0] || {};

    console.log("clipsDetails", filteredVideo)

    const [clipsVideoData, setClipsVideoData] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0); // Track current video index
    const [swipeDirection, setSwipeDirection] = useState(""); // Track swipe direction
    const [isTransitioning, setIsTransitioning] = useState(false); // Track selected report options

    const [isSubscribed, setIsSubscribed] = useState(false);
    const [isReported, setIsReported] = useState(false);
    const [selectedReports, setSelectedReports] = useState([]); // Track selected report options
    const [savedClips, setSavedClips] = useState([]); // Track saved clips

    // visibility of components on the screen
    const [commentVisible, setCommentVisible] = useState(false);
    const [subscriberVisible, setSubscriberVisible] = useState(true);
    const [controlVisible, setControlVisible] = useState(true);
    const [videoDetailsVisible, setVideoDetailsVisible] = useState(false);
    const [downloadVisible, setDownloadVisible] = useState(false);
    const [shareVisible, setShareVisible] = useState(false);
    const [reportVisible, setReportVisible] = useState(false);
    const [clipsRandomId, setClipsRandomId] = useState(null);
    const [PlayedVideoId, setPlayedVideoId] = useState([]);
    console.log("PlayedVideoId", playedClips)

    const [isPaused, setIsPaused] = useState(false); // Track if the video is paused
    const videoRef = useRef(null); // Reference to the video element

    // const [currentPage, setCurrentPage] = useState(1);
    const [swipeCount, setSwipeCount] = useState(0);
    const [totalClips, setTotalClips] = useState(0);

    // useEffect(() => {
    //     dispatch(getClips({ page: currentPage }));
    // }, [dispatch, currentPage]);


    useEffect(() => {
        try {
            dispatch(randomClips()).then((res) => {
                console.log(res, "resres")
                if (res?.payload?.selected_id) {
                    setClipsRandomId(res?.payload?.selected_id)
                    dispatch(getClipsDetails(clipsId));
                    dispatch(setPlayedClips(res?.payload?.selected_id))
                }
            })
        } catch {
            console.log("error")
        }

    }, [dispatch, swipeCount, clipsId]);

    useEffect(() => {
        setPlayedVideoId(playedClips)
    }, [playedClips])


    useEffect(() => {
        setClipsVideoData([1, 2]);
        if (clipsData && clipsData.results) {
            // setClipsVideoData(prevData => [...prevData, ...clipsData.results]);
            setTotalClips(clipsData.count);
        }
    }, [clipsData]);

    const handlers = useSwipeable({
        onSwipedUp: () => {
            // if (currentIndex < clipsDetails.length - 1) {
            setSwipeDirection("up");
            setIsTransitioning(true);

            setCurrentIndex((prevIndex) => prevIndex + 1);
            setSwipeCount(prevCount => prevCount + 1);
            if (swipeCount + 1 === 4 && clipsDetails.length < totalClips) {
                // setCurrentPage(prevPage => prevPage + 1);
                setSwipeCount(0);
            }

            setCommentVisible(false);
            setSubscriberVisible(true);
            setControlVisible(true);
            setVideoDetailsVisible(false);
            setDownloadVisible(false);
            setShareVisible(false);
            setReportVisible(false);

            if (PlayedVideoId.includes(id)) {
                setPlayedVideoId([...PlayedVideoId, clipsRandomId])
                const index = PlayedVideoId.indexOf(id);
                const lastindex = PlayedVideoId.length - 1;
                if (lastindex === index) {

                    navigate(`/clips/${clipsRandomId}`)
                } else {
                    navigate(`/clips/${PlayedVideoId[index + 1]}`)
                }
                // lastindex === index ?  navigate(`/clips/${clipsRandomId}`) : navigate(`/clips/${PlayedVideoId[index + 1]}`)

            } else {
                navigate(`/clips/${clipsRandomId}`)
            }
            // }
        },
        onSwipedDown: () => {
            if (currentIndex > 0) {
                setSwipeDirection("down");
                setIsTransitioning(true);
                setCurrentIndex((prevIndex) => prevIndex - 1);
                navigate(-1)
                setCommentVisible(false);
                setSubscriberVisible(true);
                setControlVisible(true);
                setVideoDetailsVisible(false);
                setDownloadVisible(false);
                setShareVisible(false);
                setReportVisible(false);
            }
        },
        preventScrollOnSwipe: true,
        trackTouch: true,
        trackMouse: true,
    });

    const springProps = useSpring({
        transform: swipeDirection === "up"
            ? "translateY(-100%)"
            : swipeDirection === "down"
                ? "translateY(100%)"
                : "translateY(0%)",
        opacity: isTransitioning ? 0 : 1,
        from: { transform: "translateY(0%)", opacity: 1 },
        reset: true,
        config: { tension: 170, friction: 26 },
        onRest: () => {
            setSwipeDirection("");
            setIsTransitioning(false); // End transition after animation
        },
    });

    const handleComment = () => {
        setSubscriberVisible(!subscriberVisible);
        setControlVisible(!controlVisible);
        setCommentVisible(!commentVisible);
    }

    const handleVideoDetails = () => {
        setSubscriberVisible(!subscriberVisible);
        setControlVisible(!controlVisible);
        setCommentVisible(false);
        setVideoDetailsVisible(!videoDetailsVisible);
    }

    const handleDownload = () => {
        setSubscriberVisible(!subscriberVisible);
        setControlVisible(!controlVisible);
        setCommentVisible(false);
        setDownloadVisible(!downloadVisible);
    }

    const handleShare = () => {
        setSubscriberVisible(!subscriberVisible);
        setControlVisible(!controlVisible);
        setCommentVisible(false);
        setShareVisible(!shareVisible);
    }

    const handleReport = () => {
        setSubscriberVisible(!subscriberVisible);
        setControlVisible(!controlVisible);
        setCommentVisible(false);
        setReportVisible(!reportVisible);
    }

    const handleVideoClick = () => {
        if (videoRef.current) {
            if (isPaused) {
                videoRef.current.play().catch(error => {
                    console.error("Error playing video:", error);
                });
                setIsPaused(false);
            } else {
                videoRef.current.pause();
                setIsPaused(true);
            }
        }
    };

    if (loading) return <div className="text-white h-[80vh] flex justify-center items-center">
        <ClipLoader color={"#fff"}
            loading={loading}
            size={30}
            speedMultiplier={1.5} />

    </div>;

    if (error) return <div className="text-white">Error loading clips</div>;

    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center sticky top-0 z-50">
                {/* <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / Clips / V1 / 21 Aug, 2024
                </p> */}
                <p className="text-black text-[9px] py-[2px]">{copyright}</p>
            </div>

            <div {...handlers} className="p-0 m-0 h-[100vh] w-full overflow-hidden">
                <animated.div
                    style={{ ...springProps, position: 'absolute', width: '100%', height: '100%' }}
                    className="w-full h-full"
                >
                    {filteredVideo.length > 0 && (
                        <video
                            ref={videoRef}
                            loop
                            muted
                            autoPlay
                            preload="auto"
                            disablePictureInPicture
                            key={id}
                            className="w-full h-full object-cover"
                            src={video}
                            onClick={handleVideoClick}
                        />
                    )}

                    {/* clips uploading */}
                    {progress > 0 && <div className="absolute w-full top-0 flex flex-col items-center justify-center">
                        <div className="flex w-full bg-[#00000065] px-2 py-1">
                            <div className="grid grid-cols-12 w-full">
                                <div className="col-span-1 flex items-center justify-start">
                                    <video
                                        muted
                                        autoPlay
                                        controls={false}
                                        className="h-5 w-5 rounded-[3px] object-cover cursor-pointer"
                                        src={videoData}
                                    />
                                </div>
                                <div className="col-span-11  w-full flex justify-between items-center ml-[5px]">
                                    <p className="text-[9.5px] w-[10rem] line-clamp-1 leading-[11.5px] text-white">
                                        {videoDescription}
                                    </p>
                                    <p className="text-[9.5px] line-clamp-1 leading-[11.5px] text-white">
                                        {progress}% . uploading...
                                    </p>
                                </div>
                                <div className="col-span-12 py-1">
                                    <Line percent={progress} strokeWidth="1" className="w-full" strokeColor="#0DD315" />
                                </div>
                            </div>
                        </div>
                    </div>}

                    {/* Comments */}
                    {commentVisible &&
                        <Comment
                            handleComment={handleComment}
                            clipsId={id}
                        />
                    }

                    {/* Controls */}
                    {controlVisible &&
                        <Controls
                            currentIndex={currentIndex}
                            videoData={clipsVideoData}
                            filteredVideo={filteredVideo}
                            handleComment={handleComment}
                            handleShare={handleShare}
                            handleDownload={handleDownload}
                            handleReport={handleReport}
                            setSavedClips={setSavedClips}
                            savedClips={savedClips}
                            clipsId={id}
                        />
                    }

                    {/* Subscribe */}
                    {subscriberVisible &&
                        <Subscribe
                            currentIndex={currentIndex}
                            filteredVideo={filteredVideo}
                            isSubscribed={isSubscribed}
                            setIsSubscribed={setIsSubscribed}
                            handleVideoDetails={handleVideoDetails}
                        />
                    }

                    {/* Video Details */}
                    {videoDetailsVisible &&
                        <VideoDetails
                            videoData={filteredVideo}
                            currentIndex={currentIndex}
                            isSubscribed={isSubscribed}
                            setIsSubscribed={setIsSubscribed}
                            handleVideoDetails={handleVideoDetails}
                            navigate={navigate}
                        />
                    }

                    {/* Download */}
                    {downloadVisible &&
                        <Download
                            videoData={filteredVideo}
                            currentIndex={currentIndex}
                            isSubscribed={isSubscribed}
                            setIsSubscribed={setIsSubscribed}
                            handleDownload={handleDownload}
                            video={video}
                        />
                    }

                    {/* Share */}
                    {shareVisible &&
                        <Share
                            mainurl={"https://kindviewer.com/clips/"}
                            handleShare={handleShare}
                            id={id}
                        />
                    }

                    {/* Report */}
                    {reportVisible &&
                        <Report
                            selectedReports={selectedReports}
                            setSelectedReports={setSelectedReports}
                            setIsReported={setIsReported}
                            handleReport={handleReport}
                            isReported={isReported}
                            clipsId={clipsId}
                        />
                    }
                </animated.div>
            </div>
        </Fragment>
    );
};

export default ClipsMainPage;


