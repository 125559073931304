import React from "react";
import HomePage from "../pages/HomePage";
import EnterOTP from "../pages/EnterOTP";
import LoginPage from "../pages/LoginPage";
import ChangePass from "../pages/ChangePass";
import SignUpPage from "../pages/SignUpPage";
import RecoverPass from "../pages/RecoverPass";
import Footer from "../components/Footer";
import NotFoundPage from "../pages/NotFoundPage";
import Account from "../pages/Account";
import VideoDetails from "../components/postVideo/VideoDetails";
import Notification from "../pages/notification/Notification";
import UploadVideo from "../pages/uploadvideo/UploadVideo";

import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";

import CreateVideo from "../pages/create/CreateVideo";
import CreatePost from "../pages/create/CreatePost";
import CreateClips from "../pages/create/CreateClips";
import GoLive from "../pages/create/GoLive";
import TrendingPage from "../pages/TrendingPage";
import HistoryPage from "../pages/HistoryPage";
import LikedVideoPage from "../pages/LikedVideoPage";
import DownloadedPage from "../pages/DownloadedPage";
import WatchList from "../pages/WatchList";
//playlist
import PlayList from "../pages/PlayList";
import CreatePlaylist from "../components/playlist/CreatePlaylist";
import PlaylistVideos from "../components/playlist/PlayListVideos";

import EditAccount from "../pages/EditAccount";
import SettingsMain from "../pages/settings/SettingsMain";
import SearchPage from "../pages/SearchPage";

import BlogPage from "../pages/legal/BlogPage";
import BlogViewPage from "../pages/legal/BlogViewPage";
import AboutPage from "../pages/legal/AboutPage";
import MediaPage from "../pages/legal/MediaPage";
import HelpPage from "../pages/legal/HelpPage";
import ClipsMainPage from "../pages/ClipsMainPage";
import CreateNews from "../pages/create/CreateNews";
import ViewLiveNews from "../pages/ViewLiveNews";
import Subscribed from "../pages/Subscribed";
import Podcast from "../pages/Podcast";
import LiveStream from "../pages/LiveStream";
import Sponsored from "../pages/Sponsored";
import OtherUserAccount from "../pages/OtherUserAccount";
import CreatePodcast from "../pages/create/CreatePodcast";
import TermsPage from "../pages/legal/TermsPage";
import PrivacyPage from "../pages/legal/PrivacyPage";
import ContactPage from "../pages/legal/ContactPage";
import SecurityPage from "../pages/SecurityPage";
import PrivateRoute from "../utils/PrivateRoute";
import MusicPage from "../pages/MusicPage";
import CalendarPage from "../pages/CalendarPage";
import ComingSoon from "../pages/ComingSoon";
import UnderConstruction from "../pages/UnderConstruction";
import MusicList from "../components/music/MusicList";
import UploadMusic from "../components/music/UploadMusic";
import SavedClips from "../pages/clips/SavedClips";
import EnterSignupOtp from "../pages/EnterSignupOtp";
import PodcastVideoDetails from "../components/podcast/PodcastVideoDetails";
import BlockedChannels from "../pages/settings/BlockedChannels";

const PaddedRoute = ({ element }) => {
    return (
        <div className="pb-[6.2rem]">
            {element}
        </div>
    );
};

const MainRouter = () => {
    const FooterConditional = () => {
        const location = useLocation();
        const excludedPaths = [
            "/", "/login", "/signup", "/recoverpass", "/enterotp", "/changepass", "/soon", "/underconstruction", "/gethelp", "/termspolicy", "/privacypolicy", "/signup/verify/otp"
        ];
        if (!excludedPaths.includes(location.pathname)) return <Footer />;
        return null;
    };

    return (
        <Router>
            <div className="grid grid-cols-11 h-screen">
                <div className="hidden lg:block col-span-4"></div>

                <div className="col-span-11 lg:col-span-3 lg:flex lg:items-center lg:justify-center">
                    <div className="relative h-screen lg:h-[88vh] w-full lg:w-[75%] lg:rounded-[1rem] lg:shadow-[0_0px_8px_rgb(0,0,0,0.9)] overflow-hidden">
                        <div className="h-full overflow-y-scroll bg-black">
                            <Routes>
                                <Route index element={<SecurityPage />} />
                                <Route path="*" element={<NotFoundPage />} />
                                <Route path="/login" element={<LoginPage />} />
                                <Route path="/signup" element={<SignUpPage />} />
                                <Route path="/signup/verify/otp" element={<EnterSignupOtp />} />
                                <Route path="/recoverpass" element={<RecoverPass />} />
                                <Route path="/enterotp" element={<EnterOTP />} />
                                <Route path="/changepass" element={<ChangePass />} />
                                <Route path="/soon" element={<ComingSoon />} />
                                <Route path="/underconstruction" element={<UnderConstruction />} />
                                <Route path="/gethelp" element={<PaddedRoute element={<HelpPage />} />} />
                                <Route path="/termspolicy" element={<PaddedRoute element={<TermsPage />} />} />
                                <Route path="/privacypolicy" element={<PaddedRoute element={<PrivacyPage />} />} />

                                <Route path="/" element={<PrivateRoute />} >

                                    <Route path="/home" element={<PaddedRoute element={<HomePage />} />} />
                                    <Route path="/account" element={<PaddedRoute element={<Account />} />} />
                                    <Route path="/video/:id" element={<PaddedRoute element={<VideoDetails />} />} />

                                    <Route path="/notification" element={<PaddedRoute element={<Notification />} />} />
                                    <Route path="/uploadvideo" element={<PaddedRoute element={<UploadVideo />} />} />

                                    <Route path="/createvideo" element={<PaddedRoute element={<CreateVideo />} />} />
                                    <Route path="/createpost" element={<PaddedRoute element={<CreatePost />} />} />
                                    <Route path="/createclips" element={<PaddedRoute element={<CreateClips />} />} />
                                    <Route path="/createnews" element={<PaddedRoute element={<CreateNews />} />} />
                                    <Route path="/golive" element={<PaddedRoute element={<GoLive />} />} />

                                    <Route path="/trending" element={<PaddedRoute element={<TrendingPage />} />} />
                                    <Route path="/history" element={<PaddedRoute element={<HistoryPage />} />} />
                                    <Route path="/likedvideos" element={<PaddedRoute element={<LikedVideoPage />} />} />
                                    <Route path="/downloads" element={<PaddedRoute element={<DownloadedPage />} />} />
                                    <Route path="/watchlist" element={<PaddedRoute element={<WatchList />} />} />
                                    <Route path="/playlist" element={<PaddedRoute element={<PlayList />} />} />
                                    <Route path="/createplaylist" element={<PaddedRoute element={<CreatePlaylist />} />} />
                                    <Route path="/edit/playlist/:id" element={<PaddedRoute element={<CreatePlaylist />} />} />
                                    <Route path="/playlist/:id" element={<PaddedRoute element={<PlaylistVideos />} />} />
                                    <Route path="/edit/account" element={<PaddedRoute element={<EditAccount />} />} />
                                    <Route path="/settings" element={<PaddedRoute element={<SettingsMain />} />} />
                                    <Route path="/blockedchannels" element={<PaddedRoute element={<BlockedChannels />} />} />
                                    <Route path="/search" element={<PaddedRoute element={<SearchPage />} />} />

                                    <Route path="/clips/:clipsId" element={<PaddedRoute element={<ClipsMainPage />} />} />
                                    {/* <Route path="/clips/:" element={<PaddedRoute element={<ClipsMainPage />} />} /> */}
                                    <Route path="/clips/saved" element={<PaddedRoute element={<SavedClips />} />} />
                                    <Route path="/live/news" element={<PaddedRoute element={<ViewLiveNews />} />} />
                                    <Route path="/livestream" element={<PaddedRoute element={<LiveStream />} />} />
                                    <Route path="/podcasts" element={<PaddedRoute element={<Podcast />} />} />
                                    <Route path="/subscribed" element={<PaddedRoute element={<Subscribed />} />} />
                                    <Route path="/sponsore" element={<PaddedRoute element={<Sponsored />} />} />
                                    <Route path="/otheruser/account/:id" element={<PaddedRoute element={<OtherUserAccount />} />} />
                                    <Route path="/create/podcast" element={<PaddedRoute element={<CreatePodcast />} />} />
                                    <Route path="/edit/podcast/:podcastId" element={<PaddedRoute element={<CreatePodcast />} />} />

                                    {/* THIS COMPONENET NEED TO DELETE ON FUTUTRE */}
                                    <Route path="/podcast/video/:id" element={<PaddedRoute element={<PodcastVideoDetails />} />} />

                                    <Route path="/blog/:id" element={<PaddedRoute element={<BlogViewPage />} />} />
                                    <Route path="/blog" element={<PaddedRoute element={<BlogPage />} />} />

                                    <Route path="/about" element={<PaddedRoute element={<AboutPage />} />} />
                                    <Route path="/media" element={<PaddedRoute element={<MediaPage />} />} />
                                    <Route path="/help" element={<PaddedRoute element={<HelpPage />} />} />
                                    <Route path="/terms" element={<PaddedRoute element={<TermsPage />} />} />
                                    <Route path="/privacy" element={<PaddedRoute element={<PrivacyPage />} />} />

                                    <Route path="/contact" element={<PaddedRoute element={<ContactPage />} />} />

                                    <Route path="/music" element={<PaddedRoute element={<MusicPage />} />} />
                                    <Route path="/upload-music" element={<PaddedRoute element={<UploadMusic />} />} />
                                    <Route path="/music-list" element={<PaddedRoute element={<MusicList />} />} />

                                    <Route path="/calendar" element={<PaddedRoute element={<CalendarPage />} />} />
                                </Route>
                            </Routes>
                            <div className="absolute bottom-0 w-full z-10">
                                <FooterConditional />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="hidden lg:block col-span-4"></div>
            </div>
        </Router>
    );
}

export default MainRouter;
