import React, { useState } from "react";
import logo from "../assets/kv_logo.png";
import { BiHide, BiShow } from "react-icons/bi";
import { Button } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { securityLogin } from "../redux/apislice/securitySlice";

import PulseLoader from "react-spinners/PulseLoader";
import toast, { Toaster } from 'react-hot-toast';

const SecurityPage = () => {
   const navigate = useNavigate();
   const [email, setEmail] = useState("");
   const [password, setPassword] = useState("");
   const [showPassword, setShowPassword] = useState(false);

   const dispatch = useDispatch();
   const { loading } = useSelector((state) => state.security);

   const handleEvent = (e) => {
      e.preventDefault();
      let loginDetails = { email, password };

      dispatch(securityLogin(loginDetails)).then((result) => {
         if (result.payload) {
            toast.success("Login Successful");
            setTimeout(() => navigate("/login"), 1000);
         } else {
            toast.error("Invalid Credentials");
         }
      });
   }

   return (
      <div className="bg-[#040415] h-full flex flex-col items-center justify-start pt-[4rem] px-4 relative">
         <Toaster
            position="top-right"
            reverseOrder={false}
         />
         <img
            alt="KV"
            src={logo}
            className="h-28 w-28 mb-3"
         />
         <form
            onSubmit={handleEvent}
            autoComplete="off"
            className="flex flex-col gap-y-4 p-4 pt-6 rounded-[10px] border-[2px] border-[#9a9a9a] hover:border-[#ffffff] w-full transition-all duration-500 hover:shadow-2xl"
         >
            <div className="flex flex-col items-center justify-center gap-x-1 mt-[-1rem]">
               <p className="text-[24px] md:text-[20px] font-bold uppercase text-center text-[#ffffff]">
                  Security Login
               </p>
               <p className="text-[12px] text-white mt-2">
                  Enter your email and password to login
               </p>
            </div>
            <input
               required
               autoComplete="on"
               color="white"
               name="email"
               type="email"
               value={email}
               variant="standard"
               placeholder="Enter your email"
               onChange={(e) => setEmail(e.target.value)}
               className="py-2 px-3 rounded-lg outline-none bg-[#f1f1f1] focus:bg-[#ffffff] placeholder:text-[14px] transition-all duration-500"
            />
            <div className="relative w-full">
               <input
                  required
                  autoComplete="on"
                  color="white"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  variant="standard"
                  placeholder="Enter your password"
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full py-2 pl-3 pr-12 rounded-lg outline-none bg-[#f1f1f1] focus:bg-[#ffffff] placeholder:text-[14px] transition-all duration-500"
               />
               <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute inset-y-0 right-3 flex items-center outline-none text-[#515153] cursor-pointer"
               >
                  {showPassword ? <BiHide className="text-[24px]" /> : <BiShow className="text-[24px]" />}
               </button>
            </div>
            <Button
               size="md"
               fullWidth
               color="white"
               type="submit"
               className="mt-3"
            >
               {loading ?
                  <PulseLoader
                     color={"#000"}
                     loading={loading}
                     size={10}
                     aria-label="Loading Spinner"
                     data-testid="loader"
                  />
                  : "continue"
               }
            </Button>
         </form>

         <p className="text-white text-[9px] text-center absolute bottom-2">
            Copyright © 2024  Galileo One Inc
         </p>
      </div>
   );
};

export default SecurityPage;