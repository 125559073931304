import React, { Fragment, useState, useEffect } from "react";
import { copyright } from "../utils/copyright";
import { CiSearch } from "react-icons/ci";
import { FaRegEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { profileDetails } from "../redux/apislice/profileSlice";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import Cookies from "js-cookie";
import Skeleton from "react-loading-skeleton";
import { updateProfile } from "../redux/apislice/editProfileSlice";

const EditAccount = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [profileImage, setProfileImage] = useState(null);
    const [profileSrc, setProfileSrc] = useState(null);
    const [fullName, setFullName] = useState("");
    const [hashtagName, setHashtagName] = useState("");
    const [description, setDescription] = useState("");
    const [handle, setHandle] = useState("");
    const userId = Cookies.get("userId");
    const profile = useSelector((state) => state.profile);
    const { loading, userDetails } = profile;
    const handleFullName = (e) => setFullName(e.target.value);
    const handleHashtagName = (e) => setHashtagName(e.target.value);
    const handleDescription = (e) => setDescription(e.target.value);
    const handleHandle = (e) => setHandle(e.target.value);


    const handleProfileSelect = (event) => {
        const file = event.target.files[0];
        if (file) {
            const imageURL = URL.createObjectURL(file);
            setProfileImage(file);
            setProfileSrc(imageURL);
        }
    };

    const editProfile = async () => {
        const data = new FormData();
        data.append("full_name", fullName);
        data.append("handle", handle);
        data.append("description", description);
        if (profileImage) {
            data.append("profile_picture", profileImage);
        }

        if (fullName && handle && description) {
            try {
                const response = await dispatch(updateProfile(data));
                if (response) {
                    toast.success("Profile updated successfully");

                    setTimeout(() => {
                        navigate("/account");
                    }, 2000);
                }
            } catch (error) {
                toast.error("Profile update failed");
            }

        } else {
            toast.error("Please fill all the fields");
        }

    }

    useEffect(() => {
        dispatch(profileDetails(userId));
    }, [dispatch, userId]);


    useEffect(() => {
        if (userDetails) {
            setFullName(userDetails.full_name);
            setHashtagName(userDetails.handle);
            setDescription(userDetails.description);
            setHandle(userDetails.handle);
            setProfileSrc(userDetails.profile_picture);
        }
    }, [userDetails])
    return (
        <Fragment>
            <Toaster position="right-top" />
            <div className="bg-[#d9d9d9] text-center">
                {/* <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / Edit Account / V1 / 15 Aug, 2024
                </p> */}
                <p className="text-black text-[9px] py-[2px]">{copyright}</p>
            </div>

            <div className="sticky top-0 grid grid-cols-12 items-center px-2 py-[6px] z-10 bg-[#1e1e1e]">
                <div className="col-span-6 flex items-center justify-start gap-3">
                    <IoMdArrowBack
                        onClick={() => navigate(-1)}
                        className="text-white h-5 w-5 cursor-pointer ml-[-3px]"
                    />
                    <p className="text-white text-[16px] font-medium">Edit Account</p>
                </div>
                <div className="col-span-6 flex items-center justify-end gap-3 ">
                    <CiSearch
                        onClick={() => navigate("/search")}
                        className="text-white h-5 w-5 cursor-pointer mr-[-2px]"
                    />
                </div>
            </div>

            {/* Edit Account form will be displayed here */}
            <div className="px-2 my-2">
                <div className="relative w-20 h-20 mb-3">
                    {!loading && <>
                        <img
                            src={profileSrc}
                            alt="profile"
                            className="absolute top-0 left-0 w-full h-full rounded-full object-cover"
                        />
                        <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 rounded-full"></div>
                        <FaRegEdit className="absolute cursor-pointer top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-[18px]" />
                        <input
                            type="file"
                            required
                            accept="image/*"
                            className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
                            onChange={(e) => handleProfileSelect(e)}
                        />
                    </>}
                    {loading && <div className="absolute top-0 left-0 w-full h-full rounded-full object-cover">
                        <Skeleton circle style={{ height: "100%", width: "100%" }} baseColor="#202020" highlightColor="#444" /></div>}
                </div>
                <div className="mb-3">
                    <p className="text-[#ffffff] text-[12px] font-medium">Name</p>
                    <input
                        type="text"
                        required
                        placeholder="Enter your name"
                        value={fullName}
                        onChange={(e) => handleFullName(e)}
                        className="border-b border-gray-300 py-1 w-full text-[12px] focus:outline-none focus:border-green-500 bg-black text-white"
                    />
                </div>
                <div className="mb-3">
                    <p className="text-[#ffffff] text-[12px] font-medium">Tag Name</p>
                    <input
                        type="text"
                        placeholder="Enter your tag name"
                        value={hashtagName}
                        onChange={(e) => handleHashtagName(e)}
                        className="border-b border-gray-300 py-1 w-full text-[12px] focus:outline-none focus:border-green-500 bg-black text-white"
                    />
                </div>
                <div className="mb-3">
                    <p className="text-[#ffffff] text-[12px] font-medium">Channel Name</p>
                    <input
                        type="text"
                        required
                        placeholder="Enter your tag name"
                        value={handle}
                        onChange={(e) => handleHandle(e)}
                        className="border-b border-gray-300 py-1 w-full text-[12px] focus:outline-none focus:border-green-500 bg-black text-white"
                    />
                </div>
                <div className="mb-3">
                    <p className="text-[#ffffff] text-[12px] font-medium">Profile URL</p>
                    <input
                        type="text"
                        readOnly
                        placeholder="Enter your profile URL"
                        value={"https://www.domain.com/user/yourchannel"}
                        // value={"https://www.domain.com/user/yourchannel"}
                        className="border-b border-gray-300 py-1 w-full text-[12px] focus:outline-none focus:border-green-500 bg-black text-white"
                    />
                </div>
                <div className="mb-3">
                    <p className="text-[#ffffff] text-[12px] font-medium">Description</p>
                    <input
                        required
                        type="text"
                        value={description}
                        onChange={(e) => handleDescription(e)}
                        placeholder="Enter your description"
                        className="border-b border-gray-300 py-1 w-full text-[12px] focus:outline-none focus:border-green-500 bg-black text-white"
                    />
                </div>
                <button onClick={() => editProfile()} className="bg-[#181818] hover:bg-green-500   hover:border-[0.5px] hover:border-[#0c2812] text-white py-1 w-full rounded-md text-[14px]">
                    Save
                </button>
            </div>
        </Fragment>
    )
}

export default EditAccount