import React, { useState } from "react";
import kv_logo from "../assets/kv_logo.png";
import { copyright } from "../utils/copyright";
import { social_logo } from "../data";
import { useNavigate } from "react-router-dom";
import { userSignup } from "../redux/apislice/authSlice";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import { BiHide, BiShow } from "react-icons/bi";
import PulseLoader from "react-spinners/PulseLoader";
import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';

const SignUpPage = () => {
    const lable = "KV / FE / SIGNUP / V1 / 27 July, 2024";

    const navigate = useNavigate();
    const dispatch = useDispatch();

    //global state management
    const { loading } = useSelector((state) => state.auth);

    //local stage management
    const [full_name, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [date_of_birth, setDateofbirth] = useState("");
    const [gender, setGender] = useState("");
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);


    const togglePasswordVisibility = () => setShowPassword(!showPassword);
    const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);


    const handleSubmit = async (e) => {
        e.preventDefault();

        const passwordStrength = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

        let signupDetails = {
            full_name, email, password, date_of_birth, gender, password2
        };
        if (password !== password2) {
            toast.error('Passwords do not match')
        }
        else {
            if (!passwordStrength.test(password)) {
                toast.error('Password must be at least 8 characters, contain an uppercase letter, a lowercase letter, a number, and a special character.')
            } else {
                dispatch(userSignup(signupDetails)).then((result) => {
                    if (result.error) {
                        toast.error("Signup failed! Please try again.");
                        if (result?.payload?.date_of_birth) {
                            toast.error(result?.payload?.date_of_birth[0]);
                        }
                        if (result?.payload?.email) {
                            toast.error(result?.payload?.email[0]);
                        }
                    } else {
                        toast.success("Signup successful!");
                        const encryptedEmail = encryptData(email);
                        Cookies.set("email_id", encryptedEmail)
                        encryptData(email);
                        setTimeout(() => navigate("/signup/verify/otp"), 3000);
                        setFullName("");
                        setEmail("");
                        setPassword("");
                        setPassword2("");
                        setDateofbirth("");
                        setGender("");
                    }
                });
            }
        }
    }


    // Encrypt data using AES encryption
    const encryptData = (data) => {
        return CryptoJS.AES.encrypt(data, "secret_key").toString();
    }

    return (
        <div className="h-full bg-[#2176a8] overflow-y-scroll pb-2">
            <Toaster position="top-right" />
            <div className="bg-[#d9d9d9] text-center">
                {/* <p className="text-red-600 text-[9px] uppercase font-bold">{lable}</p>*/}
                <p className="text-black text-[9px] py-[2px]">{copyright}</p>
            </div>

            {/* <h1 className="text-[3rem] px-3 font-semibold uppercase text-white">
                kv
            </h1> */}
            <div className="flex items-center mt-3">
                <img
                    src={kv_logo}
                    alt="logo"
                    onClick={() => navigate("/home")}
                    className="object-cover cursor-pointer w-[4rem] ml-3"
                />
                <p className="text-[34px] font-bold text-[#ffffff] ml-[10px]">Kind &nbsp;Views</p>
            </div>

            <form className="px-4 mt-2" onSubmit={handleSubmit}>
                <p className="text-white text-[20px] md:text-[16px] text-center">
                    Let’s Get Started
                </p>
                <div className="mt-[15px] md:mt-[2px]">
                    <label className="block text-[#ffffff] mb-[2px] font-normal">
                        Full name
                    </label>
                    <input
                        required
                        value={full_name}
                        onChange={(e) => setFullName(e.target.value)}
                        type="text"
                        placeholder="Enter your Full name"
                        className="w-full px-2 py-[8px] md:py-[6px] text-white placeholder:text-[17px] md:placeholder:text-[15px] bg-transparent border border-[#ffffff] rounded-md focus:outline-none focus:ring-2 focus:ring-[#ffffff] focus:border-transparent placeholder:text-white"
                    />
                </div>
                <div className="mt-[6px] md:mt-[2px]">
                    <label className="block text-[#ffffff] mb-[2px] font-normal">
                        Email
                    </label>
                    <input
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        placeholder="Enter your email address"
                        className="w-full px-2 py-[8px] md:py-[6px] text-white placeholder:text-[17px] md:placeholder:text-[15px] bg-transparent border border-[#ffffff] rounded-md focus:outline-none focus:ring-2 focus:ring-[#ffffff] focus:border-transparent placeholder:text-white"
                    />
                </div>
                <div className="mt-[6px] md:mt-[2px]">
                    <label className="block text-[#ffffff] mb-[2px] font-normal">
                        Password
                    </label>
                    <div className="relative">
                        <input
                            required
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            type={showPassword ? "text" : "password"}
                            placeholder="Enter your password"
                            className="w-full px-2 py-[8px] md:py-[6px] text-white placeholder:text-[17px] md:placeholder:text-[15px] bg-transparent border border-[#ffffff] rounded-md focus:outline-none focus:ring-2 focus:ring-[#ffffff] focus:border-transparent placeholder:text-white"
                        />
                        <button
                            type="button"
                            onClick={togglePasswordVisibility}
                            className="absolute inset-y-0 right-3 flex items-center outline-none text-[#ffffff] cursor-pointer"
                        >
                            {showPassword ? <BiHide className="text-[24px] md:text-[20px]" /> : <BiShow className="text-[24px] md:text-[20px]" />}
                        </button>
                    </div>

                </div>
                <div className="mt-[6px] md:mt-[2px]">
                    <label className="block text-[#ffffff] mb-[2px] font-normal">
                        Confirm Password
                    </label>
                    <div className="relative">
                        <input
                            required
                            value={password2}
                            onChange={(e) => setPassword2(e.target.value)}
                            type={showConfirmPassword ? "text" : "password"}
                            placeholder="Enter your password again"
                            className="w-full px-2 py-[8px] md:py-[6px] text-white placeholder:text-[17px] md:placeholder:text-[15px] bg-transparent border border-[#ffffff] rounded-md focus:outline-none focus:ring-2 focus:ring-[#ffffff] focus:border-transparent placeholder:text-white"
                        />

                        <button
                            type="button"
                            onClick={toggleConfirmPasswordVisibility}
                            className="absolute inset-y-0 right-3 flex items-center  outline-none text-[#ffffff] cursor-pointer"
                        >
                            {showConfirmPassword ? <BiHide className="text-[24px] md:text-[20px]" /> : <BiShow className="text-[24px] md:text-[20px]" />}
                        </button>
                    </div>

                </div>
                <div className="mt-[6px] md:mt-[2px] flex flex-col md:flex-row items-start md:justify-between md:gap-x-4">
                    <div className="w-full">
                        <label className="block text-[#ffffff] mb-[2px] font-normal">
                            Date of Birth
                        </label>
                        <div className="relative">
                            <input
                                required
                                value={date_of_birth}
                                onChange={(e) => setDateofbirth(e.target.value)}
                                type="date"
                                placeholder="YYYY/MM/DD"
                                pattern="\d{4}/\d{2}/\d{2}"
                                title="Enter date as YYYY/MM/DD"
                                className="w-full px-2 py-[7px] md:py-[5px] text-white placeholder:text-[17px] md:placeholder:text-[15px] bg-transparent border border-[#ffffff] rounded-md focus:outline-none focus:ring-2 focus:ring-[#ffffff] focus:border-transparent"
                            />
                        </div>
                    </div>
                    <div className="w-full mt-[6px] md:mt-[0px]">
                        <label className="block text-[#ffffff] mb-[2px] font-normal">
                            Gender
                        </label>
                        <div className="relative w-full">
                            <select
                                required
                                onChange={(e) => setGender(e.target.value)}
                                className="w-full bg-[#2176a8] px-2 py-[8px] md:py-[6px] text-white placeholder:text-[17px] md:placeholder:text-[15px] border border-[#ffffff] rounded-md focus:outline-none focus:ring-2 focus:ring-[#ffffff] appearance-none focus:border-transparent"
                            >
                                <option value={""}>Select</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Other">Other</option>
                            </select>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.2" stroke="currentColor" className="h-5 w-5 ml-1 absolute top-2.5 right-2.5 text-slate-700 text-white">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="flex items-start mt-2 md:mt-1">
                    <input
                        required
                        type="checkbox"
                        checked={acceptTerms}
                        onChange={(e) => setAcceptTerms(e.target.checked)}
                        className="mr-1 mt-[3px] border-none focus:outline-none cursor-pointer"
                    />
                    <p className="text-white text-[13px] md:text-[12px]">
                        By creating an account, I agree to the
                        <span className="text-[#0DD315] ml-[4px] cursor-pointer" onClick={() => navigate("/termspolicy")}>Terms of Use, </span>
                        <span className="text-[#0DD315] ml-[4px] cursor-pointer" onClick={() => navigate("/privacypolicy")}>Privacy Policy </span>
                        and
                        <span className="text-[#0DD315] ml-[4px] cursor-pointer">Cookies Policy.</span>
                    </p>
                </div>

                <button
                    type="submit"
                    className="w-full py-2 mt-3 md:mt-1 text-white bg-[#00FF0A] rounded-md focus:outline-none"
                >
                    {loading ?
                        <PulseLoader
                            color={"#fff"}
                            loading={loading}
                            size={10}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        /> : " Sign Up"}

                </button>

                <div className="flex items-center justify-between gap-4 mt-3 md:mt-1">
                    <div className="h-[0.4px] bg-white rounded-full w-full" />
                    <p className="text-[12px] md:text-[10px] text-white whitespace-nowrap">or Sign Up with</p>
                    <div className="h-[0.4px] bg-white rounded-full w-full" />
                </div>

                <div className="flex items-center justify-between mt-2 md:mt-1">
                    {social_logo.map((data) => (
                        <div
                            key={data.id}
                            onClick={() => navigate("/soon")}
                            className="flex items-center justify-center bg-white p-2 rounded cursor-pointer"
                        >
                            {data.src ? (
                                <img
                                    alt={data.name}
                                    src={data.src}
                                    className="h-6 w-6 md:h-5 md:w-5"
                                />
                            ) : (
                                <div className="h-6 w-6 md:h-5 md:w-5">
                                    <p className="text-[18px] md:text-[14px] font-extrabold  text-center">
                                        {data.name}
                                    </p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                <div className="flex items-center justify-center gap-1 w-full mt-4 md:mt-2">
                    <p className="text-[16px] md:text-[14px] text-white">I have an account?</p>
                    <p className="font-semibold text-[#00FF0A] text-[16px] md:text-[14px] cursor-pointer" onClick={() => navigate("/login")}>Log in</p>
                </div>
            </form>
        </div>
    )
}

export default SignUpPage
