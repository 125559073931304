import React, { Fragment, useState, useEffect } from "react";
import { copyright } from "../../utils/copyright";
import { LuImagePlus } from "react-icons/lu";
import { AiOutlineClose } from "react-icons/ai";
import { Switch } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import imageCompression from 'browser-image-compression';
import { useDispatch } from "react-redux";
import { createPost } from "../../redux/apislice/createPost/createPostSlice";
import toast, { Toaster } from "react-hot-toast";

const CreatePost = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [image1, setImage1] = useState(null);
    const [image1Src, setImage1Src] = useState(null);
    const [image2, setImage2] = useState(null);
    const [image2Src, setImage2Src] = useState(null);
    const [text, setText] = useState('');
    const [is_draft, setIsDraft] = useState(null);
    const [time, setTime] = useState('');
    const [date, setDate] = useState('');
    const [schedulePost, setSchedulePost] = useState(false);
    const [visibility, setVisibility] = useState(false);


    const formatTime = (time24) => {
        const [hours, minutes] = time24.split(':');
        const hours12 = (hours % 12) || 12;
        const ampm = hours >= 12 ? 'PM' : 'AM';
        return `${String(hours12).padStart(2, '0')}:${minutes} ${ampm}`;
    };

    const formatDate = (date) => {
        const [year, month, day] = date.split('-');
        return `${day}/${month}/${year}`;
    };

    useEffect(() => {
        // Retrieve images from localStorage
        const storedImage1 = localStorage.getItem('image1');
        const storedImage2 = localStorage.getItem('image2');
        setIsDraft(false)
        if (storedImage1) {
            setImage1(storedImage1);
        }
        if (storedImage2) {
            setImage2(storedImage2);
        }
    }, []);

    const handleImageChange = async (e, imageKey) => {
        const file = e.target.files[0];
        if (file) {
            try {
                const options = {
                    maxSizeMB: 1,
                    maxWidthOrHeight: 1920,
                    useWebWorker: true
                };
                const compressedFile = await imageCompression(file, options);
                const reader = new FileReader();
                reader.onload = () => {
                    const result = reader.result;
                    if (imageKey === 'image1') {
                        setImage1(file);
                        setImage1Src(result);
                        // localStorage.setItem('image1', result);
                    } else {
                        setImage2(file);
                        setImage2Src(result);
                        // localStorage.setItem('image2', result);
                    }
                };
                reader.readAsDataURL(compressedFile);
            } catch (error) {
                console.error("Error compressing the image", error);
            }
        }
    };

    const removeImage = (imageKey) => {
        if (imageKey === 'image1') {
            setImage1(null);
            localStorage.removeItem('image1');
        } else {
            setImage2(null);
            localStorage.removeItem('image2');
        }
    };

    const handleUpload = async () => {
        const formData = new FormData();
        formData.append('image1', image1);
        formData.append('image2', image2);
        if (text) {
            formData.append('text', text);
        }
        formData.append('is_draft', is_draft ? "True" : "False");
        formData.append('visibility', visibility ? "private" : 'public');
        if (time) {
            formData.append('time', formatTime(time));
        }
        if (date) {
            formData.append('date', formatDate(date));
        }
        if (image1 && image2 && text && (schedulePost ? time && date : true)) {
            const toastId = toast.loading("Uploading post...");
            try {
                await dispatch(createPost(formData)).then((res) => {
                    if (res?.payload) {
                        toast.success("Post uploaded successfully", { id: toastId });
                        setTimeout(() => {
                            navigate("/home");
                        }, 2000)
                    }
                })
            } catch (error) {
                console.error("Error uploading post", error);
                toast.error("Post upload failed", { id: toastId });
            }
        }
        else {
            toast.error("Please fill all fields");
        }
    }
    const handleDraftUpload = async () => {
        const formData = new FormData();
        formData.append('image1', image1);
        formData.append('image2', image2);
        if (text) {
            formData.append('text', text);
        }
        formData.append('is_draft', "True");
        formData.append('visibility', visibility ? "private" : 'public');
        if (time) {
            formData.append('time', formatTime(time));
        }
        if (date) {
            formData.append('date', formatDate(date));
        }

        if (image1 && image2 && text) {
            const toastId = toast.loading("Saving draft...");
            try {
                await dispatch(createPost(formData)).then((res) => {
                    if (res?.payload) {
                        toast.success("Draft saved successfully", { id: toastId });
                        setTimeout(() => {
                            navigate("/home");
                        }, 2000)
                    }
                })
            } catch (error) {
                console.error("Error saving draft", error);
                toast.error("Draft save failed", { id: toastId });
            }
        }
        else {
            toast.error("Draft need images and text")
        }

    }
    return (
        <Fragment>
            <Toaster position="top-right" />
            <div className="bg-[#d9d9d9] text-center">
                {/* <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / create post / V2 / 30 Sep, 2024
                </p> */}
                <p className="text-black text-[9px] py-[2px]">{copyright}</p>
            </div>

            <div className="p-2">
                <p className="text-center text-[16px] mt-[-6px] font-bold text-[#ffffff]">
                    Create Post
                </p>
                {/* First Image Input and Preview */}

                <div className="relative flex items-center mt-1 justify-center w-full h-[140px] bg-[#1f1f1f] rounded">
                    {image1 ? (
                        <div className="relative w-full h-full">
                            <img src={image1Src} alt="Preview" className="h-full w-full object-cover rounded" />
                            <button
                                onClick={() => removeImage('image1')}
                                className="absolute top-1 right-1 bg-[#1f1f1f] text-white rounded-full p-1 hover:bg-gray-500 transition-all"
                            >
                                <AiOutlineClose className="text-[14px]" />
                            </button>
                        </div>
                    ) : (
                        <label>
                            <LuImagePlus className="text-[20px] cursor-pointer text-[#ffffff]" />
                            <input
                                type="file"
                                accept="image/*"
                                className="hidden"
                                onChange={(e) => handleImageChange(e, 'image1')}
                            />
                        </label>
                    )}
                </div>

                {/* Second Image Input and Preview */}

                <div className="relative flex items-center mt-2 justify-center w-full h-[140px] bg-[#1f1f1f] rounded">
                    {image2 ? (
                        <div className="relative w-full h-full">
                            <img src={image2Src} alt="Preview" className="h-full w-full object-cover rounded" />
                            <button
                                onClick={() => removeImage('image2')}
                                className="absolute top-1 right-1 bg-[#1f1f1f] text-white rounded-full p-1 hover:bg-gray-500 transition-all"
                            >
                                <AiOutlineClose className="text-[14px]" />
                            </button>
                        </div>
                    ) : (
                        <label>
                            <LuImagePlus className="text-[20px] cursor-pointer text-[#ffffff]" />
                            <input
                                type="file"
                                accept="image/*"
                                className="hidden"
                                onChange={(e) => handleImageChange(e, 'image2')}
                            />
                        </label>
                    )}
                </div>

                {/* Other Fields */}

                <div className="flex flex-col mt-2">
                    <p className="text-[12px] text-white font-semibold">Add text</p>
                    <textarea
                        onChange={(e) => setText(e.target.value)}
                        value={text}
                        required
                        rows={2}
                        placeholder="Add something here..."
                        className="w-full mt-[2px] px-2 py-2 resize-none text-white placeholder:text-[13px] text-[13px] rounded border-none bg-[#1f1f1f] focus:outline-none"
                    />
                </div>

                {/* Additional Form Fields */}

                <div className="flex items-center justify-between my-2">
                    <p className="text-[12px] text-white">Private</p>
                    <Switch
                        ripple={visibility}
                        onChange={() => setVisibility(!visibility)}
                        className="h-4 w-[28.5px] checked:bg-[#0DD315] bg-[#636363]"
                    />
                </div>

                <div className="flex items-center justify-between my-2">
                    <p className="text-[12px] text-white">Schedule Post</p>
                    <Switch
                        ripple={schedulePost}
                        onChange={() => setSchedulePost(!schedulePost)}
                        className="h-4 w-[28.5px] checked:bg-[#0DD315] bg-[#636363]"
                    />
                </div>

                {/* Date and Time Fields */}
                {schedulePost &&
                    <div div className="flex items-center gap-x-3">
                        <input
                            value={date}
                            required
                            format="dd/MM/yyyy"
                            onChange={(e) => setDate(e.target.value)}
                            type="date"
                            className="w-full px-2 py-[6px] mt-2 rounded-md focus:outline-none text-[14px] bg-[#1f1f1f] text-[#ffffff]"
                        />
                        <input
                            value={time}
                            required
                            onChange={(e) => setTime(e.target.value)}
                            type="time"
                            className="w-full px-2 py-[6px] mt-2 rounded-md focus:outline-none text-[14px] bg-[#1f1f1f] text-[#ffffff]"
                        />
                    </div>
                }

                {/* Action Buttons */}

                <div className="flex items-center justify-between px-10 mt-5">
                    <p
                        onClick={() => navigate(-1)}
                        className="text-[13px] text-[#ffffff] font-bold cursor-pointer hover:text-red-500 transition-all duration-300"
                    >
                        Cancel
                    </p>
                    <p className="text-[13px] text-[#ffffff] font-bold cursor-pointer" onClick={() => handleDraftUpload()}>Draft</p>
                    <p onClick={() => handleUpload()} className="text-[13px] text-[#2ed034] hover:text-[#52ff55] font-bold cursor-pointer transition-all duration-300">
                        Upload
                    </p>
                </div>
            </div>
        </Fragment >
    );
};

export default CreatePost;