import React, { useCallback, useEffect, useState } from "react";
import { copyright } from "../utils/copyright";
import { CiSearch } from "react-icons/ci";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import fireLogo from "../assets/footer/fire_footer_icon.png";
import FourDotDrawer from "../components/common/FourDotDrawer";
import { trendingVideoList } from "../redux/apislice/trendingVideosSlice";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import SavePlaylist from "../components/common/SavePlaylist";
import Cookies from "js-cookie";

import toast, { Toaster } from "react-hot-toast";
import { blockChannel, blockVideo } from "../redux/apislice/blockSlice";
import { addWatchList } from "../redux/apislice/watchListSlice";
import { getDownload } from "../redux/apislice/downloadSlice";
import Share from "../components/clips/Share";

const TrendingPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [openVideoInfo, setOpenVideoInfo] = useState(null);
    const trendingVideos = useSelector((state) => state.trendingVideos);
    const { trendingVideoData, } = trendingVideos;
    const [savePlaylistModal, setSavePlaylistModal] = useState(false);
    const [shareVisible, setShareVisible] = useState(false);
    const userId = Cookies.get("userId");
    const [videoData, setVideoData] = useState([]);


    const handleSavePlaylist = () => {
        setSavePlaylistModal(true);
    }

    const handleToggle = (id) => {
        if (openVideoInfo === id) {
            setOpenVideoInfo(null);
        } else {
            setOpenVideoInfo(id);
        }
    };


    function timeAgo(timestamp) {
        const now = new Date(); // Current date and time
        const past = new Date(timestamp); // The timestamp you want to convert

        const diffInSeconds = Math.floor((now - past) / 1000); // Time difference in seconds

        // Define time intervals
        const intervals = {
            year: 31536000, // 365 days * 24 hours * 60 minutes * 60 seconds
            month: 2592000,  // 30 days * 24 hours * 60 minutes * 60 seconds
            week: 604800,    // 7 days * 24 hours * 60 minutes * 60 seconds
            day: 86400,      // 24 hours * 60 minutes * 60 seconds
            hour: 3600,      // 60 minutes * 60 seconds
            minute: 60,      // 60 seconds
            second: 1        // 1 second
        };

        // Calculate the largest time interval and its corresponding value
        for (const [key, value] of Object.entries(intervals)) {
            const timeDifference = Math.floor(diffInSeconds / value);
            if (timeDifference >= 1) {
                return `${timeDifference} ${key}${timeDifference > 1 ? 's' : ''} ago`;
            }
        }

        return 'just now'; // Default if the difference is less than 1 second
    }

    const handleShare = () => {
        setShareVisible(!shareVisible);
        if (!shareVisible) {
            setOpenVideoInfo(null)
        }
    }
    const handleBlockChannel = async (channelId) => {
        if (channelId === userId) {
            return toast('You cannot block yourself',
                {
                    icon: '❌',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                }
            );
        } else {
            await dispatch(blockChannel(channelId)).then((res) => {
                toast('Channel Blocked Successfully',
                    {
                        icon: '✅',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                    }
                );
                setVideoData(videoData.filter((data) => data.author.id !== channelId));
            })
        }
    }
    const handleAddWatchLater = async (videoId) => {
        try {
            await dispatch(addWatchList(videoId)).then((result) => {
                if (result.error) {
                    toast('Already Added to Watch Later',
                        {
                            icon: '❌',
                            style: {
                                borderRadius: '10px',
                                background: '#333',
                                color: '#fff',
                            },
                        }
                    );

                }
                else {
                    setTimeout(setOpenVideoInfo(null), 500);

                    toast('saved to watch later',
                        {
                            icon: '✅',
                            style: {
                                borderRadius: '10px',
                                background: '#333',
                                color: '#fff',
                            },
                        }
                    );
                }
            })

        } catch (error) {
            toast.error("Video already in Watch Later list",)
            console.log(error?.data?.message, "error");
        }
    }
    const handleDownload = async (videoId) => {
        await dispatch(getDownload(videoId)).then((res) => {
            if (res?.error) {
                toast('Already downloaded',
                    {
                        icon: '❌',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                    }
                );
            } else {
                toast('downloaded successfully',
                    {
                        icon: '✅',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                    }
                );
                setTimeout(setOpenVideoInfo(null), 500);
            }
        })
    }
    const handleNotIntrested = async (videoId) => {
        await dispatch(blockVideo(videoId)).then((res) => {
            toast('Removed from feed',
                {
                    icon: '✅',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                }
            );
            setVideoData(videoData.filter((data) => data.id !== videoId));
        })

    }


    // Fetch Trending Videos
    const fetchTrendingVideos = useCallback(async () => {
        await dispatch(trendingVideoList());
    }, [dispatch])


    useEffect(() => {
        setVideoData(trendingVideoData?.results);
    }, [trendingVideoData?.results])

    useEffect(() => {
        fetchTrendingVideos()
    }, [fetchTrendingVideos])

    return (
        <div>
            <Toaster position="top-right" />
            <div className="bg-[#d9d9d9] text-center">
                {/* <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / Trending / V1 / 14 Aug, 2024
                </p> */}
                <p className="text-black text-[9px] py-[2px]">{copyright}</p>
            </div>

            <div className="sticky top-0 grid grid-cols-12 items-center px-2 py-[6px] z-10 bg-[#1e1e1e] mb-2">
                <div className="col-span-8 flex items-center justify-start gap-3">
                    <IoMdArrowBack
                        onClick={() => navigate("/home")}
                        className="text-white h-5 w-5 cursor-pointer ml-[-3px]"
                    />
                    <div className="flex items-center gap-1">
                        <img alt="trending" src={fireLogo} className="h-6 w-6" />
                        <p className="text-white text-[16px] font-medium">Top Trending</p>
                    </div>
                </div>
                <div className="col-span-4 flex items-center justify-end gap-3">
                    <CiSearch
                        onClick={() => navigate("/search")}
                        className="text-white h-5 w-5 cursor-pointer mr-[-2px]"
                    />
                </div>
            </div>

            {/* Trending Page Content */}

            {trendingVideos.isLoading &&
                [1, 2, 3, 4, 5, 6, 7].map((_, index) => (<div key={index} className="grid grid-cols-12 px-2 py-1">
                    <div className="col-span-12">
                        <Skeleton baseColor="#202020" highlightColor="#444" style={{ height: "10rem", width: "100%" }} />
                    </div>
                    <div className="col-span-2">
                        <Skeleton baseColor="#202020" highlightColor="#444" circle style={{ height: "2rem", width: "2rem" }} />
                    </div>
                    <div className="col-span-9 flex flex-col justify-center -ml-2">
                        <div>
                            <Skeleton baseColor="#202020" highlightColor="#444" style={{ height: "10px", width: "60%" }} />
                        </div>
                        <div className="-mt-10">
                            <Skeleton baseColor="#202020" highlightColor="#444" style={{ height: "10px", width: "100%" }} />
                        </div>
                    </div>
                    <div className="col-span-1 flex justify-end">
                        <Skeleton baseColor="#202020" highlightColor="#444" style={{ height: "12px", width: "12px", borderRadius: "1px", }} />
                    </div>
                </div>
                ))}

            {trendingVideoData?.results?.map((data) => (
                <div className="pb-2" key={data.id} >
                    <img
                        alt="thumbnail"
                        src={data.thumbnail}
                        onClick={() => navigate(`/video/${data.id}`)}
                        className="w-full h-[10rem] px-2 object-cover rounded-sm cursor-pointer"
                    />
                    <div className="grid grid-cols-12 mt-1 px-2">
                        <div className="col-span-2">
                            <img
                                alt="profile"
                                className="h-8 w-8 rounded-full object-cover cursor-pointer"
                                src={data.author.profile_picture}
                            />
                            <div className="flex flex-col items-center justify-start ml-[-12px]">
                                <p className="text-white text-[13px]">{data.views_count}</p>
                                <p className="text-[#8d8d8d] text-[12px] mt-[-6px]">views</p>
                            </div>
                        </div>
                        <div className="col-span-9 ml-[-6px] flex flex-col items-start leading-[18px]">
                            <p className="text-white text-[14px] font-light line-clamp-1">{data.title}</p>
                            <p className="text-[#8d8d8d] text-[12px]">{data.author.handle} - {timeAgo(data.created_at)}</p>
                        </div>
                        <div className="col-span-1 flex items-start justify-end mt-[-2px]">
                            <p
                                onClick={() => handleToggle(data.id)}
                                className="text-white text-[14px] cursor-pointer"
                            >
                                : :
                            </p>
                        </div>
                    </div>

                    {openVideoInfo === data.id && (
                        <FourDotDrawer
                            title={data.title}
                            videoId={data.id}
                            savePlaylist={() => handleSavePlaylist()}
                            onClose={() => handleToggle(data.id)}
                            handleShare={handleShare}
                            handleBlockChannel={handleBlockChannel}
                            channelId={data?.author.id}
                            handleDownload={handleDownload}
                            handleNotIntrested={handleNotIntrested}
                            handleAddWatchLater={handleAddWatchLater}
                        />
                    )}

                    {savePlaylistModal && openVideoInfo === data.id && (
                        <div className="absolute top-0 z-50 h-full w-full">
                            <SavePlaylist onClose={() => setSavePlaylistModal(false)} title={data.title} videoId={data.id} />
                        </div>
                    )}

                    {shareVisible &&
                        <div className="absolute top-10  h-full w-full">
                            <Share
                                mainurl={"http://localhost:3000/video/"}
                                handleShare={handleShare}
                                id={data.id}
                            />
                        </div>
                    }
                </div>
            ))}
            {trendingVideoData?.results?.length === 0 && (
                <div className="text-white text-center mt-2">
                    <p>No trending videos found</p>
                </div>
            )}
        </div>
    )
}

export default TrendingPage