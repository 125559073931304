import React, { useState } from "react";
import kv_logo from "../assets/kv_logo.png";
import { copyright } from "../utils/copyright";
import { useNavigate } from "react-router-dom";
import { BiShow, BiHide } from "react-icons/bi";
import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';
import toast, { Toaster } from "react-hot-toast";
import { resetPassword } from "../redux/apislice/recoveryPasswordSlice";
import { useDispatch, useSelector } from "react-redux";
import PulseLoader from "react-spinners/PulseLoader";


const ChangePass = () => {
    const lable = "KV / FE / CHANGE PASSWORD / V1 / 27 July , 2024";
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loading } = useSelector((state) => state.recoveryPass);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [new_password, setNewPassword] = useState("");
    const [confirm_password, setConfirmPassword] = useState("");

    const togglePasswordVisibility = () => setShowPassword(!showPassword);
    const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);

    // Decrypt data using AES decryption
    const decryptData = (encryptedData) => {
        const bytes = CryptoJS.AES.decrypt(encryptedData, "secret_key");
        return bytes.toString(CryptoJS.enc.Utf8);
    }
    const passwordStrength = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    const email = decryptData(Cookies.get("email_id"))
    const otp = Cookies.get("otp")


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (new_password !== confirm_password) {
            toast.error('Passwords do not match')
        }
        else if (!passwordStrength.test(new_password)) {
            toast.error('Password must be at least 8 characters, contain an uppercase letter, a lowercase letter, a number, and a special character.')
        } else {
            const formData = {
                email,
                otp,
                new_password,
                confirm_password
            }
            try {
                const res = await dispatch(resetPassword(formData))
                if (res.payload) {
                    toast.success(res.payload.detail)
                    Cookies.remove("email_id")
                    Cookies.remove("otp")
                    Cookies.remove("password")
                    setTimeout(() => {
                        navigate("/login")
                    }, [2000])
                } else {
                    toast.error(res?.error)
                }

            } catch (error) {
                toast.error("Something went wrong")
            }
        }
    }

    return (
        <div className="h-full bg-[#2176a8] relative">
            <Toaster position="right-top" />
            <div className="bg-[#d9d9d9] text-center">
                {/* <p className="text-red-600 text-[9px] uppercase font-bold">{lable}</p>*/}
                <p className="text-black text-[9px] py-[2px]">{copyright}</p>
            </div>

            <div className="flex items-center mt-3">
                <img
                    src={kv_logo}
                    alt="logo"
                    onClick={() => navigate("/home")}
                    className="object-cover cursor-pointer w-[4rem] ml-3"
                />
                <p className="text-[34px] font-bold text-[#ffffff] ml-[10px]">Kind &nbsp;Views</p>
            </div>

            <form className="px-6 mt-6" onSubmit={handleSubmit}>
                <p className="text-white font-semibold text-[28px] md:text-[24px] text-center">
                    Change Password
                </p>
                <div className="mt-4">
                    <label className="block text-[#ffffff] font-normal text-[20px] md:text-[14px]" htmlFor="new-password">
                        New Password
                    </label>
                    <div className="relative">
                        <input
                            required
                            value={new_password}
                            onChange={(e) => setNewPassword(e.target.value)}
                            type={showPassword ? "text" : "password"}
                            placeholder="New Password"
                            id="new-password"
                            className="w-full pl-3 pr-10 py-3 md:py-2 mt-1 text-[18px] md:text-[14px] text-white bg-transparent border border-[#ffffff] rounded-md focus:outline-none focus:ring-2 focus:ring-[#ffffff] placeholder:text-white focus:border-transparent"
                        />
                        <button
                            type="button"
                            onClick={togglePasswordVisibility}
                            className="absolute inset-y-0 right-3 flex items-center text-[#ffffff] cursor-pointer"
                        >
                            {showPassword ? <BiHide className="text-[24px] md:text-[20px]" /> : <BiShow className="text-[24px] md:text-[20px]" />}
                        </button>
                    </div>
                </div>
                <div className="mt-4 md:mt-2">
                    <label className="block text-[#ffffff] font-normal text-[20px] md:text-[14px]" htmlFor="confirm-password">
                        Confirm Password
                    </label>
                    <div className="relative">
                        <input
                            required
                            value={confirm_password}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            type={showConfirmPassword ? "text" : "password"}
                            placeholder="Confirm Password"
                            id="confirm-password"
                            className="w-full pl-3 pr-10 py-3 md:py-2 mt-1 text-[18px] md:text-[14px] text-white bg-transparent border border-[#ffffff] rounded-md focus:outline-none focus:ring-2 focus:ring-[#ffffff] placeholder:text-white focus:border-transparent"
                        />
                        <button
                            type="button"
                            onClick={toggleConfirmPasswordVisibility}
                            className="absolute inset-y-0 right-3 flex items-center text-[#ffffff] cursor-pointer"
                        >
                            {showConfirmPassword ? <BiHide className="text-[24px] md:text-[20px]" /> : <BiShow className="text-[24px] md:text-[20px]" />}
                        </button>
                    </div>
                </div>
                <div className="mt-2 flex items-center justify-start">
                    <div className="flex items-center">
                        <input
                            type="checkbox"
                            required
                            className="mr-1 border-none focus:outline-none cursor-pointer"
                        />
                        <p className="text-white text-[16px] md:text-[12px]">
                            Logout from all devices
                        </p>
                    </div>
                </div>
                <button
                    type="submit"
                    className="w-full py-2 mt-4 text-[18px] md:text-[14px] text-white bg-[#00FF0A] rounded-md focus:outline-none"
                >
                    {loading ?
                        <PulseLoader
                            color={"#fff"}
                            loading={loading}
                            size={10}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                        : "Reset & Login"}

                </button>
            </form>

            <div className="absolute bottom-[10rem] w-full px-14">
                <div className="flex items-center justify-evenly">
                    <div className="w-8 bg-white h-[3px]" />
                    <div className="w-8 bg-white h-[3px]" />
                    <div className="w-8 bg-black h-[3px]" />
                </div>
            </div>
        </div>
    );
};

export default ChangePass;
