import React, { Fragment, useCallback, useEffect } from "react";
import { copyright } from "../utils/copyright";
import { DEFAULT_ID } from "../utils/mainUrl";
import { likedVideoData } from "../data";
import { CiSearch } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { fetchLikedVideos } from "../redux/apislice/LikedVideosSlice";
import { useDispatch } from "react-redux";

const LikedVideoPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // This function will be used to get the liked videos
    const getLikedVideos = useCallback(async () => {
        await dispatch(fetchLikedVideos());
    }, [dispatch])

    useEffect(() => {
        getLikedVideos();
    }, [getLikedVideos])

    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center">
                {/* <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / Liked Video / V1 / 14 Aug, 2024
                </p> */}
                <p className="text-black text-[9px] py-[2px]">{copyright}</p>
            </div>

            <div className="sticky top-0 grid grid-cols-12 items-center px-2 py-[6px] z-10 bg-[#1e1e1e]">
                <div className="col-span-6 flex items-center justify-start gap-3">
                    <IoMdArrowBack
                        onClick={() => navigate(-1)}
                        className="text-white h-5 w-5 cursor-pointer ml-[-3px]"
                    />
                    <p className="text-white text-[16px] font-medium">Liked Video</p>
                </div>
                <div className="col-span-6 flex items-center justify-end gap-3 ">
                    <CiSearch
                        onClick={() => navigate("/search")}
                        className="text-white h-5 w-5 cursor-pointer mr-[-2px]"
                    />
                </div>
            </div>

            {/* Liked video data will be displayed here */}
            {likedVideoData.map((data) => (
                <div className="grid grid-cols-12 px-2 my-4 gap-2" key={data.id}>
                    <div className="col-span-5">
                        <img
                            alt="liked video"
                            src={data.thumbnail}
                            onClick={() => navigate(`/video/${DEFAULT_ID}`)}
                            className="w-full h-[4.2rem] object-cover rounded cursor-pointer"
                        />
                    </div>
                    <div className="col-span-7">
                        <p className="text-white text-[12px] leading-[14px] cursor-pointer line-clamp-2">
                            {data.title}
                        </p>
                        <p className="text-[#808080] text-[12px] cursor-pointer">{data.channel}</p>
                        <div className="flex items-center gap-2">
                            <p className="text-[#808080] text-[10px]">{data.views} views</p>
                            <p className="text-[#808080] text-[10px]">•</p>
                            <p className="text-[#808080] text-[10px]">{data.uploaded}</p>
                        </div>
                    </div>
                </div>
            ))}
        </Fragment>
    )
}

export default LikedVideoPage