import React, { Fragment, useState } from "react";
import { copyright } from "../utils/copyright";

import { LiveNewsVideoData } from "../data";

import { useSwipeable } from "react-swipeable";
import { useSpring, animated } from "@react-spring/web";

import Share from "../components/livenews/Share";
import Report from "../components/livenews/Report";
import Comment from "../components/livenews/Comment";
import Controls from "../components/livenews/Controls";
import Subscribe from "../components/livenews/Subscribe";
import VideoDetails from "../components/livenews/VideoDetails";

const ViewLiveNews = () => {
   const [currentIndex, setCurrentIndex] = useState(0); // Track current video index
   const [swipeDirection, setSwipeDirection] = useState(""); // Track swipe direction
   const [isTransitioning, setIsTransitioning] = useState(false); // Track transition state

   const [isSubscribed, setIsSubscribed] = useState(false);
   const [isReported, setIsReported] = useState(false);
   const [selectedReports, setSelectedReports] = useState([]); // Track selected report options

   // visibility of components on the screen
   const [commentVisible, setCommentVisible] = useState(false);
   const [subscriberVisible, setSubscriberVisible] = useState(true);
   const [controlVisible, setControlVisible] = useState(true);
   const [videoDetailsVisible, setVideoDetailsVisible] = useState(false);
   const [shareVisible, setShareVisible] = useState(false);
   const [reportVisible, setReportVisible] = useState(false);

   const handlers = useSwipeable({
      onSwipedUp: () => {
         if (currentIndex < LiveNewsVideoData.length - 1) {
            setSwipeDirection("up");
            setIsTransitioning(true);
            setCurrentIndex((prevIndex) => prevIndex + 1);
         }
      },
      onSwipedDown: () => {
         if (currentIndex > 0) {
            setSwipeDirection("down");
            setIsTransitioning(true);
            setCurrentIndex((prevIndex) => prevIndex - 1);
         }
      },
      preventScrollOnSwipe: true,
      trackTouch: true,
      trackMouse: true,
   });

   const springProps = useSpring({
      transform: swipeDirection === "up"
         ? "translateY(-100%)"
         : swipeDirection === "down"
            ? "translateY(100%)"
            : "translateY(0%)",
      opacity: isTransitioning ? 0 : 1,
      from: { transform: "translateY(0%)", opacity: 1 },
      reset: true,
      config: { tension: 170, friction: 26 },
      onRest: () => {
         setSwipeDirection("");
         setIsTransitioning(false); // End transition after animation
      },
   });

   const handleComment = () => {
      setControlVisible(!controlVisible);
      setCommentVisible(!commentVisible);
      setSubscriberVisible(!subscriberVisible);
   }

   const handleVideoDetails = () => {
      setCommentVisible(false);
      setControlVisible(!controlVisible);
      setSubscriberVisible(!subscriberVisible);
      setVideoDetailsVisible(!videoDetailsVisible);
   }

   const handleShare = () => {
      setCommentVisible(false);
      setShareVisible(!shareVisible);
      setControlVisible(!controlVisible);
      setSubscriberVisible(!subscriberVisible);
   }

   const handleReport = () => {
      setIsReported(false);
      setSelectedReports([]);
      setCommentVisible(false);
      setReportVisible(!reportVisible);
      setControlVisible(!controlVisible);
      setSubscriberVisible(!subscriberVisible);
   }

   return (
      <Fragment>
         <div className="bg-[#d9d9d9] text-center sticky top-0 z-50">
            {/* <p className="text-red-600 text-[9px] uppercase font-bold">
               KV / FE / news / V2 / 25 SEP, 2024
            </p> */}
            <p className="text-black text-[9px] py-[2px]">{copyright}</p>
         </div>

         <div {...handlers} className="p-0 m-0 h-[100vh] w-full overflow-hidden">
            <animated.div
               style={{ ...springProps, position: 'absolute', width: '100%', height: '100%' }}
               className="w-full h-full"
            >
               <video
                  key={LiveNewsVideoData[currentIndex].id}
                  loop
                  autoPlay
                  preload="auto"
                  src={LiveNewsVideoData[currentIndex].url}
                  disablePictureInPicture
                  className="w-full h-full object-cover"
               />

               {/* Controls */}
               {controlVisible &&
                  <Controls
                     handleComment={handleComment}
                     handleShare={handleShare}
                     handleReport={handleReport}
                  />
               }

               {/* Subscribe */}
               {subscriberVisible &&
                  <Subscribe
                     currentIndex={currentIndex}
                     videoData={LiveNewsVideoData}
                     isSubscribed={isSubscribed}
                     setIsSubscribed={setIsSubscribed}
                     handleVideoDetails={handleVideoDetails}
                  />
               }

               {/* Comments */}
               {commentVisible &&
                  <Comment
                     handleComment={handleComment}
                  />
               }

               {/* Video Details */}
               {videoDetailsVisible &&
                  <VideoDetails
                     videoData={LiveNewsVideoData}
                     currentIndex={currentIndex}
                     isSubscribed={isSubscribed}
                     setIsSubscribed={setIsSubscribed}
                     handleVideoDetails={handleVideoDetails}
                  />
               }

               {/* Share */}
               {shareVisible &&
                  <Share
                     handleShare={handleShare}
                  />
               }

               {/* Report */}
               {reportVisible &&
                  <Report
                     selectedReports={selectedReports}
                     setSelectedReports={setSelectedReports}
                     setIsReported={setIsReported}
                     handleReport={handleReport}
                     isReported={isReported}
                  />
               }
            </animated.div>
         </div>
      </Fragment>
   )
}

export default ViewLiveNews