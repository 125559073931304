import React, { Fragment, useCallback, useEffect } from "react";
import { copyright } from "../../utils/copyright";
import { CiSearch } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { Button, Switch } from "@material-tailwind/react";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { getBlockedChannels } from "../../redux/apislice/blockSlice";
import Skeleton from "react-loading-skeleton";

const SettingsMain = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const rememberMe = Cookies.get("rememberMe") === "true" ? true : false;
    const blockedChannelsList = useSelector((state) => state.blocked)
    const { data, loading } = blockedChannelsList
    console.log(blockedChannelsList, "blockedChannelsList")

    const logout = () => {
        if (rememberMe) {
            localStorage.removeItem("securityAccessToken");
            Cookies.remove("accessToken");
            navigate("/");
        } else {
            Cookies.remove();
            localStorage.removeItem("securityAccessToken");
            navigate("/");
        }
    }
    const fetchBlockedChannels = useCallback(async () => {
        await dispatch(getBlockedChannels());
    }, [dispatch]);

    useEffect(() => {
        fetchBlockedChannels();
    }, [fetchBlockedChannels])
    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center">
                {/* <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / Settings / V1 / 16 Aug, 2024
                </p> */}
                <p className="text-black text-[9px] py-[2px]">{copyright}</p>
            </div>

            <div className="sticky top-0 grid grid-cols-12 items-center px-2 py-[6px] z-10 bg-[#1e1e1e]">
                <div className="col-span-6 flex items-center justify-start gap-3">
                    <IoMdArrowBack
                        onClick={() => navigate(-1)}
                        className="text-white h-5 w-5 cursor-pointer ml-[-3px]"
                    />
                    <p className="text-white text-[16px] font-medium">Settings</p>
                </div>
                <div className="col-span-6 flex items-center justify-end gap-3 ">
                    <CiSearch
                        onClick={() => navigate("/search")}
                        className="text-white h-5 w-5 cursor-pointer mr-[-2px]"
                    />
                </div>
            </div>

            <div className="px-2 pt-2">
                {/* general */}
                <p className="text-[16px] text-[#ffffff] font-semibold">General</p>
                <div className="grid grid-cols-12 items-center mt-[2px]">
                    <div className="col-span-6">
                        <p className="text-[13px] text-[#8a8a8a] font-medium">Dark Mode</p>
                    </div>
                    <div className="col-span-6 flex items-center justify-end overflow-hidden">
                        <Switch
                            ripple={true}
                            className="h-4 w-[28.5px] checked:bg-[#0DD315]"
                            containerProps={{
                                className: "w-8 h-4",
                            }}
                            circleProps={{
                                className: "before:hidden left-0.5 border-none w-3 h-3",
                            }}
                        />
                    </div>
                </div>

                <div className="grid grid-cols-12 items-center mt-2">
                    <div className="col-span-6">
                        <p className="text-[13px] text-[#8a8a8a] font-medium">All notification</p>
                    </div>
                    <div className="col-span-6 flex items-center justify-end overflow-hidden">
                        <Switch
                            ripple={true}
                            className="h-4 w-[28.5px] checked:bg-[#0DD315]"
                            containerProps={{
                                className: "w-8 h-4",
                            }}
                            circleProps={{
                                className: "before:hidden left-0.5 border-none w-3 h-3",
                            }}
                        />
                    </div>
                </div>

                {/* data saving */}
                <p className="text-[16px] text-[#ffffff] font-semibold mt-4">Data Saving</p>
                <div className="grid grid-cols-12 items-center mt-[2px]">
                    <div className="col-span-6">
                        <p className="text-[13px] text-[#8a8a8a] font-medium">Data saving mode</p>
                    </div>
                    <div className="col-span-6 flex items-center justify-end overflow-hidden">
                        <Switch
                            ripple={true}
                            className="h-4 w-[28.5px] checked:bg-[#0DD315]"
                            containerProps={{
                                className: "w-8 h-4",
                            }}
                            circleProps={{
                                className: "before:hidden left-0.5 border-none w-3 h-3",
                            }}
                        />
                    </div>
                </div>

                <div className="grid grid-cols-12 items-center mt-2">
                    <div className="col-span-6">
                        <p className="text-[13px] text-[#8a8a8a] font-medium">Reduce video quaity</p>
                    </div>
                    <div className="col-span-6 flex items-center justify-end overflow-hidden">
                        <Switch
                            ripple={true}
                            className="h-4 w-[28.5px] checked:bg-[#0DD315]"
                            containerProps={{
                                className: "w-8 h-4",
                            }}
                            circleProps={{
                                className: "before:hidden left-0.5 border-none w-3 h-3",
                            }}
                        />
                    </div>
                </div>

                <div className="grid grid-cols-12 items-center mt-2">
                    <div className="col-span-6">
                        <p className="text-[13px] text-[#8a8a8a] font-medium">Reduce download quaity</p>
                    </div>
                    <div className="col-span-6 flex items-center justify-end overflow-hidden">
                        <Switch
                            ripple={true}
                            className="h-4 w-[28.5px] checked:bg-[#0DD315]"
                            containerProps={{
                                className: "w-8 h-4",
                            }}
                            circleProps={{
                                className: "before:hidden left-0.5 border-none w-3 h-3",
                            }}
                        />
                    </div>
                </div>

                {/* downloads */}
                <p className="text-[16px] text-[#ffffff] font-semibold mt-4">Downloads</p>
                <div className="grid grid-cols-12 items-center mt-[2px]">
                    <div className="col-span-6">
                        <p className="text-[13px] text-[#8a8a8a] font-medium">Download over Wi-Fi only</p>
                    </div>
                    <div className="col-span-6 flex items-center justify-end overflow-hidden">
                        <Switch
                            ripple={true}
                            className="h-4 w-[28.5px] checked:bg-[#0DD315]"
                            containerProps={{
                                className: "w-8 h-4",
                            }}
                            circleProps={{
                                className: "before:hidden left-0.5 border-none w-3 h-3",
                            }}
                        />
                    </div>
                </div>

                <div className="grid grid-cols-12 items-center mt-2">
                    <div className="col-span-8">
                        <p className="text-[13px] text-[#8a8a8a] font-medium">Download over mobile network</p>
                    </div>
                    <div className="col-span-4 flex items-center justify-end overflow-hidden">
                        <Switch
                            ripple={true}
                            className="h-4 w-[28.5px] checked:bg-[#0DD315]"
                            containerProps={{
                                className: "w-8 h-4",
                            }}
                            circleProps={{
                                className: "before:hidden left-0.5 border-none w-3 h-3",
                            }}
                        />
                    </div>
                </div>

                <div className="grid grid-cols-12 items-center mt-2">
                    <div className="col-span-12">
                        <p className="text-[13px] text-[#8a8a8a] font-medium">Download quality</p>
                    </div>
                    <div className="col-span-12 flex items-center gap-3 mt-1 overflow-hidden">
                        <div className="flex items-center gap-1">
                            <input type="radio" className="h-4 w-5 cursor-pointer" />
                            <p className="text-[#8a8a8a] text-[13px] mt-[2px]">360p</p>
                        </div>
                        <div className="flex items-center gap-1">
                            <input type="radio" className="h-4 w-5 cursor-pointer" />
                            <p className="text-[#8a8a8a] text-[13px] mt-[2px]">480p</p>
                        </div>
                        <div className="flex items-center gap-1">
                            <input type="radio" className="h-4 w-5 cursor-pointer" />
                            <p className="text-[#8a8a8a] text-[13px] mt-[2px]">720p</p>
                        </div>
                        <div className="flex items-center gap-1">
                            <input type="radio" className="h-4 w-5 cursor-pointer" />
                            <p className="text-[#8a8a8a] text-[13px] mt-[2px]">1080p</p>
                        </div>
                    </div>
                </div>

                {/* uploads */}
                <p className="text-[16px] text-[#ffffff] font-semibold mt-4">Uploads</p>
                <div className="grid grid-cols-12 items-center mt-[2px]">
                    <div className="col-span-6">
                        <p className="text-[13px] text-[#8a8a8a] font-medium">Upload over Wi-Fi only</p>
                    </div>
                    <div className="col-span-6 flex items-center justify-end overflow-hidden">
                        <Switch
                            ripple={true}
                            className="h-4 w-[28.5px] checked:bg-[#0DD315]"
                            containerProps={{
                                className: "w-8 h-4",
                            }}
                            circleProps={{
                                className: "before:hidden left-0.5 border-none w-3 h-3",
                            }}
                        />
                    </div>
                </div>

                <div className="grid grid-cols-12 items-center mt-2">
                    <div className="col-span-6">
                        <p className="text-[13px] text-[#8a8a8a] font-medium">Always upload original</p>
                    </div>
                    <div className="col-span-6 flex items-center justify-end overflow-hidden">
                        <Switch
                            ripple={true}
                            className="h-4 w-[28.5px] checked:bg-[#0DD315]"
                            containerProps={{
                                className: "w-8 h-4",
                            }}
                            circleProps={{
                                className: "before:hidden left-0.5 border-none w-3 h-3",
                            }}
                        />
                    </div>
                </div>

                <div className="grid grid-cols-12 items-center mt-2">
                    <div className="col-span-12">
                        <p className="text-[13px] text-[#8a8a8a] font-medium">Upload quality</p>
                    </div>
                    <div className="col-span-12 flex items-center gap-3 mt-1 overflow-hidden">
                        <div className="flex items-center gap-1">
                            <input type="radio" className="h-4 w-5 cursor-pointer" />
                            <p className="text-[#8a8a8a] text-[13px] mt-[2px]">360p</p>
                        </div>
                        <div className="flex items-center gap-1">
                            <input type="radio" className="h-4 w-5 cursor-pointer" />
                            <p className="text-[#8a8a8a] text-[13px] mt-[2px]">480p</p>
                        </div>
                        <div className="flex items-center gap-1">
                            <input type="radio" className="h-4 w-5 cursor-pointer" />
                            <p className="text-[#8a8a8a] text-[13px] mt-[2px]">720p</p>
                        </div>
                        <div className="flex items-center gap-1">
                            <input type="radio" className="h-4 w-5 cursor-pointer" />
                            <p className="text-[#8a8a8a] text-[13px] mt-[2px]">1080p</p>
                        </div>
                    </div>
                </div>

                {/* playback */}
                <p className="text-[16px] text-[#ffffff] font-semibold mt-4">Playback</p>
                <div className="grid grid-cols-12 items-center mt-[2px]">
                    <div className="col-span-6">
                        <p className="text-[13px] text-[#8a8a8a] font-medium">Auto-play</p>
                    </div>
                    <div className="col-span-6 flex items-center justify-end overflow-hidden">
                        <Switch
                            ripple={true}
                            className="h-4 w-[28.5px] checked:bg-[#0DD315]"
                            containerProps={{
                                className: "w-8 h-4",
                            }}
                            circleProps={{
                                className: "before:hidden left-0.5 border-none w-3 h-3",
                            }}
                        />
                    </div>
                </div>

                <div className="grid grid-cols-12 items-center mt-2">
                    <div className="col-span-12">
                        <p className="text-[13px] text-[#8a8a8a] font-medium">Auto-play time delay</p>
                    </div>
                    <div className="col-span-12 flex items-center gap-3 mt-1 mb-3 overflow-hidden">
                        <div className="flex items-center gap-1">
                            <input type="radio" className="h-4 w-5 cursor-pointer" />
                            <p className="text-[#8a8a8a] text-[13px] mt-[2px]">5sec</p>
                        </div>
                        <div className="flex items-center gap-1">
                            <input type="radio" className="h-4 w-5 cursor-pointer" />
                            <p className="text-[#8a8a8a] text-[13px] mt-[2px]">10sec</p>
                        </div>
                        <div className="flex items-center gap-1">
                            <input type="radio" className="h-4 w-5 cursor-pointer" />
                            <p className="text-[#8a8a8a] text-[13px] mt-[2px]">15sec</p>
                        </div>
                        <div className="flex items-center gap-1">
                            <input type="radio" className="h-4 w-5 cursor-pointer" />
                            <p className="text-[#8a8a8a] text-[13px] mt-[2px]">20sec</p>
                        </div>
                    </div>
                </div>
                {data?.length > 0 && <div className="grid grid-cols-12 items-center mt-2">
                    <div className="col-span-12 flex justify-between items-center">
                        <p className="text-[16px] text-[#ffffff] font-semibold">Blocked Channels</p>
                        {data?.length > 4 && <p className="text-blue-500 text-[12px] font-semibold cursor-pointer">View More</p>}
                    </div>
                    <div className="col-span-12 grid grid-cols-12 items-center gap-3 mt-1 mb-3 overflow-hidden justify-between">
                        {!loading && data?.map((item, index) => (
                            <>
                                {index <= 3 && <img
                                    onClick={() => navigate("/blockedchannels")}
                                    alt={item.full_name}
                                    src={item.profile_picture}
                                    className={`w-[5rem] h-[3rem] col-span-3 object-cover rounded-[4px] cursor-pointer hover:border-[2px] hover:border-light-blue-500 border-transparent`}
                                />}
                            </>
                        ))}

                        {loading && [1, 2, 3, 4].map((_, index) => (
                            <div key={index} className="col-span-3">
                                {/* <div className="bg-[#d9d9d9] h-[3rem] w-[5rem] rounded-[4px] animate-pulse"></div> */}
                                <Skeleton baseColor="#202020" highlightColor="#444" style={{ height: "3rem", width: "5rem" }} />
                            </div>
                        ))}
                    </div>
                </div>}
            </div>

            <div className="px-2 pt-2 mb-4">
                <div className="flex items-center justify-between">
                    <Button className="w-full" onClick={() => logout()}>
                        logout
                    </Button>
                </div>
            </div>
        </Fragment>
    )
}

export default SettingsMain