import React, { Fragment, useEffect, useState } from "react";
import { copyright } from "../utils/copyright";
import { useNavigate } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { CiSearch } from "react-icons/ci";
import { MdDownloadDone } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { deleteDownloadVideo, getDownloadList } from "../redux/apislice/downloadSlice";
import { FaRegTrashAlt } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";

const DownloadedPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const downloaded = useSelector((state) => state.downloadList);
    console.log(downloaded, "downloaded")
    const { isLoading, downloadList } = downloaded

    const [downloadVideos, setDownloadVideos] = useState([])


    const timeAgo = timestamp => {
        const diffInSeconds = Math.floor((new Date() - new Date(timestamp)) / 1000);
        const intervals = [
            { label: "year", seconds: 31536000 },
            { label: "month", seconds: 2592000 },
            { label: "week", seconds: 604800 },
            { label: "day", seconds: 86400 },
            { label: "hour", seconds: 3600 },
            { label: "minute", seconds: 60 },
            { label: "second", seconds: 1 }
        ];
        const { label, seconds } = intervals.find(i => diffInSeconds >= i.seconds) || {};
        const timeDifference = label ? Math.floor(diffInSeconds / seconds) : 0;
        return label ? `${timeDifference} ${label}${timeDifference > 1 ? 's' : ''} ago` : 'just now';
    };

    const handleDelete = async (video_Id) => {
        dispatch(deleteDownloadVideo(video_Id)).then((res) => {
            if (res.type === "deleteDownloadVideo/fulfilled") {
                setDownloadVideos((prev) => prev.filter((data) => data.video.id !== video_Id))
            }
        })
    }

    useEffect(() => {
        dispatch(getDownloadList())
    }, [dispatch])

    useEffect(() => {
        setDownloadVideos(downloadList?.results)
    }, [downloadList?.results])

    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center">
                {/* <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / Downloads / V1 / 14 Aug, 2024
                </p> */}
                <p className="text-black text-[9px] py-[2px]">{copyright}</p>
            </div>

            <div className="sticky top-0 grid grid-cols-12 items-center px-2 py-[6px] z-10 bg-[#1e1e1e]">
                <div className="col-span-6 flex items-center justify-start gap-3">
                    <IoMdArrowBack
                        onClick={() => navigate(-1)}
                        className="text-white h-5 w-5 cursor-pointer ml-[-3px]"
                    />
                    <p className="text-white text-[16px] font-medium">Downloads</p>
                </div>
                <div className="col-span-6 flex items-center justify-end gap-3 ">
                    <CiSearch
                        onClick={() => navigate("/search")}
                        className="text-white h-5 w-5 cursor-pointer mr-[-2px]"
                    />
                </div>
            </div>

            {/* Liked video data will be displayed here */}
            {isLoading ? (
                ([1, 2, 3, 4, 5, 6].map(() => (
                    <div className="grid grid-cols-12 px-2 gap-2" >
                        <div className="col-span-5">
                            <Skeleton height={68} width={120} baseColor="#202020" highlightColor="#444" />
                        </div>
                        <div className="col-span-7 py-1 ml-1">
                            <p className="text-white text-[10px] leading-[14px] cursor-pointer line-clamp-2">
                                <Skeleton baseColor="#202020" highlightColor="#444" height={10} width={150} />
                            </p>
                            <p className="text-[#808080] text-[10px] cursor-pointer"> <Skeleton baseColor="#202020" highlightColor="#444" height={10} width={100} /></p>
                            <div className="flex items-center justify-between">
                                <div className="flex flex-col">
                                    <p className="text-[#808080] text-[10px]">
                                        <Skeleton baseColor="#202020" highlightColor="#444" height={10} width={60} /></p>
                                    <div className='grid grid-cols-12 gap-2 justify-between items-center'>
                                        <p className='col-span-4'>
                                            <Skeleton baseColor="#202020" highlightColor="#444" height={10} width={50} />
                                        </p>
                                        <p className='col-span-4'>
                                            <Skeleton baseColor="#202020" highlightColor="#444" height={10} width={50} />
                                        </p>
                                        <p className='col-span-2'></p>
                                        <p className='col-span-2 flex justify-end items-center'>
                                            <Skeleton baseColor="#202020" highlightColor="#444" height={10} width={20} />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )))
            ) : (downloadVideos?.length > 0 ? (downloadVideos?.map((data) => {
                const { downloaded_at, id, video } = data
                return (<div className="grid grid-cols-12 px-2 my-4 gap-2" key={id} >
                    <div className="col-span-5">
                        <img
                            alt="liked video"
                            src={video.thumbnail}
                            onClick={() => navigate(`/video/${video.id}`)}
                            className="w-full h-[4.35rem] object-cover rounded cursor-pointer"
                        />
                    </div>
                    <div className="col-span-7">
                        <p className="text-white text-[10px] leading-[14px] cursor-pointer line-clamp-2">
                            {video.title}
                        </p>
                        <p className="text-[#808080] text-[10px] cursor-pointer">{video.author.handle}</p>
                        <div className="flex items-center gap-1">
                            <MdDownloadDone className="h-3 w-3 text-[#808080]" />
                            <p className="text-[#808080] text-[10px]">downloaded</p>
                        </div>
                        <div className="flex justify-between">
                            <div className="flex items-center gap-2">
                                <p className="text-[#808080] text-[10px]">{video.views_count} views</p>
                                <p className="text-[#808080] text-[10px]">•</p>
                                <p className="text-[#808080] text-[10px]">{timeAgo(downloaded_at)}</p>
                            </div>
                            <FaRegTrashAlt className="text-[#808080] text-[10px] cursor-pointer hover:text-[#f04f4f]" onClick={() => handleDelete(video.id)} />
                        </div>
                    </div>
                </div>)
            })) :
                <div className="flex justify-center items-center h-96">
                    <p className="text-white text-[14px]">
                        No Downloads
                    </p>
                </div>
            )}
        </Fragment >
    )
}

export default DownloadedPage