import React, { Fragment, useState, useEffect } from "react";
import MusicCard from "./MusicCard";
import { useNavigate } from "react-router-dom";

import { CiSearch } from "react-icons/ci";
import { IoMdArrowBack } from "react-icons/io";

import {
    userUploadMusic,
    savedMusicList,
    savedMusicListForUser,
    saveUnsaveMusicById,
    deleteMusicById
} from "../../redux/apislice/music/musicSlice";
import { copyright } from "../../utils/copyright";
import { useDispatch, useSelector } from "react-redux";

import BeatLoader from "react-spinners/BeatLoader";

const MusicList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showMusicList, setShowMusicList] = useState("uploaded");

    const musicData = useSelector((state) => state.musicList.data) || [];
    const loading = useSelector((state) => state.musicList.loading);
    const saveList = useSelector((state) => state.musicList.savedMusicList);

    useEffect(() => {
        dispatch(savedMusicListForUser());
    }, [dispatch]);

    useEffect(() => {
        if (showMusicList === "uploaded") {
            dispatch(userUploadMusic());
        }
    }, [dispatch, showMusicList]);

    const handleShowUploadedMusic = () => {
        setShowMusicList("uploaded");
        dispatch(userUploadMusic());
    };

    const handleShowSavedMusic = () => {
        setShowMusicList("saved");
        dispatch(savedMusicList());
    };

    const handleSaveUnsave = async (id) => {
        try {
            await dispatch(saveUnsaveMusicById(id)).unwrap();
            dispatch(savedMusicListForUser()); // Update the saved music list
        } catch (error) {
            console.error("Failed to save/unsave music:", error);
        }
    };

    const handleDelete = async (id) => {
        try {
            await dispatch(deleteMusicById(id)).unwrap();
            dispatch(userUploadMusic()); // Refresh the uploaded music list
        } catch (error) {
            console.error("Failed to delete music:", error);
        }
    };

    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center">
                {/* <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / Music List / V1 / 17 OCT, 2024
                </p> */}
                <p className="text-black text-[9px] py-[2px]">{copyright}</p>
            </div>

            <div className="sticky top-0 bg-black">
                <div className="grid grid-cols-12 items-center px-2 py-[6px] z-10 bg-[#1e1e1e]">
                    <div className="col-span-6 flex items-center justify-start gap-3">
                        <IoMdArrowBack
                            onClick={() => navigate(-1)}
                            className="text-white h-5 w-5 cursor-pointer ml-[-3px]"
                        />
                        <p className="text-white text-[16px] font-medium">
                            Music List
                        </p>
                    </div>
                    <div className="col-span-6 flex items-center justify-end gap-3 ">
                        <CiSearch
                            onClick={() => navigate("/search")}
                            className="text-white h-5 w-5 cursor-pointer mr-[-2px]"
                        />
                    </div>
                </div>

                <div className="flex items-center gap-x-2 p-2">
                    <button
                        onClick={handleShowUploadedMusic}
                        className={`${showMusicList === "uploaded" ? "bg-[#ffffff] text-black" : "bg-[#1e1e1e] text-white"} 
                        px-3 py-1 rounded w-full text-[12px] focus:outline-none`
                        }
                    >
                        Uploaded Music
                    </button>
                    <button
                        onClick={handleShowSavedMusic}
                        className={`${showMusicList === "saved" ? "bg-[#ffffff] text-black" : "bg-[#1e1e1e] text-white"} 
                        px-3 py-1 rounded w-full text-[12px] focus:outline-none`
                        }
                    >
                        Saved Music
                    </button>
                </div>
            </div>

            <div className="px-2">
                {loading ? (
                    <div className="flex items-center justify-center">
                        <BeatLoader
                            color={"#fff"}
                            loading={true}
                            size={15}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                ) : (
                    <>
                        {showMusicList === "uploaded" &&
                            <MusicCard
                                showSaved={false}
                                showDelete={true}
                                musicData={musicData}
                                handleSaveUnsave={handleSaveUnsave}
                                handleDelete={handleDelete}
                            />
                        }
                        {showMusicList === "saved" &&
                            <MusicCard
                                showSaved={true}
                                showDelete={false}
                                musicData={saveList}
                                handleSaveUnsave={handleSaveUnsave}
                            />
                        }
                    </>
                )}
            </div>
        </Fragment>
    )
}

export default MusicList;