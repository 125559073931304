import React, { useState } from "react";
import { copyright } from "../../utils/copyright";
import { useNavigate } from "react-router-dom";
import kv_logo from "../../assets/kv_logo.png";
// import Likes from "./Likes";
import Default from "./Default";
// import Comments from "./Comments";
// import Mentions from "./Mentions";
// import Messages from "./Messages";
// import Analytics from "./Analytics";
import Subscribers from "./Subscribers";

const Notification = () => {
    const lable = "KV / FE / Notification / V1 / 10 Sep, 2024";

    const navigate = useNavigate();
    const [isChecked, setIsChecked] = useState(true);
    const [activeComponent, setActiveComponent] = useState("default");

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked)
    }

    return (
        <div className="h-full">
            <div className="bg-[#d9d9d9] text-center">
                {/* <p className="text-red-600 text-[9px] uppercase font-bold">{lable}</p> */}
                <p className="text-black text-[9px] py-[2px]">{copyright}</p>
            </div>
            <div className="sticky top-0">
                <div className="grid grid-cols-12 items-center px-2 bg-white">
                    <div className="col-span-2 flex items-center justify-start">
                        <img
                            src={kv_logo}
                            alt="logo"
                            onClick={() => navigate("/home")}
                            className="object-cover cursor-pointer w-[2rem] my-1"
                        />
                    </div>
                    <div className="col-span-8 flex items-center justify-center">
                        <p className="text-black text-[16px] font-medium" onClick={() => setActiveComponent("default")}>Notifications</p>
                    </div>
                    <div className="col-span-2 flex items-center justify-end">
                        <label className="flex cursor-pointer select-none items-center ml-3">
                            <div className="relative">
                                <input
                                    type="checkbox"
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}
                                    className="sr-only"
                                />
                                <div className={`block h-5 w-9 rounded-full ${isChecked ? "bg-green-600" : "bg-black"}`}>

                                </div>
                                <div className={`absolute left-[2px] top-[2px] flex h-4 w-4 items-center justify-center text-[7px] rounded-full bg-white transition ${isChecked ? "translate-x-full" : ""}`}>
                                    {isChecked ? "on" : "off"}
                                </div>
                            </div>
                        </label>
                    </div>
                </div>

                <div className="flex items-center justify-start gap-x-2 p-2">
                    <div
                        onClick={() => setActiveComponent("default")}
                        className={`flex items-center justify-center px-[6px] py-1 rounded-md cursor-pointer 
                                ${activeComponent === "default" ? "bg-[#29e52f] text-white" : "bg-white text-black"}
                            `}
                    >
                        <p className="text-[14px] md:text-[10px] font-medium">All</p>
                    </div>
                    <div
                        onClick={() => setActiveComponent("subscribers")}
                        className={`flex items-center justify-center px-[6px] py-1 rounded-md cursor-pointer 
                                ${activeComponent === "subscribers" ? "bg-[#29e52f] text-white" : "bg-white text-black"}
                            `}
                    >
                        <p className="text-[14px] md:text-[10px] font-medium">Subscribers</p>
                    </div>
                </div>
            </div>

            <div className="px-2 pt-1 overflow-y-scroll">
                {activeComponent === "default" && <Default />}
                {activeComponent === "subscribers" && <Subscribers />}
                {/* {activeComponent === "likes" && <Likes />} */}
                {/*{activeComponent === "comments" && <Comments />}
                {activeComponent === "mentions" && <Mentions />}
                {activeComponent === "analytics" && <Analytics />}
                {activeComponent === "messages" && <Messages />} */}
            </div>
        </div>
    )
}

export default Notification