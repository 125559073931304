import React, { Fragment, useState } from "react";
import { copyright } from "../../utils/copyright";
import { useNavigate } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { RiDownload2Fill } from "react-icons/ri";
import { IoMdDownload } from "react-icons/io";

const MediaPage = () => {
    const navigate = useNavigate();
    const [showContact, setShowContact] = useState(false);
    const [showDownload, setShowDownload] = useState(false);
    const [showGuidelines, setShowGuidelines] = useState(true);

    const handleContact = () => {
        setShowContact(!showContact)
        setShowDownload(false)
        setShowGuidelines(false)
    }

    const handleDownload = () => {
        setShowDownload(true)
        setShowContact(false)
        setShowGuidelines(false)
    }

    const handleGuidelines = () => {
        setShowGuidelines(true)
        setShowContact(false)
        setShowDownload(false)
    }

    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center">
                {/* <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / Media / V1 / 20 Aug, 2024
                </p> */}
                <p className="text-black text-[9px] py-[2px]">{copyright}</p>
            </div>

            <div className="sticky top-0 grid grid-cols-12 items-center px-2 py-[6px] z-10 bg-[#1e1e1e]">
                <div className="col-span-6 flex items-center justify-start gap-3">
                    <IoMdArrowBack
                        onClick={() => navigate(-1)}
                        className="text-white h-5 w-5 cursor-pointer ml-[-3px]"
                    />
                    <p className="text-white text-[16px] font-medium" onClick={handleGuidelines}>Media</p>
                </div>
                <div className="col-span-6 flex items-center justify-end">
                    <RiDownload2Fill className="text-white h-5 w-5 cursor-pointer" onClick={handleDownload} />
                </div>
            </div>

            {/* Guidelines */}
            {showGuidelines &&
                <div className="mt-2">
                    <div className="grid grid-cols-12 gap-2 bg-white p-2 my-2">
                        <div className="col-span-5">
                            <img
                                alt="media"
                                className="w-full h-[150px] object-cover"
                                src="https://cdn.pixabay.com/photo/2016/09/30/12/02/camera-1705135_1280.jpg"
                            />
                        </div>
                        <div className="col-span-7 flex items-center">
                            <p className="text-[11px] leading-4">
                                If you are a member of Media and would like to talk,
                                please submit the form in the KV gallery page. Only
                                media queries will receive a response, for everything
                                else, please <span className="text-cyan-400 underline underline-offset-2 cursor-pointer" onClick={handleContact}>Contact Support.</span>
                            </p>
                        </div>
                    </div>

                    <div className="grid grid-cols-12 gap-2 p-2 my-2">
                        <div className="col-span-7 flex flex-col items-start">
                            <p className="text-[14px] font-bold text-white">CEO talks about KV</p>
                            <p className="text-[11px] leading-4 mt-1 text-white">
                                Praesent imperdiet velit lobortis, cras tortor tristique,
                                aliquam dolor etiam pellentesque scelerisque eget. Inceptos
                                vestibulum, pharetra posuere nam elementum. Cursus potenti, curabitur elit luctus.
                            </p>
                        </div>
                        <div className="col-span-5">
                            <img
                                alt="media"
                                className="w-full h-[150px] object-cover"
                                src="https://images.unsplash.com/photo-1537511446984-935f663eb1f4?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                            />
                        </div>
                    </div>

                    <div className="px-2 flex flex-col items-center mt-6 justify-center text-white">
                        <h1 className="text-[3rem] font-extrabold">KV</h1>
                        <p className="text-[12px] mt-[-10px]">
                            Average daily uploads 30 million videos
                        </p>
                    </div>
                </div>
            }

            {/* Contact */}
            {showContact &&
                <form className="m-2">
                    <div className="mb-2">
                        <label className="text-white text-[14px] font-normal">Full Name</label>
                        <input
                            type="text"
                            placeholder="Enter your full name"
                            className="w-full px-2 py-2 text-white placeholder:text-[14px] text-[14px] rounded border-none bg-[#474747] focus:outline-none"
                        />
                    </div>
                    <div className="mb-2">
                        <label className="text-white text-[14px] font-normal">Email</label>
                        <input
                            type="email"
                            placeholder="Enter your email"
                            className="w-full px-2 py-2 text-white placeholder:text-[14px] text-[14px] rounded border-none bg-[#474747] focus:outline-none"
                        />
                    </div>
                    <div className="mb-2">
                        <label className="text-white text-[14px] font-normal">Country</label>
                        <input
                            type="text"
                            placeholder="Enter your country"
                            className="w-full px-2 py-2 text-white placeholder:text-[14px] text-[14px] rounded border-none bg-[#474747] focus:outline-none"
                        />
                    </div>
                    <div className="mb-2">
                        <label className="text-white text-[14px] font-normal">Contact Number (optional)</label>
                        <input
                            type="text"
                            placeholder="Enter your contact number"
                            className="w-full px-2 py-2 text-white placeholder:text-[14px] text-[14px] rounded border-none bg-[#474747] focus:outline-none"
                        />
                    </div>
                    <div className="mb-2">
                        <label className="text-white text-[14px] font-normal">Media</label>
                        <input
                            type="text"
                            placeholder="Enter your media house name"
                            className="w-full px-2 py-2 text-white placeholder:text-[14px] text-[14px] rounded border-none bg-[#474747] focus:outline-none"
                        />
                    </div>
                    <div className="mb-2">
                        <label className="text-white text-[14px] font-normal">Message</label>
                        <textarea
                            type="text"
                            placeholder="Enter your message"
                            className="w-full px-2 py-2 resize-none text-white placeholder:text-[14px] text-[14px] rounded border-none bg-[#474747] focus:outline-none"
                        />
                    </div>
                    <button className="bg-[#ffffff] rounded-full py-1 w-full">
                        Submit
                    </button>
                </form>
            }

            {/* Download */}
            {showDownload &&
                <div className="px-2">
                    <p className="text-[16px] font-bold text-center text-white mt-2">Download KV Logo</p>
                    <p className="text-[12px] text-white text-center my-4 leading-4">
                        Learn about our branding guidelines and how you can use our logos and icons
                        for your project. For detailed instructions, download our Guideline document.
                    </p>
                    <div className="grid grid-cols-2 gap-2 mt-3">
                        <div className="col-span-1 flex items-center justify-center">
                            <div className="bg-white px-8 py-8 relative rounded">
                                <p className=" text-blue-500 font-extrabold text-[2rem] mt-[-15px]">
                                    KV
                                </p>
                                <IoMdDownload
                                    className="text-[25px] absolute left-1/2 -translate-x-1/2 bg-[#6e6e6e] hover:bg-[#797979] text-white cursor-pointer p-[6px] rounded-full"
                                />
                            </div>
                        </div>
                        <div className="col-span-1 flex items-center justify-center">
                            <div className="bg-white px-8 py-8 relative rounded">
                                <p className=" text-blue-500 font-extrabold text-[2rem] mt-[-15px]">
                                    KV
                                </p>
                                <IoMdDownload
                                    className="text-[25px] absolute left-1/2 -translate-x-1/2 bg-[#6e6e6e] hover:bg-[#797979] text-white cursor-pointer p-[6px] rounded-full"
                                />
                            </div>
                        </div>
                        <div className="col-span-1 flex items-center mt-8 justify-center">
                            <div className="bg-white px-8 py-8 relative rounded">
                                <p className=" text-blue-500 font-extrabold text-[2rem] mt-[-15px]">
                                    KV
                                </p>
                                <IoMdDownload
                                    className="text-[25px] absolute left-1/2 -translate-x-1/2 bg-[#6e6e6e] hover:bg-[#797979] text-white cursor-pointer p-[6px] rounded-full"
                                />
                            </div>
                        </div>
                        <div className="col-span-1 flex items-center mt-8 justify-center">
                            <div className="bg-white px-8 py-8 relative rounded">
                                <p className=" text-blue-500 font-extrabold text-[2rem] mt-[-15px]">
                                    KV
                                </p>
                                <IoMdDownload
                                    className="text-[25px] absolute left-1/2 -translate-x-1/2 bg-[#6e6e6e] hover:bg-[#797979] text-white cursor-pointer p-[6px] rounded-full"
                                />
                            </div>
                        </div>
                    </div>
                    <p className="text-[12px] text-white text-center my-4 leading-4">
                        Our Media kit provides ready to use versions of our logo for digital media.
                        Please do not edit, change, distort, recolor, or reconfigure our logos.
                    </p>
                </div>
            }
        </Fragment>
    )
}

export default MediaPage