import React, { useEffect, useRef, useState } from "react";
import { copyright } from "../../utils/copyright";
import { BiText } from "react-icons/bi";
import { FaMusic } from "react-icons/fa";
import { VscMention } from "react-icons/vsc";
import { useNavigate } from "react-router-dom";
import { IoArrowBackCircle } from "react-icons/io5";
import { MdKeyboardVoice, MdMovieFilter, MdVideoCall } from "react-icons/md";

const UploadVideo = () => {
    const [videoFile, setVideoFile] = useState(null);
    // const [audioFile, setAudioFile] = useState(null); // State to store the selected audio file
    const [videoPreviewUrl, setVideoPreviewUrl] = useState(null);
    const [videoDuration, setVideoDuration] = useState("0:00");
    const [frames, setFrames] = useState([]);
    const [stepTwo, setStepTwo] = useState(false);

    const [showTextPopup, setShowTextPopup] = useState(false);
    const [showMentionPopup, setShowMentionPopup] = useState(false);
    const [showFilterPopup, setShowFilterPopup] = useState(false);

    const [selectedFilter, setSelectedFilter] = useState(null);

    const [searchQuery, setSearchQuery] = useState("");
    const [selectedChannels, setSelectedChannels] = useState(new Set());

    const channels = [
        { id: 1, name: 'NFL', image: 'https://yt3.ggpht.com/kDUg480xOHHjIEnvADRDAz8w9MapJEx-3UJjJQzfeg4O-SrAGHTOSpd5Tm5QtJi0RlSfafMxQg=s68-c-k-c0x00ffffff-no-rj' },
        { id: 2, name: 'ABC News', image: 'https://yt3.ggpht.com/OOT0eq8LoW8V3PMURGYuk0kRSgRGP7EsuGZoODhHBcg1jCB1qkuIAxS-XGnjKX9Q6rf-WKTvkQ=s68-c-k-c0x00ffffff-no-rj' },
        { id: 3, name: 'Eyewitness News ABC7NY', image: 'https://yt3.ggpht.com/ytc/AIdro_k1_mRYQG-HAspwbSjYcshWT6yECtfxdmfmCfIchUZsWHB9=s68-c-k-c0x00ffffff-no-rj' }
    ];

    const handleSearch = (query) => {
        setSearchQuery(query);
    };

    const handleChannelSelect = (channelName) => {
        setSelectedChannels(prev => {
            const newSelection = new Set(prev);
            if (newSelection.has(channelName)) {
                newSelection.delete(channelName);
            } else {
                newSelection.add(channelName);
            }
            return newSelection;
        });
    };

    const filteredChannels = channels.filter(channel =>
        channel.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setShowTextPopup(false);
                setShowMentionPopup(false);
                setShowFilterPopup(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const fileInputRef = useRef(null);
    const audioInputRef = useRef(null); // Ref for audio input
    const videoRef = useRef(null);
    const hasOpenedFileDialog = useRef(false);
    const navigate = useNavigate();
    const popupRef = useRef(null);

    useEffect(() => {
        if (!hasOpenedFileDialog.current) {
            fileInputRef.current.click();
            hasOpenedFileDialog.current = true;
        }
    }, []);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setVideoFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setVideoPreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleAudioFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // setAudioFile(file);
            // You can perform further actions with the audio file here
        }
    };

    const formatDuration = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = Math.floor(seconds % 60);
        return `${minutes}:${secs < 10 ? `0${secs}` : secs}`;
    };

    const handleLoadedMetadata = async () => {
        const videoElement = videoRef.current;
        const duration = Math.floor(videoElement.duration);
        setVideoDuration(formatDuration(duration));
        const framesArray = await captureFrames(videoElement, duration);
        setFrames(framesArray);
    };

    const captureFrames = (videoElement, duration) => {
        return new Promise((resolve) => {
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");
            const framesArray = [];

            canvas.width = videoElement.videoWidth;
            canvas.height = videoElement.videoHeight;

            const captureFrameAtSecond = (second) => {
                return new Promise((resolve) => {
                    videoElement.currentTime = second;
                    videoElement.onseeked = () => {
                        context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
                        const frameData = canvas.toDataURL("image/jpeg");
                        framesArray.push(frameData);
                        resolve();
                    };
                });
            };

            const captureFramesAsync = async () => {
                for (let i = 0; i < duration; i++) {
                    await captureFrameAtSecond(i);
                }
                resolve(framesArray);
            };

            captureFramesAsync();
        });
    };

    return (
        <div className="relative">
            <div className="bg-[#d9d9d9] text-center">
                {/* <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / Upload Video / V1 / 08 Aug, 2024
                </p> */}
                <p className="text-black text-[9px] py-[2px]">{copyright}</p>
            </div>
            <div className="grid grid-cols-12 items-center px-2 bg-white">
                <div className="col-span-2 flex items-center justify-start">
                    <h1
                        onClick={() => navigate("/home")}
                        className="text-[1.6rem] font-bold uppercase text-black cursor-pointer"
                    >
                        kv
                    </h1>
                </div>
                <div className="col-span-8 flex items-center justify-center">
                    <p className="text-black text-[16px] font-medium">Upload Video</p>
                </div>
            </div>
            <div className="px-2 pt-2 relative">
                {videoPreviewUrl &&
                    <IoArrowBackCircle
                        onClick={() => navigate(-1)}
                        className="text-white text-[20px] cursor-pointer ml-[-2px]"
                    />
                }
                {stepTwo &&
                    <div className="absolute right-2 top-3 z-10">
                        <div className="flex flex-col gap-2">
                            <div className="bg-white p-1 rounded-full text-[10px] cursor-pointer">
                                <BiText
                                    className="text-[15px] hover:text-green-500"
                                    onClick={() => setShowTextPopup(!showTextPopup)}
                                />
                            </div>
                            <div
                                className="bg-white p-1 rounded-full text-[10px] cursor-pointer"
                                onClick={() => audioInputRef.current.click()} // Trigger file input click
                            >
                                <MdKeyboardVoice className="text-[15px] hover:text-green-500" />
                            </div>
                            <div className="bg-white p-1 rounded-full text-[10px] cursor-pointer">
                                <MdVideoCall className="text-[15px] hover:text-green-500" />
                            </div>
                            <div
                                className="bg-white p-1 rounded-full text-[10px] cursor-pointer"
                                onClick={() => setShowMentionPopup(!showMentionPopup)}
                            >
                                <VscMention className="text-[15px] hover:text-green-500" />
                            </div>
                            <div
                                onClick={() => setShowFilterPopup(!showFilterPopup)}
                                className="bg-white p-1 rounded-full text-[10px] cursor-pointer"
                            >
                                <MdMovieFilter className="text-[15px] hover:text-green-500" />
                            </div>
                        </div>
                    </div>
                }
            </div>

            <div className="flex flex-col items-center mt-2 px-12 relative">
                <input
                    type="file"
                    accept="video/*"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    className="hidden"
                />
                <input
                    type="file"
                    accept="audio/*" // Accept only audio files
                    onChange={handleAudioFileChange}
                    ref={audioInputRef}
                    className="hidden"
                />
                {videoPreviewUrl && (
                    <video
                        ref={videoRef}
                        muted
                        loop
                        autoPlay
                        src={videoPreviewUrl}
                        type={videoFile?.type}
                        className="w-full h-[22rem] max-h-[22rem] rounded"
                        onLoadedMetadata={handleLoadedMetadata}
                    />
                )}
                {videoPreviewUrl && (
                    <p className="absolute top-2 left-1/2 transform -translate-x-1/2 text-white bg-[#2b2b2b] px-3 py-[2px] rounded-full text-[10px] font-semibold">
                        {videoDuration}
                    </p>
                )}
                {videoPreviewUrl && frames.length > 0 && (
                    <div className="absolute -bottom-[5.5rem] w-full px-2 overflow-hidden">
                        <div className="flex overflow-x-scroll">
                            {frames.map((frame, index) => (
                                <img
                                    key={index}
                                    src={frame}
                                    alt={`Frame ${index + 1}`}
                                    className="w-[2.5rem] h-[2.5rem] object-cover"
                                />
                            ))}
                        </div>
                        <div className="flex items-center justify-center gap-4">
                            {stepTwo ?
                                <>
                                    <button className="text-black bg-white px-5 py-[5px] rounded-full text-[12px] font-semibold mt-2">
                                        <FaMusic className="text-[15px] hover:text-green-500" />
                                    </button>
                                    <button className="text-black-500 bg-white px-4 py-[3px] rounded-full text-[12px] font-semibold mt-2">
                                        Next
                                    </button>
                                </>
                                :
                                <button
                                    onClick={() => setStepTwo(true)}
                                    className="text-black bg-white px-4 py-[3px] rounded-full text-[12px] font-semibold mt-2"
                                >
                                    Next
                                </button>
                            }
                        </div>
                    </div>
                )}
            </div>

            {/* text popup */}
            {showTextPopup && (
                <div className="absolute inset-0 px-2 flex items-center justify-center bg-black bg-opacity-100 z-20">
                    <div ref={popupRef} className="bg-white p-4 rounded-lg">
                        <textarea
                            rows="4"
                            cols="50"
                            className="w-full border p-2 rounded resize-none"
                            placeholder="Enter your text here..."
                        />
                        <div className="flex items-center justify-center pt-2">
                            <button
                                type="submit"
                                className="bg-black text-white text-[12px] px-3 py-[2px] rounded-full focus:border-blue-500 border"
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* mention popup */}
            {showMentionPopup && (
                <div className="absolute inset-0 px-2 flex items-center justify-center bg-black bg-opacity-100 z-20">
                    <div ref={popupRef} className="bg-[#f6f6f6] p-4 rounded-lg w-full max-w-md">
                        <p className="text-center text-black font-bold mb-4">Select Channels</p>
                        <input
                            type="text"
                            value={searchQuery}
                            onChange={(e) => handleSearch(e.target.value)}
                            placeholder="Search channels..."
                            className="w-full border px-3 py-1 text-[14px] rounded-full mb-4 outline-none"
                        />
                        <div className="flex flex-col gap-2 max-h-[400px] overflow-y-auto">
                            {filteredChannels.map((channel) => (
                                <div key={channel.id} className="flex items-center gap-2">
                                    <input
                                        type="checkbox"
                                        id={`channel-${channel.id}`}
                                        checked={selectedChannels.has(channel.name)}
                                        onChange={() => handleChannelSelect(channel.name)}
                                        className="cursor-pointer"
                                    />
                                    <label htmlFor={`channel-${channel.id}`} className="flex items-center gap-2">
                                        <img src={channel.image} alt={channel.name} className="w-8 h-8 rounded-full" />
                                        <span className="text-[13px] line-clamp-1">{channel.name}</span>
                                    </label>
                                </div>
                            ))}
                        </div>
                        <div className="flex items-center justify-center pt-4">
                            <button
                                type="button"
                                className="bg-black text-white text-[12px] px-3 py-[2px] rounded-full"
                                onClick={() => setShowMentionPopup(false)}
                            >
                                Done
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* filter popup */}
            {showFilterPopup && (
                <div className="absolute inset-0 px-2 flex items-center justify-center bg-black bg-opacity-100 z-20">
                    <div ref={popupRef} className="bg-white p-4 rounded-lg">
                        <p className="text-center text-black font-bold mb-4">Select a Filter</p>
                        <div className="flex gap-4 flex-wrap justify-center">
                            {[
                                { name: "Grayscale", filter: "grayscale(100%)" },
                                { name: "Sepia", filter: "sepia(100%)" },
                                { name: "Blur", filter: "blur(3px)" },
                                { name: "Brightness", filter: "brightness(150%)" }
                            ].map((filterEffect, index) => (
                                <div
                                    key={index}
                                    className={`w-20 h-20 rounded-full overflow-hidden border-2 cursor-pointer transition-all duration-200 ${selectedFilter === filterEffect.name ? "ring-4 ring-green-500" : "border-gray-200"
                                        }`}
                                    onClick={() => setSelectedFilter(filterEffect.name)}
                                    style={{
                                        filter: filterEffect.filter,
                                        backgroundImage: `url('https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                    }}
                                >
                                </div>
                            ))}
                        </div>
                        <div className="flex items-center justify-center pt-4">
                            <button
                                type="button"
                                className="bg-black text-white text-[12px] px-3 py-[2px] rounded-full"
                                onClick={() => setShowFilterPopup(false)}
                            >
                                Apply
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UploadVideo;
