import React, { useEffect, useState } from "react";
import {
    FacebookIcon,
    FacebookShareButton,
    WhatsappIcon,
    WhatsappShareButton,
    TwitterShareButton,
    FacebookMessengerShareButton,
    FacebookMessengerIcon,
    PinterestShareButton,
    PinterestIcon,
    RedditShareButton,
    RedditIcon,
} from "react-share";

import { FaXTwitter } from "react-icons/fa6";
import { MdDone } from "react-icons/md";
import { useDispatch } from "react-redux";
import { IoClose, IoCopy } from "react-icons/io5";
import { shareClips } from "../../redux/apislice/clips/clipsShareSlice";

const Share = ({ handleShare, id, mainurl }) => {
    const dispatch = useDispatch();

    // const shareURL = `https://kindviewer.com/clips/${clipsId}`;
    const shareURL = `${mainurl}${id}`

    const [copied, setCopied] = useState(false);


    useEffect(() => {
        dispatch(shareClips(id));
    }, [dispatch, id]);

    const handleCopy = () => {
        navigator.clipboard.writeText(shareURL).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 3000);
        });
    };

    return (
        <div className="absolute w-full overflow-y-scroll bottom-[7.5rem] md:bottom-[9rem] h-[25vh] md:h-[20vh] bg-[#191919] rounded-t-2xl">
            <div className="px-3 md:px-2 sticky top-0 bg-[#191919] z-10 flex items-center justify-between pt-3 md:pt-2 pb-2 md:pb-1 border-b-[0.4px] border-[#262626]">
                <p className="text-white text-[16px] md:text-[14px]">Share</p>
                <IoClose
                    onClick={handleShare}
                    className="text-white text-[22px] md:text-[20px] cursor-pointer"
                />
            </div>
            <div className="flex items-center justify-between px-3 md:px-2 pt-3 md:pt-2 mr-4">
                <FacebookShareButton
                    url={shareURL}
                    className="h-5 w-5 rounded-full cursor-pointer"
                >
                    <FacebookIcon size={36} round />
                </FacebookShareButton>
                <FacebookMessengerShareButton
                    url={shareURL}
                    className="h-5 w-5 rounded-full cursor-pointer"
                >
                    <FacebookMessengerIcon size={36} round />
                </FacebookMessengerShareButton>
                <WhatsappShareButton
                    url={shareURL}
                    className="h-5 w-5 rounded-full cursor-pointer"
                >
                    <WhatsappIcon size={36} round />
                </WhatsappShareButton>
                <TwitterShareButton
                    url={shareURL}
                    className="h-5 w-5 rounded-full cursor-pointer"
                >
                    {/* <XIcon size={36} round  /> */}
                    <FaXTwitter size={36} className="text-white bg-[#000000] rounded-full p-[6px]" />
                </TwitterShareButton>
                <PinterestShareButton
                    url={shareURL}
                    className="h-5 w-5 rounded-full cursor-pointer"
                >
                    <PinterestIcon size={36} round />
                </PinterestShareButton>
                <RedditShareButton
                    url={shareURL}
                    className="h-5 w-5 rounded-full cursor-pointer"
                >
                    <RedditIcon size={36} round />
                </RedditShareButton>
            </div>

            <div className="flex items-center justify-between gap-1 px-3 md:px-2 pt-3 md:pt-2 pb-4">
                <div className="mt-5 grid grid-cols-12 gap-x-2 w-full">
                    <div className="col-span-10 bg-[#000000] p-2 rounded-md flex items-center justify-start">
                        <p className="text-[12px] text-[#ffffff] leading-[14px]">
                            {shareURL}
                        </p>
                    </div>
                    <div className="col-span-2 flex items-center justify-center bg-[#000000] p-2 rounded-md">
                        {copied ? (
                            <MdDone className="text-[#46ed3a] text-[24px] cursor-pointer" />
                        ) : (
                            <IoCopy onClick={handleCopy} className="text-white text-[22px] cursor-pointer" />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Share;