import React, { Fragment, useCallback, useEffect, useState } from "react";
import { copyright } from "../utils/copyright";
import { CiSearch } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { FaRegTrashAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getWatchList, removeWatchLaterVideo } from "../redux/apislice/watchListSlice";
import Skeleton from "react-loading-skeleton";

const WatchList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const watchlist = useSelector((state) => state.watchLaterList);
    const {
        watchLaterData, isLoading
    } = watchlist;
    const [sortedResults, setSortedResults] = useState([]);
    const [activeFilter, setActiveFilter] = useState("Recently Added");
    function timeAgo(timestamp) {
        const now = new Date(); // Current date and time
        const past = new Date(timestamp); // The timestamp you want to convert

        const diffInSeconds = Math.floor((now - past) / 1000); // Time difference in seconds

        // Define time intervals
        const intervals = {
            year: 31536000, // 365 days * 24 hours * 60 minutes * 60 seconds
            month: 2592000,  // 30 days * 24 hours * 60 minutes * 60 seconds
            week: 604800,    // 7 days * 24 hours * 60 minutes * 60 seconds
            day: 86400,      // 24 hours * 60 minutes * 60 seconds
            hour: 3600,      // 60 minutes * 60 seconds
            minute: 60,      // 60 seconds
            second: 1        // 1 second
        };

        // Calculate the largest time interval and its corresponding value
        for (const [key, value] of Object.entries(intervals)) {
            const timeDifference = Math.floor(diffInSeconds / value);
            if (timeDifference >= 1) {
                return `${timeDifference} ${key}${timeDifference > 1 ? 's' : ''} ago`;
            }
        }

        return 'just now'; // Default if the difference is less than 1 second
    }

    const handleRemove = async (id) => {
        const responce = await dispatch(removeWatchLaterVideo(id))
        if (responce) {
            dispatch(getWatchList())
        }
    }

    const filterItems = useCallback((filterType = "") => {
        let sorted = Array.isArray(watchLaterData?.results) ? [...watchLaterData?.results] : [];
        setActiveFilter(filterType)
        switch (filterType) {
            case "Recently Added":
                sorted.sort((a, b) => new Date(b.video.created_at) - new Date(a.video.created_at));
                return setSortedResults(sorted)

            case "Recently Played":
                return setSortedResults(sorted)

            case "A-Z":
                sorted.sort((a, b) => a.video.title?.localeCompare(b.video.title));
                return setSortedResults(sorted)

            case "Z-A":
                sorted.sort((a, b) => b.video.title.localeCompare(a.video.title));
                return setSortedResults(sorted)

            default:
                return setSortedResults(sorted)
        }
    }, [watchLaterData?.results])

    useEffect(() => {
        filterItems()
    }, [filterItems])



    useEffect(() => {
        dispatch(getWatchList())
    }, [dispatch])

    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center">
                {/* <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / Watch List / V1 / 15 Aug, 2024
                </p> */}
                <p className="text-black text-[9px] py-[2px]">{copyright}</p>
            </div>

            <div className="sticky top-0 grid grid-cols-12 items-center px-2 py-[6px] z-10 bg-[#1e1e1e]">
                <div className="col-span-6 flex items-center justify-start gap-3">
                    <IoMdArrowBack
                        onClick={() => navigate(-1)}
                        className="text-white h-5 w-5 cursor-pointer ml-[-3px]"
                    />
                    <p className="text-white text-[16px] font-medium">Your Watchlist</p>
                </div>
                <div className="col-span-6 flex items-center justify-end gap-3 ">
                    <CiSearch
                        onClick={() => navigate("/search")}
                        className="text-white h-5 w-5 cursor-pointer mr-[-2px]"
                    />
                </div>
            </div>

            {/* Watchlist data will be displayed here */}
            <div className="mt-2 px-2">
                <div className="flex items-center justify-between text-white">
                    <div className="flex items-center gap-2">
                        <p className="text-[14px] font-medium">Total Watchlist</p>
                        <p className="text-[14px] font-bold">{watchLaterData?.results?.length}</p>
                    </div>
                    <div>
                        <p className="text-[12px] font-medium text-blue-700 cursor-pointer" onClick={() => navigate("/clips/saved")}>Saved Clips</p>
                    </div>
                </div>

                <div className="flex items-center justify-between my-2">
                    <p p onClick={() => filterItems("Recently Added")} className={`${activeFilter === "Recently Added" ? "bg-gray-600 text-white" : "bg-white"} px-3 py-[2px] rounded-md text-[10px] cursor-pointer hover:opacity-80`}>
                        Recently Added
                    </p>
                    <p onClick={() => filterItems("Recently Played")} className={`${activeFilter === "Recently Played" ? "bg-gray-600 text-white" : "bg-white"} px-3 py-[2px] rounded-md text-[10px] cursor-pointer hover:opacity-80`}>
                        Recently Played
                    </p>
                    <p onClick={() => filterItems("A-Z")} className={`${activeFilter === "A-Z" ? "bg-gray-600 text-white" : "bg-white"} px-3 py-[2px] rounded-md text-[10px] cursor-pointer hover:opacity-80`}>
                        A-Z
                    </p>
                    <p onClick={() => filterItems("Z-A")} className={`${activeFilter === "Z-A" ? "bg-gray-600 text-white" : "bg-white"} px-3 py-[2px] rounded-md text-[10px] cursor-pointer hover:opacity-80`}>
                        Z-A
                    </p>
                </div>

                {!isLoading && sortedResults?.map((data) => (
                    <div className="grid grid-cols-12 my-4 gap-2" key={data.id}>
                        <div className="col-span-5">
                            <img
                                alt="liked video"
                                src={data?.video?.thumbnail}
                                onClick={() => navigate(`/video/${data?.video?.id}`)}
                                className="w-full h-[4.2rem] object-cover rounded cursor-pointer"
                            />
                        </div>
                        <div className="col-span-7">
                            <p className="text-white text-[12px] leading-[14px] cursor-pointer line-clamp-2">
                                {data?.video?.title}
                            </p>
                            <p className="text-[#808080] text-[12px] cursor-pointer">{data?.video?.author?.handle}</p>
                            <div className="flex items-center justify-between">
                                <div className="flex items-center gap-2">
                                    <p className="text-[#808080] text-[10px]">{data?.video?.views_count} views</p>
                                    <p className="text-[#808080] text-[10px]">•</p>
                                    <p className="text-[#808080] text-[10px]">{timeAgo(data?.added_at)}</p>
                                </div>

                                <FaRegTrashAlt className="text-[#808080] text-[10px] cursor-pointer hover:text-[#f04f4f]" onClick={() => handleRemove(data?.video?.id)} />
                            </div>
                        </div>
                    </div>
                ))}

                {isLoading && ([1, 2, 3, 4, 5, 6].map((_, index) => (
                    <div className="grid grid-cols-12 px-1 gap-2" key={index} >
                        <div className="col-span-5">
                            <Skeleton height={68} width={120} baseColor="#202020" highlightColor="#444" />
                        </div>
                        <div className="col-span-7 py-1 ml-1">
                            <p className="text-white text-[10px] leading-[14px] cursor-pointer line-clamp-2">
                                <Skeleton baseColor="#202020" highlightColor="#444" height={10} width={150} />
                            </p>
                            <p className="text-[#808080] text-[10px] cursor-pointer"> <Skeleton baseColor="#202020" highlightColor="#444" height={10} width={100} /></p>
                            <div className="flex items-center justify-between">
                                <div className="flex flex-col">
                                    <p className="text-[#808080] text-[10px]">
                                        <Skeleton baseColor="#202020" highlightColor="#444" height={10} width={60} /></p>
                                    <div className='grid grid-cols-12 gap-2 justify-between items-center'>
                                        <p className='col-span-4'>
                                            <Skeleton baseColor="#202020" highlightColor="#444" height={10} width={50} />
                                        </p>
                                        <p className='col-span-4'>
                                            <Skeleton baseColor="#202020" highlightColor="#444" height={10} width={50} />
                                        </p>
                                        <p className='col-span-2'></p>
                                        <p className='col-span-2 flex justify-end items-center'>
                                            <Skeleton baseColor="#202020" highlightColor="#444" height={10} width={20} />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )))}
                {watchLaterData?.results?.length === 0 && (
                    <div className="flex items-center justify-center mt-10">
                        <p className="text-white text-[12px]">No videos in your watchlist</p>
                    </div>

                )}
            </div>
        </Fragment>
    )
}

export default WatchList