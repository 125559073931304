import React, { Fragment, useCallback, useEffect, useState } from "react";
import { copyright } from "../../utils/copyright";
import { useNavigate, useParams } from "react-router-dom";
import PlaylistHeader from "./PlaylistHeader";
import { FaRegTrashAlt } from 'react-icons/fa'
import { deletePlaylistVideo, getPlaylistDetails } from '../../redux/apislice/playListSlice'
import { useDispatch, useSelector } from 'react-redux'
import Skeleton from 'react-loading-skeleton'

export default function PlayListVideos() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const params = useParams()
    const { id } = params
    const playlist = useSelector((state) => state.playlist)
    const { playList, loading } = playlist
    const [sortedResults, setSortedResults] = useState([])
    const [activeFilter, setActiveFilter] = useState('')

    function timeAgo(timestamp) {
        const now = new Date(); // Current date and time
        const past = new Date(timestamp); // The timestamp you want to convert

        const diffInSeconds = Math.floor((now - past) / 1000); // Time difference in seconds

        // Define time intervals
        const intervals = {
            year: 31536000, // 365 days * 24 hours * 60 minutes * 60 seconds
            month: 2592000,  // 30 days * 24 hours * 60 minutes * 60 seconds
            week: 604800,    // 7 days * 24 hours * 60 minutes * 60 seconds
            day: 86400,      // 24 hours * 60 minutes * 60 seconds
            hour: 3600,      // 60 minutes * 60 seconds
            minute: 60,      // 60 seconds
            second: 1        // 1 second
        };

        // Calculate the largest time interval and its corresponding value
        for (const [key, value] of Object.entries(intervals)) {
            const timeDifference = Math.floor(diffInSeconds / value);
            if (timeDifference >= 1) {
                return `${timeDifference} ${key}${timeDifference > 1 ? 's' : ''} ago`;
            }
        }

        return 'just now'; // Default if the difference is less than 1 second
    }

    const handleNavigate = (video_id) => {
        navigate(`/video/${video_id}`)
    }

    const fetchPlaylist = useCallback(() => {
        dispatch(getPlaylistDetails(id))
    }, [dispatch, id])


    const handleDelete = (videoId) => {
        dispatch(deletePlaylistVideo(videoId)).then(() => {
            fetchPlaylist()
        })
    }

    useEffect(() => {
        fetchPlaylist()
    }, [fetchPlaylist])


    const filterItems = useCallback((filterType = "") => {
        let sorted = Array.isArray(playList?.videos) ? [...playList?.videos] : [];
        setActiveFilter(filterType)
        switch (filterType) {
            case "Recently Added":
                sorted.sort((a, b) => new Date(b.video.created_at) - new Date(a.video.created_at));
                return setSortedResults(sorted)

            case "Recently Played":
                return setSortedResults(sorted)

            case "A-Z":
                sorted.sort((a, b) => a.video.title?.localeCompare(b.video.title));
                return setSortedResults(sorted)

            case "Z-A":
                sorted.sort((a, b) => b.video.title.localeCompare(a.video.title));
                return setSortedResults(sorted)

            default:
                return setSortedResults(sorted)
        }
    }, [playList?.videos])

    useEffect(() => {
        filterItems()
    }, [filterItems])
    console.log(playList, "playList")

    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center">
                {/* <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / Play List VIDEOS / V1 / 15 Aug, 2024
                </p> */}
                <p className="text-black text-[9px] py-[2px]">{copyright}</p>
            </div>
            <PlaylistHeader />
            <div className="mt-2 px-2">
                <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2 text-white">
                        <p className="text-[14px] font-medium">Total Video</p>
                        <p className="text-[14px] font-bold text-white"> {playList?.videos ? playList.videos?.length : 0}</p>
                    </div>
                </div>
                <div className="flex items-center justify-between my-2">
                    <p
                        onClick={() => filterItems("Recently Added")}

                        className={`${activeFilter === "Recently Added" ? "bg-gray-600 text-white" : "bg-white"}  px-3 py-[2px] rounded-md text-[10px] cursor-pointer hover:opacity-80`}
                    >
                        Recently Added
                    </p>
                    <p
                        onClick={() => filterItems("Recently Played")}

                        className={`${activeFilter === "Recently Played" ? " bg-gray-600 text-white" : "bg-white"}  px-3 py-[2px] rounded-md text-[10px] cursor-pointer hover:opacity-80`}
                    >
                        Recently Played
                    </p>
                    <p
                        onClick={() => filterItems("A-Z")}

                        className={` ${activeFilter === "A-Z" ? " bg-gray-600 text-white" : "bg-white"}  px-3 py-[2px] rounded-md text-[10px] cursor-pointer hover:opacity-80`}
                    >
                        A-Z
                    </p>
                    <p
                        onClick={() => filterItems("Z-A")}

                        className={` ${activeFilter === "Z-A" ? " bg-gray-600 text-white" : "bg-white"}  px-3 py-[2px] rounded-md text-[10px] cursor-pointer hover:opacity-80`}
                    >
                        Z-A
                    </p>
                </div>

                {!loading && sortedResults?.map((data) => (
                    <div className="grid grid-cols-12 px-1 my-4 gap-2" key={data.id}>
                        <div className="col-span-5" >
                            <img
                                onClick={() => handleNavigate(data?.video?.id)}
                                alt="liked video"
                                src={data.video.thumbnail}
                                className="w-full h-[4.35rem] object-cover rounded cursor-pointer"
                            />
                        </div>
                        <div className="col-span-7">
                            <p className="text-white text-[10px] leading-[14px] cursor-pointer line-clamp-2">
                                {data.video.title}
                            </p>
                            <p className="text-[#808080] text-[10px] cursor-pointer" onClick={() => handleNavigate(data?.video?.id)}>{data.video.author?.handle}</p>
                            <div className="flex items-center justify-between">
                                <div className="flex items-center gap-2">
                                    <p className="text-[#808080] text-[10px]" onClick={() => handleNavigate(data?.video?.id)}>{data.video.views_count} views</p>
                                    <p className="text-[#808080] text-[10px]">•</p>
                                    <p className="text-[#808080] text-[10px]" onClick={() => handleNavigate(data?.video?.id)}>{timeAgo(data.video.created_at)}</p>
                                </div>

                                <FaRegTrashAlt className="text-[#808080] text-[10px] cursor-pointer hover:text-[#f04f4f]" onClick={() => handleDelete(data.id)} />
                            </div>
                        </div>
                    </div>
                ))}
                {loading && ([1, 2, 3, 4, 5, 6].map(() => (
                    <div className="grid grid-cols-12 px-1 gap-2" >
                        <div className="col-span-5">
                            <Skeleton height={68} width={120} baseColor="#202020" highlightColor="#444" />
                        </div>
                        <div className="col-span-7 py-1 ml-1">
                            <p className="text-white text-[10px] leading-[14px] cursor-pointer line-clamp-2">
                                <Skeleton baseColor="#202020" highlightColor="#444" height={10} width={150} />
                            </p>
                            <p className="text-[#808080] text-[10px] cursor-pointer"> <Skeleton baseColor="#202020" highlightColor="#444" height={10} width={100} /></p>
                            <div className="flex items-center justify-between">
                                <div className="flex flex-col">
                                    <p className="text-[#808080] text-[10px]">
                                        <Skeleton baseColor="#202020" highlightColor="#444" height={10} width={60} /></p>
                                    <div className='grid grid-cols-12 gap-2 justify-between items-center'>
                                        <p className='col-span-4'>
                                            <Skeleton baseColor="#202020" highlightColor="#444" height={10} width={50} />
                                        </p>
                                        <p className='col-span-4'>
                                            <Skeleton baseColor="#202020" highlightColor="#444" height={10} width={50} />
                                        </p>
                                        <p className='col-span-2'></p>
                                        <p className='col-span-2 flex justify-end items-center'>
                                            <Skeleton baseColor="#202020" highlightColor="#444" height={10} width={20} />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )))}
                {playList?.videos?.length === 0 ? <p className='text-[14px] font-bold text-white'>No videos found</p> : null}
            </div>
        </Fragment>
    )
}
