import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

// 16 dot menu images
import menuIcon from "../../assets/16_DOTS_SQUARE_BOX.png";
import createVideo from "../../assets/16dot_menu/create_video.png";
// import uploadVideo from "../../assets/16dot_menu/upload_video.png";
import createClips from "../../assets/16dot_menu/create_clips.png";
import createPost from "../../assets/16dot_menu/create_post.png";
import goLive from "../../assets/16dot_menu/go_live.png";
import createNews from "../../assets/16dot_menu/create_news.png";
import watchList from "../../assets/16dot_menu/watch_list.png";
import myPlaylist from "../../assets/16dot_menu/my_playlist.png";
import downloaded from "../../assets/16dot_menu/downloaded.png";
import settings from "../../assets/16dot_menu/settings.png";
import editAccount from "../../assets/16dot_menu/change_account.png";
import likedVideo from "../../assets/16dot_menu/liked_video.png";
import history from "../../assets/16dot_menu/history.png";
import trending from "../../assets/16dot_menu/trending.png";
import clips from "../../assets/16dot_menu/clips.png";
import bottomIcon from "../../assets/16dot_menu/bottom_icon.png";

import { FaUsers } from "react-icons/fa";
// import { FaCircleUser } from "react-icons/fa6";
import { IoIosHelpCircle } from "react-icons/io";
// import { BsMusicNoteList } from "react-icons/bs";
import { MdWorkspacePremium, MdOutlinePermMedia, MdOutlineContentPaste } from "react-icons/md";
import { useSelector } from "react-redux";

const MainMenu = () => {
    const navigate = useNavigate();
    const [menu, setMenu] = useState(false);
    const [additionalMenu, setAdditionalMenu] = useState(false);
    const menuRef = useRef(null);
    const toggleButtonRef = useRef(null);
    const profile = useSelector((state) => state.profile);
    const { userDetails } = profile
    const { randomClipsId } = useSelector(state => state.clips);

    // 16 dot menu items
    const menuItems = [
        { img: "https://cdn.pixabay.com/photo/2015/01/08/18/29/entrepreneur-593358_1280.jpg", label: "User Account", pathName: "/account" },
        { img: trending, label: "Trending", pathName: "/trending" },
        { img: clips, label: "Clips", pathName: `/clips/${randomClipsId?.selected_id}` },
        { img: createVideo, label: "Create Video", pathName: "/createvideo" },
        // { img: uploadVideo, label: "Upload Video", pathName: "/uploadvideo" },
        { img: createClips, label: "Create Clips", pathName: "/createclips" },
        { img: createPost, label: "Create Post", pathName: "/createpost" },
        { img: createNews, label: "Create News", pathName: "/createnews" },
        { img: goLive, label: "Go Live", pathName: "/golive" },
        { img: watchList, label: "Watch List", pathName: "/watchlist" },
        { img: myPlaylist, label: "My Playlists", pathName: "/playlist" },
        { img: downloaded, label: "Downloaded", pathName: "/downloads" },
        { img: settings, label: "Settings", pathName: "/settings" },
        { img: editAccount, label: "Edit Account", pathName: "/edit/account" },
        { img: likedVideo, label: "Liked Videos", pathName: "/likedvideos" },
        { img: history, label: "History", pathName: "/history" },
        { icon: MdWorkspacePremium, label: "KV Sponsored", pathName: "/sponsore" },
    ];

    // Additional 16 dot menu items
    const additionalMenuItems = [
        { icon: FaUsers, label: "About Us", pathName: "/about" },
        { icon: MdOutlinePermMedia, label: "Media", pathName: "/media" },
        { icon: MdOutlineContentPaste, label: "Blog", pathName: "/blog" },
        { icon: IoIosHelpCircle, label: "Help", pathName: "/help" },
    ];

    const handleMenuToggle = (e) => {
        e.stopPropagation();
        setMenu((prevMenu) => !prevMenu);
        setAdditionalMenu(false);
    };

    const handleAdditionalMenuToggle = () => {
        setAdditionalMenu((prevMenu) => !prevMenu);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                menuRef.current &&
                !menuRef.current.contains(event.target) &&
                !toggleButtonRef.current.contains(event.target)
            ) {
                setMenu(false);
                setAdditionalMenu(false); // Close both menus on outside click
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuRef]);

    return (
        <div className="relative">
            <img
                alt="menu"
                src={menuIcon}
                ref={toggleButtonRef}
                onClick={handleMenuToggle}
                className="h-8 w-8 md:h-6 md:w-6 cursor-pointer"
            />
            {menu && (
                <div ref={menuRef} className="absolute top-[2rem] right-0 bg-[#ffffff] w-[7.5rem] z-20">
                    {menuItems.map((item, index) => (
                        <div
                            key={index}
                            onClick={() => navigate(item.pathName)}
                            className={`flex items-center gap-3 px-2 py-[2px] 
                                ${index !== menuItems.length - 1 || additionalMenu ? "border-b-[0.4px] border-[#dedede]" : ""}
                            `}
                        >
                            {item.img ? (
                                <img src={item.pathName === "/account" ? userDetails?.profile_picture : item.img} alt={item.label} className="h-4 w-4 rounded-full" />
                            ) : (
                                <item.icon className="h-4 w-4 text-[#5c5b5b]" />
                            )}
                            <p className="text-[12px] hover:text-[#f33535] cursor-pointer">{item.label}</p>
                        </div>
                    ))}
                    {menu && additionalMenu && (
                        <>
                            {additionalMenuItems.map((item, index) => (
                                <div
                                    key={index}
                                    onClick={() => navigate(item.pathName)}
                                    className={`flex items-center gap-3 px-2 py-[2px] border-[#dedede] 
                                    ${index === additionalMenuItems.length - 1 ? "" : "border-b-[0.4px]"}
                                `}
                                >
                                    {item.img ? (
                                        <img src={item.img} alt={item.label} className="h-4 w-4" />
                                    ) : (
                                        <item.icon className="h-4 w-4 text-[#5c5b5b]" />
                                    )}
                                    <p className="text-[12px] hover:text-[#f33535] cursor-pointer">{item.label}</p>
                                </div>
                            ))}
                        </>
                    )}
                    <div className="flex items-center gap-3 px-2 pt-[2px] pb-2 relative">
                        {/* {!additionalMenu &&
                            <img
                                src={clips}
                                alt="create video"
                                className="h-4 w-4"
                                onClick={() => navigate("/clips")}
                            />
                        }
                        {!additionalMenu &&
                            <p
                                onClick={() => navigate("/clips")}
                                className="text-[12px] hover:text-[#f33535] cursor-pointer"
                            >
                                Clips
                            </p>
                        } */}
                        <div className="absolute -bottom-[7px] left-1/2 -translate-x-1/2">
                            <div className="bg-[#9F9F9F] px-5 py-1 cursor-pointer" onClick={handleAdditionalMenuToggle}>
                                <img alt="bottom icon" src={bottomIcon} className="h-[6px] w-[6px] flex items-center justify-center" />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default MainMenu