import React, { Fragment, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { copyright } from "../utils/copyright";
import BeatLoader from "react-spinners/BeatLoader";

import { CgMenuGridR } from "react-icons/cg";
import { TbMusicPlus } from "react-icons/tb";
import { RiFolderMusicFill } from "react-icons/ri";
import { IoCaretBackCircle, IoPause, IoPlay } from "react-icons/io5";
import BottomPlayer from "../components/music/BottomPlayer";

import { useDispatch, useSelector } from "react-redux";
import { getAllMusic, latestMusic, mostPlayedMusic, playIncreaseById, savedMusicList, musicByCategory } from "../redux/apislice/music/musicSlice";

const MusicPage = () => {
   const [showMusicMenu, setShowMusicMenu] = useState(false);
   const [currentPlayingId, setCurrentPlayingId] = useState(null);
   const [currentMusic, setCurrentMusic] = useState(null);
   const [progress, setProgress] = useState(0);
   const [isPlaying, setIsPlaying] = useState(false);
   const [isMuted, setIsMuted] = useState(false);
   const [volume, setVolume] = useState(100);
   const [currentTime, setCurrentTime] = useState(0);
   const [duration, setDuration] = useState(0);
   const [showMostPlayed, setShowMostPlayed] = useState(false);
   const [latest, setLatest] = useState(false);
   const [saved, setSaved] = useState(false);
   const [searchTerm, setSearchTerm] = useState("");
   const [selectedCategory, setSelectedCategory] = useState(null);
   const [selectedCategoryName, setSelectedCategoryName] = useState("");

   const audioRef = useRef(null);
   const navigate = useNavigate();
   const menuRef = useRef(null);

   const dispatch = useDispatch();
   const musicData = useSelector((state) => state.musicList.data) || [];
   const loading = useSelector((state) => state.musicList.loading);

   const musicMenu = [
      { id: 1, name: "Electronic Dance Music", value: "ElectronicDanceMusic" },
      { id: 2, name: "Rock Music", value: "RockMusic" },
      { id: 3, name: "Jazz", value: "Jazz" },
      { id: 4, name: "Dubstep", value: "Dubstep" },
      { id: 5, name: "Rhythm and Blues", value: "RhythmandBlues" },
      { id: 6, name: "Techno", value: "Techno" },
      { id: 7, name: "Country Music", value: "CountryMusic" },
      { id: 8, name: "Electro", value: "Electro" },
      { id: 9, name: "Indie Rock", value: "IndieRock" },
      { id: 10, name: "Pop Music", value: "PopMusic" },
   ];

   useEffect(() => {
      dispatch(getAllMusic());
   }, [dispatch]);

   useEffect(() => {
      let interval;
      if (isPlaying) {
         interval = setInterval(() => {
            if (audioRef.current) {
               setCurrentTime(audioRef.current.currentTime);
               setProgress((audioRef.current.currentTime / audioRef.current.duration) * 100);
            }
         }, 1000);
      } else {
         clearInterval(interval);
      }
      return () => clearInterval(interval);
   }, [isPlaying]);

   useEffect(() => {
      if (audioRef.current) {
         audioRef.current.addEventListener('error', handleAudioError);
      }
      return () => {
         if (audioRef.current) {
            audioRef.current.removeEventListener('error', handleAudioError);
         }
      };
   }, []);

   useEffect(() => {
      const handleClickOutside = (event) => {
         if (menuRef.current && !menuRef.current.contains(event.target)) {
            setShowMusicMenu(false);
         }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
         document.removeEventListener("mousedown", handleClickOutside);
      };
   }, []);

   const handleAudioError = () => {
      console.error('Failed to load audio source.');
      setIsPlaying(false);
      setCurrentPlayingId(null);
      setCurrentMusic(null);
      setProgress(0);
      setCurrentTime(0);
      setDuration(0);
   };

   const handlePlayPause = (id, music) => {
      if (currentPlayingId === id) {
         if (isPlaying) {
            audioRef.current.pause();
         } else {
            audioRef.current.play();
            dispatch(playIncreaseById(id));
         }
         setIsPlaying(!isPlaying);
      } else {
         setCurrentPlayingId(id);
         setCurrentMusic(music);
         setProgress(0);
         setCurrentTime(0);
         if (audioRef.current) {
            audioRef.current.src = music.file;
            audioRef.current.play().catch(handleAudioError);
            dispatch(playIncreaseById(id));
            setIsPlaying(true);
         }
      }
   };

   const handleNext = () => {
      const currentIndex = musicData.findIndex((music) => music.id === currentPlayingId);
      const nextIndex = (currentIndex + 1) % musicData.length;
      const nextMusic = musicData[nextIndex];
      setCurrentMusic(nextMusic);
      setCurrentPlayingId(nextMusic.id);
      setProgress(0);
      setCurrentTime(0);
      if (audioRef.current) {
         audioRef.current.src = nextMusic.file;
         audioRef.current.play().catch(handleAudioError);
         setIsPlaying(true);
      }
   };

   const handlePrevious = () => {
      const currentIndex = musicData.findIndex((music) => music.id === currentPlayingId);
      const previousIndex = (currentIndex - 1 + musicData.length) % musicData.length;
      const previousMusic = musicData[previousIndex];
      setCurrentMusic(previousMusic);
      setCurrentPlayingId(previousMusic.id);
      setProgress(0);
      setCurrentTime(0);
      if (audioRef.current) {
         audioRef.current.src = previousMusic.file;
         audioRef.current.play().catch(handleAudioError);
         setIsPlaying(true);
      }
   };

   const handleMute = () => {
      setIsMuted(!isMuted);
      if (audioRef.current) {
         audioRef.current.muted = !isMuted;
      }
   };

   const handleVolumeChange = (e) => {
      const volume = e.target.value;
      setVolume(volume);
      if (audioRef.current) {
         audioRef.current.volume = volume / 100;
      }
   };

   const handleSeek = (e, progressBar) => {
      const rect = progressBar.getBoundingClientRect();
      const offsetX = e.clientX - rect.left;
      const seekTime = (offsetX / progressBar.clientWidth) * audioRef.current.duration;
      audioRef.current.currentTime = seekTime;
      setCurrentTime(seekTime);
      setProgress((seekTime / audioRef.current.duration) * 100);
   };

   const formatTime = (time) => {
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60);
      return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
   };

   const handleMostPlayedClick = () => {
      setShowMostPlayed(true);
      setLatest(false);
      setSaved(false);
      setSelectedCategory(null);
      setSelectedCategoryName("");
      dispatch(mostPlayedMusic());
   };

   const handleLatestClick = () => {
      setShowMostPlayed(false);
      setLatest(true);
      setSaved(false);
      setSelectedCategory(null);
      setSelectedCategoryName("");
      dispatch(latestMusic());
   };

   const handleSavedClick = () => {
      setShowMostPlayed(false);
      setLatest(false);
      setSaved(true);
      setSelectedCategory(null);
      setSelectedCategoryName("");
      dispatch(savedMusicList());
   };

   const handleCategoryClick = (category) => {
      const categoryName = musicMenu.find(menu => menu.value === category).name;
      setShowMostPlayed(false);
      setLatest(false);
      setSaved(false);
      setSelectedCategory(category);
      setSelectedCategoryName(categoryName);
      setShowMusicMenu(false);
      dispatch(musicByCategory(category));
   };

   const filteredMusicData = Array.isArray(musicData) ? musicData.filter((data) =>
      data.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      data.singer.toLowerCase().includes(searchTerm.toLowerCase())
   ) : [];

   return (
      <Fragment>
         <div className="sticky top-0 bg-black pb-2">
            <div className="bg-[#d9d9d9] text-center">
               {/* <p className="text-red-600 text-[9px] uppercase font-bold">
                  KV / FE / Music / V2 / 21 OCT, 2024
               </p> */}
               <p className="text-black text-[9px] py-[2px]">{copyright}</p>
            </div>
            <div className="flex items-center gap-x-2 px-2 pt-2">
               <IoCaretBackCircle
                  onClick={() => navigate(-1)}
                  className="text-[32px] md:text-[28px] ml-[-1px] text-white cursor-pointer"
               />
               <input
                  type="text"
                  placeholder="Search for music ..."
                  className="w-full px-3 py-[6px] md:py-1 text-white placeholder:text-[13px] text-[13px] rounded-full border-none bg-[#474747] focus:outline-none"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
               />
               <TbMusicPlus
                  onClick={() => navigate("/upload-music")}
                  className="text-[32px] md:text-[22px] text-white cursor-pointer"
               />
               <RiFolderMusicFill
                  onClick={() => navigate("/music-list")}
                  className="text-[32px] md:text-[22px] text-white cursor-pointer"
               />
            </div>
            <div className="flex items-center justify-between mt-2 relative px-2" ref={menuRef}>
               <CgMenuGridR
                  onClick={() => setShowMusicMenu(!showMusicMenu)}
                  className={`text-[30px] md:text-[24px] ml-[-4px] ${showMusicMenu ? "text-[#27b129]" : "text-[#ffffff]"} cursor-pointer`}
               />
               <button
                  onClick={handleMostPlayedClick}
                  className={`text-[12px] md:text-[10px] font-semibold ml-[-6px] 
                     ${showMostPlayed ? "bg-[#ffffff] text-black" : "bg-[#3e3e3e] text-white"} 
                     hover:bg-[#ffffff] hover:text-black w-[6.5rem] md:w-[5rem] py-[6px] md:py-[5px] rounded flex items-center justify-center cursor-pointer transition-all duration-500`
                  }
               >
                  Most Played
               </button>
               <button
                  onClick={handleLatestClick}
                  className={`text-[12px] md:text-[10px] font-semibold  
                     ${latest ? "bg-[#ffffff] text-black" : "bg-[#3e3e3e] text-white"} 
                     hover:bg-[#ffffff] hover:text-black w-[6.5rem] md:w-[5rem] py-[6px] md:py-[5px] rounded flex items-center justify-center cursor-pointer transition-all duration-500`
                  }
               >
                  Latest
               </button>
               <button
                  onClick={handleSavedClick}
                  className={`text-[12px] md:text-[10px] font-semibold  
                     ${saved ? "bg-[#ffffff] text-black" : "bg-[#3e3e3e] text-white"} 
                     hover:bg-[#ffffff] hover:text-black w-[6.5rem] md:w-[5rem] py-[6px] md:py-[5px] rounded flex items-center justify-center cursor-pointer transition-all duration-500`
                  }
               >
                  Saved
               </button>
               {showMusicMenu && (
                  <div className="absolute top-8 md:top-7 left-2 bg-white rounded overflow-hidden">
                     {musicMenu.map((menu, index) => (
                        <p
                           key={menu.id}
                           onClick={() => handleCategoryClick(menu.value)}
                           className={`w-[11rem] md:w-[8rem] text-[14px] md:text-[10px] text-[#1c1c1c] hover:font-[600] px-3 md:px-2 py-[3px] md:py-[3px] cursor-pointer 
                              ${index !== musicMenu.length - 1 ? 'border-b border-[#e3e3e3]' : ''}
                              ${selectedCategory === menu.value ? 'bg-[#27b129] text-white' : ''}`
                           }
                        >
                           {menu.name}
                        </p>
                     ))}
                  </div>
               )}
            </div>
            {selectedCategoryName && (
               <div className="text-center text-[#ffffff] bg-[#3e3e3e] rounded py-1 mx-2 mt-2">
                  <p className="text-[14px] md:text-[11px] font-semibold">{selectedCategoryName}</p>
               </div>
            )}
         </div>
         <div className={`mb-2 px-2 ${currentMusic ? "h-[65vh] md:h-[49vh]" : "h-full"} overflow-y-scroll`}>
            <div className="mt-2">
               {loading ? (
                  <div className="flex items-center justify-center">
                     <BeatLoader
                        color={"#fff"}
                        loading={true}
                        size={15}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                     />
                  </div>
               ) : (
                  Array.isArray(filteredMusicData) && filteredMusicData.length > 0 ? (
                     filteredMusicData.map((data) => (
                        <div className="grid grid-cols-12 gap-x-2 mb-4 md:mb-3" key={data.id}>
                           <div className="col-span-2">
                              <img
                                 alt={data.singer}
                                 src={data.image === null ?
                                    "https://cdn.pixabay.com/photo/2021/01/29/08/10/musician-5960112_1280.jpg" : data.image
                                 }
                                 className="w-14 md:w-10 h-14 md:h-10 rounded-full object-cover"
                              />
                           </div>
                           <div className="col-span-9 ml-[-1px] md:ml-[-5px] mt-[2px] md:mt-0 flex flex-col justify-between">
                              <p className="text-[14px] md:text-[9px] text-white line-clamp-1 leading-[12px]">
                                 {data.title}
                              </p>
                              <p className="text-[13px] md:text-[10px] font-bold text-[#4d4d4d]">
                                 {data.singer}
                              </p>
                              <div className="flex items-center gap-x-4">
                                 <p className="text-[11px] md:text-[8px] text-[#27b129]">
                                    {data.plays === 0 ? "not played yet" : `${data.plays} played`}
                                 </p>
                                 <p className="text-[11px] md:text-[8px] text-[#27b129]">
                                    {data.saves === 0 ? "not saved yet" : `${data.saves} saved`}
                                 </p>
                              </div>
                           </div>
                           <div className="col-span-1 flex justify-between items-start">
                              {isPlaying && currentPlayingId === data.id ? (
                                 <IoPause
                                    onClick={() => handlePlayPause(data.id, data)}
                                    className="text-[20px] md:text-[16px] text-white cursor-pointer"
                                 />
                              ) : (
                                 <IoPlay
                                    onClick={() => handlePlayPause(data.id, data)}
                                    className="text-[20px] md:text-[16px] text-white cursor-pointer"
                                 />
                              )}
                           </div>
                        </div>
                     ))
                  ) : (
                     <p className="text-[#4d4d4d] px-1 text-[14px] text-center">
                        No music available
                     </p>
                  )
               )}
            </div>
         </div>

         {/* Bottom Player */}
         {currentMusic && (
            <BottomPlayer
               currentMusic={currentMusic}
               isPlaying={isPlaying}
               isMuted={isMuted}
               volume={volume}
               progress={progress}
               currentTime={currentTime}
               duration={duration}
               handlePlayPause={handlePlayPause}
               handleNext={handleNext}
               handlePrevious={handlePrevious}
               handleMute={handleMute}
               handleVolumeChange={handleVolumeChange}
               handleSeek={handleSeek}
               formatTime={formatTime}
            />
         )}
         <audio ref={audioRef} onLoadedMetadata={() => setDuration(audioRef.current.duration)} />
      </Fragment>
   );
};

export default MusicPage;