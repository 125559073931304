import { Input, Switch } from "@material-tailwind/react";
import { copyright } from "../../utils/copyright";
import React, { Fragment, useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { IoMdArrowBack } from "react-icons/io";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { createPlaylist, getPlaylistDetails, updatePlaylist } from "../../redux/apislice/playListSlice";

export default function CreatePlaylist() {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const params = useParams();
    const { id } = params;
    const [togg, setTogg] = useState(false);
    const [playListTitle, setPlayListTitle] = useState("");
    const createPlayList = () => {
        if (playListTitle !== "") {
            dispatch(createPlaylist({ title: playListTitle, status: togg ? "Private" : "Public" })).then((res) => {
                if (res.payload) {
                    navigate("/playlist")
                }
                else {
                    console.log(res.error)
                }

            })
            setPlayListTitle("");
        }
    }
    const updatePlayList = () => {
        dispatch(updatePlaylist({ playlistId: id, title: playListTitle, status: togg ? "Private" : "Public" })).then((res) => {
            if (res.payload) {
                navigate("/playlist")
            }
            else {
                console.log(res.error)
            }
        })
    }
    useEffect(() => {
        if (id) {
            dispatch(getPlaylistDetails(id)).then((res) => {
                if (res.payload) {
                    setPlayListTitle(res.payload.title)
                    setTogg(res.payload.status === "Private" ? true : false)
                }
                else {
                    console.log(res.error)
                }
            })
        }
    }, [dispatch ,id])

    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center">
                {/* <p className="text-red-600 text-[9px] uppercase font-bold">
                    {`KV / FE / ${id ? "EDIT" : "CREATE"}  Play List / V1 / 15 Aug, 2024`}
                </p> */}
               <p className="text-black text-[9px] py-[2px]">{copyright}</p>
            </div>

            <div className="sticky top-0 grid grid-cols-12 items-center px-2 py-[6px] z-10 bg-[#1e1e1e]">
                <div className="col-span-6 flex items-center justify-start gap-3">
                    <IoMdArrowBack
                        onClick={() => navigate(-1)}
                        className="text-white h-5 w-5 cursor-pointer ml-[-3px]"
                    />
                    <p className="text-white text-[16px] font-medium">Your Playlist</p>
                </div>
                <div className="col-span-6 flex items-center justify-end gap-3 ">
                    <CiSearch
                        onClick={() => navigate("/search")}
                        className="text-white h-5 w-5 cursor-pointer mr-[-2px]"
                    />
                </div>
            </div>

            <div className="px-2 mt-2">
                <div className="flex items-center justify-between">
                    <p className="text-white text-[14px] font-bold">{`${id ? "Edit": "Create"} Playlist`}</p>
                    <p
                        onClick={() => navigate(-1)}
                        className="text-[10px] text-light-blue-600 hover:text-light-blue-500 cursor-pointer"
                    >
                        Close
                    </p>
                </div>
                <div className="flex flex-col mt-4">
                    <Input label="Title" color="white" required value={playListTitle} onChange={(e) => setPlayListTitle(e.target.value)} />
                </div>
                <div className="flex items-center justify-between mt-4">
                    <p className="text-[12px] text-white">
                        Private
                    </p>
                    <Switch
                        ripple={false}
                        className="h-4 w-[28.5px] checked:bg-[#0DD315]"
                        containerProps={{
                            className: "w-8 h-4",
                        }}
                        circleProps={{
                            className: "before:hidden left-0.5 border-none w-3 h-3",
                        }}
                        checked={togg}
                        onChange={() => setTogg(!togg)}
                    />
                </div>
                <div className="flex items-center justify-between mt-4">
                    <button
                        onClick={() => navigate(-1)}
                        className="bg-[#3e3e3e] opacity-80 hover:opacity-100 transition-all duration-300 text-[13px] text-white px-4 py-[3px] rounded-md"
                    >
                        Cancel
                    </button>
                    {id ? <button
                        onClick={() => updatePlayList()}
                        className="bg-[#3e3e3e] opacity-80 hover:opacity-100 transition-all duration-300 text-[13px] text-white px-4 py-[3px] rounded-md"
                    >
                        update
                    </button> :
                        <button
                            onClick={() => createPlayList()}
                            className="bg-[#3e3e3e] opacity-80 hover:opacity-100 transition-all duration-300 text-[13px] text-white px-4 py-[3px] rounded-md"
                        >
                            Create
                        </button>}
                </div>
            </div>

        </Fragment>
    )
}
