import React, { Fragment, useEffect } from "react";
import { copyright } from "../../utils/copyright";
import { useNavigate } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { blogList } from "../../redux/apislice/blogSlice";
import BeatLoader from "react-spinners/BeatLoader";

const BlogPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loading, data } = useSelector((state) => state.blog);

    useEffect(() => {
        dispatch(blogList());
    }, [dispatch]);

    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center">
                {/* <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / Blog / V1 / 26 OCT, 2024
                </p> */}
                <p className="text-black text-[9px] py-[2px]">{copyright}</p>
            </div>

            <div className="sticky top-0 grid grid-cols-12 items-center px-2 py-[6px] z-10 bg-[#1e1e1e]">
                <div className="col-span-6 flex items-center justify-start gap-3">
                    <IoMdArrowRoundBack
                        onClick={() => navigate(-1)}
                        className="text-white h-5 w-5 cursor-pointer ml-[-3px]"
                    />
                    <p className="text-white text-[16px] font-medium">Blog</p>
                </div>
                <div className="col-span-6 flex items-center justify-end gap-3">
                    <CiSearch
                        onClick={() => navigate("/search")}
                        className="text-white h-5 w-5 cursor-pointer mr-[-2px]"
                    />
                </div>
            </div>

            {/* Blog Card */}
            {loading ?
                <div className="flex items-center justify-center mt-3">
                    <BeatLoader
                        color={"#fff"}
                        loading={true}
                        size={15}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                </div>
                :
                (Array.isArray(data) && data.map((item, index) => (
                    <div
                        key={index}
                        onClick={() => navigate(`/blog/${item.id}`)}
                        className="grid grid-cols-12 p-2 gap-x-2 border-b border-[#363636]"
                    >
                        <div className="col-span-5">
                            <img
                                alt="blog"
                                className="h-[5.5rem] w-full object-cover rounded cursor-pointer"
                                src={item.post_image ? item.post_image :
                                    "https://cdn.pixabay.com/photo/2014/06/19/21/36/blog-372771_1280.jpg"
                                }
                            />
                        </div>
                        <div className="col-span-7 flex flex-col justify-between">
                            <p className="text-[16px] md:text-[12px] text-[#ffffff] font-bold line-clamp-2 leading-[18px] md:leading-[13px] cursor-pointer">
                                {item.title}
                            </p>
                            <p className="text-[14px] md:text-[10px] text-[#c4c4c4] line-clamp-3 leading-[16px] md:leading-[12px] cursor-pointer">
                                {item.content}
                            </p>
                            <p className="text-[14px] md:text-[10px] text-light-blue-700 hover:text-light-blue-400 cursor-pointer transition-all duration-300">
                                View More
                            </p>
                        </div>
                    </div>
                ))
                )}
        </Fragment>
    )
}

export default BlogPage;