import React, { Fragment, useEffect } from 'react'
import { CiSearch } from 'react-icons/ci'
import { IoMdArrowBack } from 'react-icons/io'
import { copyright } from '../../utils/copyright'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@material-tailwind/react'
import { unBlockChannel } from '../../redux/apislice/blockSlice'

export default function BlockedChannels() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const blockedChannels = useSelector((state) => state.blocked)
    const [blockedList, setBlockedList] = React.useState([])
    const { data } = blockedChannels


    const handleNavigate = (id) => {
        navigate(`/otheruser/account/${id}`)
    }

    const handleUnblock = async (channelId) => {
        await dispatch(unBlockChannel(channelId)).then((res) => {
            if (res.error) {
                console.log(res.error, "error")
            } else {
                setBlockedList(blockedList.filter((channel) => channel.id !== channelId))
            }
        })
    }

    useEffect(() => {
        setBlockedList(data)
    }, [data])
    console.log(blockedChannels, "blockedChannels")
    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center">
                {/* <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE /Blocked Channels list / V1 / 8 Nov, 2024
                </p> */}
                <p className="text-black text-[9px] py-[2px]">{copyright}</p>
            </div>

            <div className="sticky top-0 grid grid-cols-12 items-center px-2 py-[6px] z-10 bg-[#1e1e1e]">
                <div className="col-span-6 flex items-center justify-start gap-3">
                    <IoMdArrowBack
                        onClick={() => navigate(-1)}
                        className="text-white h-5 w-5 cursor-pointer ml-[-3px]"
                    />
                    <p className="text-white text-[16px] font-medium">Blocked Channels</p>
                </div>
                <div className="col-span-6 flex items-center justify-end gap-3 ">
                    <CiSearch
                        onClick={() => navigate("/search")}
                        className="text-white h-5 w-5 cursor-pointer mr-[-2px]"
                    />
                </div>
            </div>

            <div className="grid grid-cols-12">
                {blockedList?.map((channel) => (
                    <>
                        <div className='col-span-12 grid grid-cols-12 px-2 py-2 hover:bg-gray-800'>
                            <div className='col-span-2'>
                                <img
                                    onClick={() => handleNavigate(channel?.id)}
                                    alt={channel?.handle} src={channel?.profile_picture}
                                    className={`w-[2.5rem] rounded-full h-[2.5rem] object-cover  cursor-pointer hover:border-[2px] hover:border-light-blue-500 border-transparent`}
                                />
                            </div>
                            <div className='col-span-7 flex items-center justify-start'>
                                <p className='text-white font-semibold text-[12px]'>{channel?.full_name}</p>
                            </div>
                            <div className='col-span-3 flex justify-end items-center'>
                                <Button onClick={() => handleUnblock(channel?.id)} className='rounded-full px-2 py-2 text-[10px] hover:bg-green-500'>
                                    Unblock
                                </Button>
                            </div>
                        </div>
                        <hr className='col-span-12 border-[#3a3a3a] border-t-1' />
                    </>

                ))}
                {blockedList?.length === 0 && <div className="col-span-12">
                    <div className="flex items-center justify-center mt-10">
                        <p className="text-[16px] text-white font-medium">No blocked channels</p>
                    </div>
                </div>}
            </div>
        </Fragment>
    )
}
