import React, { Fragment, useState, useRef, useEffect } from "react";
import { copyright } from "../utils/copyright";
import kv_logo from "../assets/kv_logo.png";

import { useNavigate } from "react-router-dom";
import { Switch } from "@material-tailwind/react";
import MainMenu from "../components/common/MainMenu";
import SubscribedCard from "./subscribed/SubscribedCard";
import SubscribedList from "./subscribed/SubscribedList";

import { IoIosSettings } from "react-icons/io";
import { IoFilterCircle, IoNotifications, IoSearch } from "react-icons/io5";

import { useDispatch, useSelector } from "react-redux";
import { profileDetails } from "../redux/apislice/profileSlice";
import Cookies from "js-cookie";
import Skeleton from "react-loading-skeleton";
import { getAllSubscribed, getSubscribedVideos, hideChannel, unHideChannel } from "../redux/apislice/subscribed/subscribedSlice";
import { createSubscription, DeleteSubscription, getAllUserSubscription, getUserSubscription } from "../redux/apislice/subscriptionSlice";
import BeatLoader from "react-spinners/BeatLoader";

const Subscribed = () => {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const profile = useSelector((state) => state.profile);
    const { loading, userDetails } = profile;
    const userId = Cookies.get("userId");

    const filterRef = useRef(null);
    const settingsRef = useRef(null);

    const [showFilter, setShowFilter] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const [showSubscriptions, setShowSubscriptions] = useState(false);
    const [isSubscribe, setIsSubscribe] = useState(true);

    const [subscribedData, setSubscribedData] = useState([]);
    const subscribed = useSelector((state) => state.subscribed);
    const { data, subVideos, subLoading } = subscribed;
    const subscriptionlist = useSelector((state) => state.subscription);
    const { subscribeList } = subscriptionlist;

    const handleFilter = () => {
        setShowFilter(!showFilter);
        setShowSettings(false);
    };

    // const handleSettings = () => {
    //     setShowSettings(!showSettings);
    //     setShowFilter(false);
    // };

    const handleShowSubscriptions = () => {
        setShowSubscriptions(true);
    }

    const handleClickOutside = (event) => {
        if (
            filterRef.current && !filterRef.current.contains(event.target) &&
            settingsRef.current && !settingsRef.current.contains(event.target)
        ) {
            setShowFilter(false);
            setShowSettings(false);
        }
    };


    // api calls
    const handleClickSub = (chennel_id) => {
        const subscribe = subscribeList?.results?.some((data) => data.channel.id === chennel_id);

        if (subscribe) {
            deleteSubscribe(chennel_id);
        } else {
            handleSubscribe(chennel_id);
        }
    }

    const handleSubscribe = async (chennel_id) => {
        setIsSubscribe(!isSubscribe);
        try {
            const rest = await dispatch(createSubscription({ channel_id: chennel_id, user: userId, notifications: "all" }))
            if (rest.error) {
            }
            else {
                dispatch(getUserSubscription(chennel_id))
                dispatch(getAllUserSubscription())
            }
        } catch (error) {
            console.log(error, "Subscription failed");
        }
    }

    const deleteSubscribe = (chennel_id) => {
        const filterData = subscribeList?.results?.filter((data) => data.channel.id === chennel_id);
        const chennelId = filterData[0]?.id;
        setIsSubscribe(!isSubscribe);
        dispatch(DeleteSubscription(chennelId)).then((result) => {
            if (result.error) {
                console.log(result, "Subscription failed");
            } else {
                dispatch(getUserSubscription(chennel_id))
                dispatch(getAllUserSubscription())
            }
        });
    }


    const handleHideChannel = (channel_id) => {
        dispatch(hideChannel(channel_id)).then((result) => {
            if (result.error) {
                console.log(result, "Hide failed");
            } else {
                dispatch(getSubscribedVideos())
            }
        })
    }


    /// this function need to be updated when backend is ready

    const handleUnHideChannel = (channel_id) => {
        dispatch(unHideChannel(channel_id)).then((result) => {
            if (result.error) {
                console.log(result, "Hide failed");
            } else {
                dispatch(getSubscribedVideos())
            }
        })

    }


    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        dispatch(profileDetails(userId));
    }, [dispatch, userId])

    useEffect(() => {
        setSubscribedData(data?.results)
    }, [data?.results])

    useEffect(() => {
        dispatch(getAllSubscribed())
        dispatch(getSubscribedVideos())
        dispatch(getAllUserSubscription())
    }, [dispatch])


    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center">
                {/* <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / Subscribed / V2 / 02 Sep, 2024
                </p> */}
                <p className="text-black text-[9px] py-[2px]">{copyright}</p>
            </div>

            <div className="sticky top-0 border-b-[0.4px] border-[#3e3e3e] z-20">
                <div className="bg-white px-2 grid grid-cols-12 items-center relative">
                    <div className="col-span-6 flex items-center justify-start gap-6">
                        <img
                            src={kv_logo}
                            alt="logo"
                            onClick={() => navigate("/home")}
                            className="object-cover cursor-pointer w-[2rem] my-1"
                        />
                        <IoSearch className="text-[20px] text-black cursor-pointer" onClick={() => navigate("/search")} />
                    </div>
                    <div className="col-span-6 flex items-center justify-end gap-4">
                        <div className="relative">
                            <IoNotifications
                                onClick={() => navigate("/notification")}
                                className="text-[22px] md:text-[20px] cursor-pointer"
                            />
                            <div className="absolute -top-[5px] -right-[6px] flex items-center justify-center h-[13px] w-[13px] rounded-full bg-[#f83131]">
                                <p className="text-[8px] text-white">5</p>
                            </div>
                        </div>
                        {!loading &&
                            <img
                                alt="profile"
                                onClick={() => navigate("/account")}
                                src={userDetails?.profile_picture}
                                className="w-[1.3rem] h-[1.3rem] object-cover rounded-full cursor-pointer"
                            />
                        }
                        {loading &&
                            <Skeleton
                                circle
                                baseColor="#202020" highlightColor="#444"
                                style={{ height: "1.3rem", width: "1.3rem" }}
                            />
                        }
                        <MainMenu />
                    </div>
                </div>

                <div className="grid grid-cols-12 items-center px-2 py-1 bg-black">
                    <div className="col-span-2 flex justify-start relative" ref={filterRef}>
                        <IoFilterCircle
                            onClick={handleFilter}
                            className="text-white text-[22px] cursor-pointer -ml-[2.5px]"
                        />
                        {/* Filter PopUp Menu */}
                        {showFilter &&
                            <div className="absolute top-8 left-0 bg-white px-2 py-1 rounded-lg">
                                <div className="flex items-center justify-between gap-3 py-[5px]">
                                    <p className="text-[12px] whitespace-nowrap">Latest Notifications</p>
                                    <Switch
                                        ripple={false}
                                        className="h-4 w-[28.5px] checked:bg-[#0DD315]"
                                        containerProps={{
                                            className: "w-8 h-4",
                                        }}
                                        circleProps={{
                                            className: "before:hidden left-0.5 border-none w-3 h-3",
                                        }}
                                    />
                                </div>
                                <div className="flex items-center justify-between gap-3 pb-[5px]">
                                    <p className="text-[12px] whitespace-nowrap">Older Notifications</p>
                                    <Switch
                                        ripple={false}
                                        className="h-4 w-[28.5px] checked:bg-[#0DD315]"
                                        containerProps={{
                                            className: "w-8 h-4",
                                        }}
                                        circleProps={{
                                            className: "before:hidden left-0.5 border-none w-3 h-3",
                                        }}
                                    />
                                </div>
                                <div className="flex items-center justify-between gap-3 pb-[5px]">
                                    <p className="text-[12px] whitespace-nowrap">Sort by date</p>
                                    <Switch
                                        ripple={false}
                                        className="h-4 w-[28.5px] checked:bg-[#0DD315]"
                                        containerProps={{
                                            className: "w-8 h-4",
                                        }}
                                        circleProps={{
                                            className: "before:hidden left-0.5 border-none w-3 h-3",
                                        }}
                                    />
                                </div>
                                <div className="flex items-center justify-between gap-3 pb-[5px]">
                                    <p className="text-[12px] whitespace-nowrap">A - Z</p>
                                    <Switch
                                        ripple={false}
                                        className="h-4 w-[28.5px] checked:bg-[#0DD315]"
                                        containerProps={{
                                            className: "w-8 h-4",
                                        }}
                                        circleProps={{
                                            className: "before:hidden left-0.5 border-none w-3 h-3",
                                        }}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                    <div className="col-span-8 flex justify-center">
                        <p
                            onClick={() => setShowSubscriptions(false)}
                            className="text-[17px] font-[550] text-white cursor-pointer"
                        >
                            Subscribed
                        </p>
                    </div>
                    <div className="col-span-2 flex justify-end relative" ref={settingsRef}>
                        <IoIosSettings
                            // onClick={handleSettings}
                            className="text-white text-[22px] cursor-pointer -mr-[2.5px]"
                        />
                        {/* Settings PopUp Menu */}
                        {showSettings &&
                            <div className="absolute top-8 right-0 bg-white px-2 py-1 rounded-lg">
                                <div className="flex items-center justify-between gap-3 py-[5px]">
                                    <p className="text-[12px] whitespace-nowrap">Mute</p>
                                    <Switch
                                        ripple={false}
                                        className="h-4 w-[28.5px] checked:bg-[#0DD315]"
                                        containerProps={{
                                            className: "w-8 h-4",
                                        }}
                                        circleProps={{
                                            className: "before:hidden left-0.5 border-none w-3 h-3",
                                        }}
                                    />
                                </div>
                                <div className="flex items-center justify-between gap-3 pb-[5px]">
                                    <p className="text-[12px] whitespace-nowrap">Hide</p>
                                    <Switch
                                        ripple={false}
                                        className="h-4 w-[28.5px] checked:bg-[#0DD315]"
                                        containerProps={{
                                            className: "w-8 h-4",
                                        }}
                                        circleProps={{
                                            className: "before:hidden left-0.5 border-none w-3 h-3",
                                        }}
                                    />
                                </div>
                                <div className="flex items-center justify-between gap-3 pb-[5px]">
                                    <p className="text-[12px] whitespace-nowrap">Delete</p>
                                    <Switch
                                        ripple={false}
                                        className="h-4 w-[28.5px] checked:bg-[#0DD315]"
                                        containerProps={{
                                            className: "w-8 h-4",
                                        }}
                                        circleProps={{
                                            className: "before:hidden left-0.5 border-none w-3 h-3",
                                        }}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>

            {!showSubscriptions && subscribedData?.length &&
                <p
                    onClick={handleShowSubscriptions}
                    className="px-2 py-[6px] text-[10px] text-[#00ff48e4] hover:text-[#00FF47] cursor-pointer text-end">
                    Show my subscriptions
                </p>
            }

            {!showSubscriptions &&
                <SubscribedCard subscribedData={subscribedData} subVideos={subVideos} handleClickSub={handleClickSub} subscribeList={subscribeList} subLoading={subLoading}
                    handleHideChannel={handleHideChannel}
                />
            }

            {showSubscriptions &&
                <SubscribedList subscribedData={subscribedData} handleUnHideChannel={handleUnHideChannel} />
            }

            {subLoading &&
                <div className="flex items-center justify-center h-80 w-full">
                    <BeatLoader
                        color={"#fff"}
                        loading={true}
                        size={15}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                </div>
            }

        </Fragment>
    );
};

export default Subscribed;
