import axios from "axios";
import { REACT_APP_MAIN_URL } from "../../utils/mainUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import getAccessConfig from "../config";

const mainUrl = REACT_APP_MAIN_URL

//home page video list api
export const videoList = createAsyncThunk(
    "videoList",
    async ({ pageCount }) => {
        const config = getAccessConfig();
        const request = await axios.get(`${mainUrl}/api/videos/?page=${pageCount}`, config);
        const response = await request.data;
        return response;
    }
);

const videoListSlice = createSlice({
    name: "videoListSlice",
    initialState: {
        isLoading: false,
        videoData: null,
        error: null,
    },
    extraReducers: (builder) => {
        //videoListSlice
        builder.addCase(videoList.pending, (state) => {
            state.isLoading = true;
            state.videoData = null;
            state.error = null;
        });
        builder.addCase(videoList.fulfilled, (state, action) => {
            state.isLoading = false;
            state.videoData = action.payload;
            state.error = null;
        });
        builder.addCase(videoList.rejected, (state) => {
            state.isLoading = false;
            state.videoData = null;
            state.error = true;
        });
    }
});

export default videoListSlice.reducer;
