import React, { Fragment, useState, useEffect, useCallback } from "react";
import { copyright } from "../utils/copyright";
import kv_logo from "../assets/kv_logo.png";
import { useDispatch, useSelector } from "react-redux";
import {
    searchResult,
    searchHistory,
    searchHistoryDeleteAll,
    searchHistoryDeleteById,
    resetSearchState,
    popularSearch
} from "../redux/apislice/search/searchSlice";

import { GoHistory } from "react-icons/go";
import { FaMicrophone } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { FiArrowUpLeft } from "react-icons/fi";
import { FaArrowTrendUp } from "react-icons/fa6";
import { IoCloseOutline, IoSearch } from "react-icons/io5";

import { debounce } from "lodash";
import BeatLoader from "react-spinners/BeatLoader";
import MainMenu from "../components/common/MainMenu";

const SearchPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loading, data, error, searchHistory: historyData, popularSearchData } = useSelector((state) => state.search);

    const [searchInput, setSearchInput] = useState("");
    const [isListening, setIsListening] = useState(false);
    const [showAll, setShowAll] = useState(false);
    const [localHistoryData, setLocalHistoryData] = useState([]);

    const debouncedSearch = useCallback(
        debounce((query) => {
            if (query.trim() !== "") {
                dispatch(searchResult(query)).then(() => {
                    dispatch(searchHistory());
                })
            }
        }, 500), // Adjust the delay as needed
        [dispatch]
    );

    const handleInputChange = (e) => {
        setSearchInput(e.target.value);
        debouncedSearch(e.target.value);
    };

    useEffect(() => {
        dispatch(searchHistory());
        dispatch(popularSearch())
        return () => {
            dispatch(resetSearchState()); // Reset search state on component unmount
        };
    }, [dispatch]);

    useEffect(() => {
        setLocalHistoryData(historyData);
    }, [historyData]);

    const clearInput = () => {
        setSearchInput("");
        dispatch(resetSearchState());
    };

    const handleVoiceSearch = () => {
        if ('webkitSpeechRecognition' in window) {
            const recognition = new window.webkitSpeechRecognition();
            recognition.lang = 'en-US';
            recognition.interimResults = false;
            recognition.continuous = false;
            recognition.start();
            setIsListening(true);

            recognition.onresult = (event) => {
                const transcript = event.results[0][0].transcript;
                const cleanTranscript = transcript.replace(/[.,?!]$/, '').trim();
                setSearchInput(cleanTranscript);
                debouncedSearch(cleanTranscript);
                setIsListening(false);
            };

            recognition.onerror = () => {
                setIsListening(false);
            };

            recognition.onend = () => {
                setIsListening(false);
            };
        } else {
            alert('Speech recognition is not supported in this browser.');
        }
    };

    const handleResultClick = (searchText) => {
        setSearchInput(searchText);
    };

    const clearAllHistory = () => {
        dispatch(searchHistoryDeleteAll()).then(() => {
            setLocalHistoryData([]);
        });
    };

    const removeSearchItem = (id) => {
        dispatch(searchHistoryDeleteById(id)).then(() => {
            setLocalHistoryData(localHistoryData.filter(item => item.id !== id));
        });
    };

    const toggleShowAll = () => {
        setShowAll(!showAll);
    };

    const displayedHistory = showAll ? localHistoryData : localHistoryData.slice(0, 10);

    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center">
                {/* <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / Search / V2 / 26 OCT, 2024
                </p> */}
                <p className="text-black text-[9px] py-[2px]">{copyright}</p>
            </div>

            <div className="grid grid-cols-11 sticky top-0 items-center px-2 py-[3px] gap-x-1 bg-white">
                <div className="col-span-2 flex items-center justify-start">
                    <img
                        src={kv_logo}
                        alt="logo"
                        onClick={() => navigate("/home")}
                        className="object-cover cursor-pointer w-[2rem]"
                    />
                </div>
                <div className="col-span-8 -ml-3 mr-2 flex items-center justify-center relative">
                    <input
                        type="text"
                        placeholder="Search for anything ..."
                        value={searchInput}
                        onChange={handleInputChange}
                        className="w-full pl-4 md:pl-3 pr-9 md:pr-8 py-[8px] md:py-[5px] border text-[16px] md:text-[14px] rounded-full focus:outline-none bg-[#1e1e1e] border-none text-white"
                    />
                    {searchInput ? (
                        <div className="absolute top-1/2 transform -translate-y-1/2 right-[12px] md:right-2">
                            <IoCloseOutline
                                onClick={clearInput}
                                className="text-white hover:text-[#f94444] text-[24px] md:text-[18px] cursor-pointer"
                            />
                        </div>
                    ) : (
                        <div className="absolute top-1/2 transform -translate-y-1/2 right-[12px] md:right-2">
                            <FaMicrophone
                                onClick={handleVoiceSearch}
                                className={`text-[#9d9d9d] hover:text-[#ffffff] text-[20px] md:text-[15px] cursor-pointer transition-all duration-200 ${isListening ? 'animate-pulse' : ''}`}
                            />
                        </div>
                    )}
                </div>
                <div className="col-span-1 flex items-center justify-end">
                    <MainMenu />
                </div>
            </div>

            {/* Show loader if loading */}
            {loading && (
                <div className="flex w-full justify-center items-center mt-2">
                    <BeatLoader
                        color={"#fff"}
                        loading={true}
                        size={15}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                </div>
            )}

            {/* Show error message if there is an error */}
            {error && (
                <div className="px-2 py-5">
                    <p className="text-[15px] md:text-[13px] text-[#4d4d4d] font-semibold">
                        Error: {error}
                    </p>
                </div>
            )}

            {/* Show search results */}
            {data.length > 0 && (
                <div className="px-2">
                    {data.map((result) => (
                        <div
                            key={result.id}
                            onClick={() => handleResultClick(result.title)}
                            className="grid grid-cols-12 gap-1 items-center mt-3"
                        >
                            <div className="col-span-1">
                                <IoSearch
                                    onClick={() => navigate(`/video/${result.id}`)}
                                    className="text-[15px] text-[#d3d3d3] hover:text-[#ffffff] cursor-pointer"
                                />
                            </div>
                            <div className="col-span-10 cursor-pointer">
                                <p
                                    onClick={() => navigate(`/video/${result.id}`)}
                                    className="text-[12px] text-[#d3d3d3] hover:text-[#ffffff] font-medium leading-4 overflow-hidden line-clamp-1"
                                >
                                    {result.title}
                                </p>
                            </div>
                            <div className="col-span-1 flex items-center justify-end">
                                <FiArrowUpLeft
                                    onClick={() => navigate(`/video/${result.id}`)}
                                    className="text-[18px] text-[#d3d3d3] hover:text-[#ffffff] cursor-pointer"
                                />
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {/* Show message if no results are found */}
            {!loading && !error && data.length === 0 && searchInput.trim() !== "" && (
                <div className="px-2 py-3">
                    <p className="text-[15px] md:text-[13px] text-[#4d4d4d] font-semibold">
                        No results found.
                    </p>
                </div>
            )}

            {/* Recent Search Section */}
            <div className="px-2 pt-2">
                <div className="flex items-center justify-between">
                    {displayedHistory.length > 0 &&
                        <p className="text-[15px] md:text-[13px] text-white font-semibold">
                            Recent Search
                        </p>
                    }
                    {localHistoryData.length > 0 && (
                        <p
                            className="text-[15px] md:text-[13px] text-blue-500 hover:text-blue-600 font-semibold cursor-pointer"
                            onClick={clearAllHistory}
                        >
                            clear all
                        </p>
                    )}
                </div>
                {/* Show recent search history */}
                {displayedHistory.map((data) => (
                    <div className="grid grid-cols-12 gap-1 items-center mt-3" key={data.id}>
                        <div className="col-span-1">
                            <GoHistory className="text-[18px] md:text-[15px] text-white" />
                        </div>
                        <div
                            className="col-span-10 cursor-pointer"
                            onClick={() => handleResultClick(data.search_query)}
                        >
                            <p className="text-[14px] md:text-[12px] text-white font-medium leading-4 overflow-hidden line-clamp-1">
                                {data.search_query}
                            </p>
                        </div>
                        <div className="col-span-1 flex items-center justify-end">
                            <IoCloseOutline
                                onClick={() => removeSearchItem(data.id)}
                                className="text-[18px] md:text-[15px] text-black hover:text-white bg-white hover:bg-[#f94444] rounded-full p-[1px] cursor-pointer"
                            />
                        </div>
                    </div>
                ))}
                {/* Show view all button if history length is greater than 10 */}
                {localHistoryData.length > 10 && (
                    <div className="text-center mt-2">
                        <button
                            className="text-[13px] text-blue-500 hover:text-blue-600 font-semibold cursor-pointer"
                            onClick={toggleShowAll}
                        >
                            {showAll ? "Show Less" : "View All"}
                        </button>
                    </div>
                )}
            </div>

            {/* Popular Search Section */}
            <div className="px-2 py-5">
                <p className="text-[15px] md:text-[13px] text-white font-semibold">
                    Popular Search
                </p>
                {popularSearchData.map((data) => (
                    <div className="grid grid-cols-12 gap-1 items-start mt-2" key={data.id}>
                        <div className="col-span-1">
                            <FaArrowTrendUp
                                className="text-[18px] md:text-[14px] text-white mt-[2px]"
                            />
                        </div>
                        <div className="col-span-9 flex flex-col">
                            <p className="text-[14px] md:text-[12px] text-white font-medium leading-4 overflow-hidden">
                                {data.title}
                            </p>
                            <p className="text-[12px] md:text-[10px] text-[#424242]">
                                total {data.total_search_count} search
                            </p>
                        </div>
                        <div className="col-span-2">
                            <img
                                alt={data.title}
                                src={data.thumbnail}
                                className="h-8 w-full object-cover rounded-[4px]"
                            />
                        </div>
                    </div>
                ))}
            </div>
        </Fragment>
    );
};

export default SearchPage;