import React, { Fragment, useState, useEffect, useRef, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import kv_logo from "../assets/kv_logo.png";
import goLive from "../assets/16dot_menu/go_live.png";
import createPost from "../assets/16dot_menu/create_post.png";
import createNews from "../assets/16dot_menu/create_news.png";
import createVideo from "../assets/16dot_menu/create_video.png";
// import uploadVideo from "../assets/16dot_menu/upload_video.png";
import createShorts from "../assets/16dot_menu/create_clips.png";

import { FaSquarePlus } from "react-icons/fa6";
import { IoNotifications, IoSearch } from "react-icons/io5";

import MainMenu from "./common/MainMenu";
import SecondMenu from "./common/SecondMenu";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { getNotifications } from "../redux/apislice/notification/notificationSlice";

const Header = () => {
    const dispatch = useDispatch();
    const [createMenu, setCreateMenu] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    const menuRef = useRef(null);
    const toggleButtonRef = useRef(null);
    const [notifyCount, setNotifyCount] = useState(0);
    const profile = useSelector((state) => state.profile);
    const { userDetails, loading } = profile;

    // Create menu items
    const createMenuItems = [
        { img: createVideo, label: "Create Video", pathName: "/createvideo" },
        // { img: uploadVideo, label: "Upload Video", pathName: "/uploadvideo" },
        { img: createShorts, label: "Create Clips", pathName: "/createclips" },
        { img: createPost, label: "Create Post", pathName: "/createpost" },
        { img: createNews, label: "Create News", pathName: "/createnews" },
        { img: goLive, label: "Go Live", pathName: "/golive" },
    ];

    const handleCreateMenuToggle = (e) => {
        e.stopPropagation();
        setCreateMenu((prevMenu) => !prevMenu);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                menuRef.current &&
                !menuRef.current.contains(event.target) &&
                !toggleButtonRef.current.contains(event.target)
            ) {
                setCreateMenu(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuRef]);

    const fetchNotifications = useCallback(async () => {
        await dispatch(getNotifications()).then((res) => {

            const unreadNotifications = res?.payload?.filter((item) => item?.is_read === false);
            setNotifyCount(unreadNotifications?.length || 0);
        })

    }, [dispatch])


    useEffect(() => {
        fetchNotifications()
    }, [fetchNotifications]);

    return (
        <Fragment>
            <div className="bg-white px-2 py-1 grid grid-cols-12 items-center relative" ref={toggleButtonRef}>
                <div className="col-span-6 flex items-center justify-start gap-6">
                    {/* <h1 className="text-[2rem] md:text-[1.6rem] font-bold uppercase text-black cursor-pointer" onClick={() => navigate("/home")}>
                        kv
                    </h1> */}
                    <img
                        src={kv_logo}
                        alt="logo"
                        className="object-cover cursor-pointer w-[2rem]"
                        onClick={() => navigate("/home")}
                    />
                    <FaSquarePlus
                        onClick={handleCreateMenuToggle}
                        className="text-[22px] md:text-[20px] text-black cursor-pointer"
                    />
                </div>

                <div className="col-span-6 flex items-center justify-end gap-4">
                    {!loading && <img
                        alt="profile"
                        onClick={() => navigate("/account")}
                        src={userDetails?.profile_picture}
                        className="w-[1.3rem] h-[1.3rem] object-cover rounded-full cursor-pointer"
                    // src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    />}
                    {loading && <Skeleton circle style={{ height: "1.3rem", width: "1.3rem" }} baseColor="#202020" highlightColor="#444" />}
                    <div className="relative">
                        <IoNotifications
                            onClick={() => navigate("/notification")}
                            className="text-[22px] md:text-[20px] cursor-pointer"
                        />
                        {notifyCount !== 0 && <div className="absolute -top-[5px] -right-[6px] flex items-center justify-center h-[13px] w-[13px] rounded-full bg-[#f83131]">
                            <p className="text-[8px] text-white">{notifyCount}</p>
                        </div>}
                    </div>
                    <IoSearch
                        onClick={() => navigate("/search")}
                        className="text-[22px] md:text-[20px] text-black cursor-pointer"
                    />
                    <MainMenu />
                </div>

                {/* create menu */}
                {createMenu &&
                    <div ref={menuRef} className="absolute top-[3rem] md:top-[2.45rem] left-2 bg-[#ffffff]">
                        {createMenuItems.map((item, index) => (
                            <div
                                key={index}
                                onClick={() => navigate(item.pathName)}
                                className={`flex items-center gap-3 px-2 py-[2px] 
                                    ${index !== createMenuItems.length - 1 ? "border-b-[0.4px] border-[#dedede]" : ""}
                                `}
                            >
                                {item.img ? (
                                    <img src={item.img} alt={item.label} className="h-5 w-5 md:h-4 md:w-4" />
                                ) : (
                                    <item.icon className="h-5 w-5 md:h-4 md:w-4 text-[#5c5b5b]" />
                                )}
                                <p className="text-[14px] md:text-[12px] hover:text-[#f33535] cursor-pointer">{item.label}</p>
                            </div>
                        ))}
                    </div>
                }
            </div>

            {/* second header menu */}
            {location.pathname === "/home" && (
                <SecondMenu />
            )}
        </Fragment>
    );
};

export default Header;
